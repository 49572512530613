import { ControlledComboBox, ControlledTextField, SanitizedHtml } from 'components';
import { Icon, IconButton, Label, Stack, TooltipHost } from '@fluentui/react';
import { emptyFunction, nameof } from 'functions';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { Control } from 'react-hook-form';
import { memo, useEffect } from 'react';
import { IProject } from 'interfaces';
import { EIconName } from 'enums';
import { CancelToken } from 'axios';

const VARIABLES = {
  requestId: 'CreateUpdateProjectForm.getProjectNames'
};

interface IProps {
  control: Control<any>;
}

const CreateUpdateProjectForm = ({ control }: IProps) => {

  const { isLoading, data: dataProjectNames } = useHttpRequestStatus<string[]>(VARIABLES.requestId, { data: [] });
  const { t } = useTranslation();

  useEffect(
    () => {
      const cancelTokenSource = httpRequestService.getCancelTokenSource();
      loadProjectNames(cancelTokenSource.token);

      return () => cancelTokenSource.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadProjectNames = (cancelToken?: CancelToken) => {
    httpRequestService
      .devops
      .projects
      .get({ cancelToken, requestId: VARIABLES.requestId })
      .catch(emptyFunction);
  };

  const isSubmitting = control._formState.isSubmitting;

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <Stack>
        <Label>
          {t('common.project')}
        </Label>
        <Stack horizontal verticalAlign='start'>
          <ControlledComboBox
            control={control}
            className='w-100'
            name={nameof<IProject>('name')}
            rules={{ required: t('common.required').toString() }}
            disabled={isSubmitting || isLoading}
            autoComplete='on'
            options={dataProjectNames.map(l => ({ key: l, text: l }))} />
          <Stack horizontal verticalAlign='center'>
            <IconButton
              disabled={isLoading}
              className='ms-fontSize-12 mx-2'
              onClick={() => loadProjectNames()}
              iconProps={{ iconName: EIconName.refresh }} />
            <TooltipHost content={<SanitizedHtml className='ms-fontSize-12' html={t('projectsForm.info')} />}>
              <Icon role='button' iconName={EIconName.Info} className='ms-fontSize-16 mt-1' />
            </TooltipHost>
          </Stack>
        </Stack>
      </Stack>
      <ControlledTextField
        required
        control={control}
        name={nameof<IProject>('displayName')}
        disabled={isSubmitting}
        label={t('common.displayName')} />
      <ControlledTextField
        multiline
        rows={5}
        control={control}
        name={nameof<IProject>('description')}
        disabled={isSubmitting}
        label={t('common.description')}
        maxLength={500} />
    </Stack>
  );

};

export default memo(CreateUpdateProjectForm);
