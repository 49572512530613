import { IEmailTemplate, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/projects';

const emailTemplate = {

  get: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IEmailTemplate[]>(
        `${baseUrl}/${projectId}/translations/email-templates`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjectTranslationsOfEmail
        }
      )
      .then(response => response.data),

  getContent: (projectId: string, templateName: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<{ htmlContent: string; subject: string; }>(
        `${baseUrl}/${projectId}/translations/email-templates/${templateName}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjectTranslationsOfEmail
        }
      )
      .then(response => response.data),

  updateContent: (projectId: string, templateName: string, htmlContent: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${projectId}/translations/email-templates/${templateName}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UpdateProjectTranslationsOfEmailFIle
        },
        {
          method: 'PUT',
          data: htmlContent
        }
      )
      .then(response => response.data)

};

export default emailTemplate;
