import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit';
import { ICurrentUser, INotification } from 'interfaces';
import { EApplicationModule, EUserRole } from 'enums';
import { personInitials } from 'functions';
import { TNullable } from 'types';
import { IStore } from 'store';

export interface ICurrentUserStore extends ICurrentUser {
  isNotificationPanelVisible?: boolean;
  notifications: INotification[];
  bbcvColor?: TNullable<string>;
  isLoggingOut?: boolean;
  isLoggingIn?: boolean;
}

const selectUserConnected = (state: IStore) => state.userConnected;

const userConnectedSlice = createSlice({
  name: 'userConnected',
  reducers: {
    set: (state, action) => {
      state = action.payload.setter(state);

      try {
        action.payload.callback?.(state);
      } catch { }

      return state;
    }
  },
  initialState: null as TNullable<ICurrentUserStore>
});

export default userConnectedSlice;

const set = {
  set: (dispatch: Dispatch, setter: (state: ICurrentUserStore) => ICurrentUserStore, callback?: (state: ICurrentUserStore) => void) => dispatch({
    type: `${userConnectedSlice.name}/${set.set.name}`,
    payload: {
      callback,
      setter
    }
  })
};

const get = {
  bbcvColor: createSelector(selectUserConnected, userConnected => userConnected?.bbcvColor),
  isUserConnected: createSelector(
    selectUserConnected,
    userConnected => (userConnected?.id ?? '') !== ''
  ),
  userConnected: createSelector(
    selectUserConnected,
    userConnected => userConnected as ICurrentUserStore
  ),
  id: createSelector(
    selectUserConnected,
    userConnected => userConnected?.id as string
  ),
  email: createSelector(
    selectUserConnected,
    userConnected => userConnected?.email as string
  ),
  initials: createSelector(
    selectUserConnected,
    userConnected => (
      userConnected
        ? personInitials(userConnected)
        : null
    )
  ),
  notifications: createSelector(
    selectUserConnected,
    userConnected => userConnected?.notifications ?? []
  ),
  hasUnreadNotifications: createSelector(
    selectUserConnected,
    userConnected => (userConnected?.notifications ?? []).some(l => l.read === false)
  ),
  isNotificationPanelVisible: createSelector(
    selectUserConnected,
    userConnected => userConnected?.isNotificationPanelVisible ?? false
  ),
  isLoggingIn: createSelector(
    selectUserConnected,
    userConnected => userConnected?.isLoggingIn ?? false
  ),
  isLoggingOut: createSelector(
    selectUserConnected,
    userConnected => userConnected?.isLoggingOut ?? false
  ),
  modules: createSelector(
    selectUserConnected,
    userConnected => {
      const modules = (
        (userConnected?.roles ?? []).some(l => [EUserRole.Admin, EUserRole.Tester].includes(l))
          ? [
            EApplicationModule.TimeTracking,
            EApplicationModule.Consulting,
            EApplicationModule.Projects,
            EApplicationModule.Settings
          ]
          : [EApplicationModule.Projects]
      );

      return modules;
    }
  ),
  asRoles: (...roles: EUserRole[]) => createSelector(
    selectUserConnected,
    userConnected => userConnected?.roles?.some(l => roles.includes(l)) ?? false
  )
};

export const userConnectedStore = {
  set,
  get
};
