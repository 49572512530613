import { useTranslation } from 'react-i18next';
import { Icon, Stack } from '@fluentui/react';
import { EIconName } from 'enums';
import { memo } from 'react';

interface IProps {
  type: 'next' | 'previous' | 'download';
  disabled?: boolean;
  onClick: () => void;
}

const FileViewerButton = ({ type, onClick, disabled = false }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      onClick={() => disabled === false && onClick()}
      className={`badge ms-bgColor-white ${disabled ? 'opacity-75' : 'cursor-pointer'}`}
      verticalAlign='center'
      tokens={{ childrenGap: 5 }}>
      {
        type === 'download' &&
        <Icon className='ms-fontSize-12 ms-fontWeight-bold' iconName={EIconName.Download} />
      }
      {
        type === 'previous' &&
        <Icon className='ms-fontSize-12 ms-fontWeight-bold' iconName={EIconName.Previous} />
      }
      <small className='ms-fontWeight-bold'>
        {t(`common.${type}`)}
      </small>
      {
        type === 'next' &&
        <Icon className='ms-fontSize-12 ms-fontWeight-bold' iconName={EIconName.Next} />
      }
    </Stack>
  );

};

export default memo(FileViewerButton);
