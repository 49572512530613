import imgLogo from 'assets/images/png/Logo-Xchange_vert.png';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { ENVIRONMENT_VARIABLES } from 'constant';
import { memo } from 'react';
import './LoadingScreen.scss';

interface IProps {
  description?: string;
}

const LoadingScreen = ({ description }: IProps) => (
  <div className='app-loadingScreen ms-fadeIn500 d-flex justify-content-center align-items-center'>
    <div>
      <div className='d-flex justify-content-center'>
        <img className='mb-3' srcSet={imgLogo} alt={ENVIRONMENT_VARIABLES.appName} />
      </div>
      <Spinner label={description} />
    </div>
  </div>
);

export default memo(LoadingScreen);
