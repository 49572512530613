import HeaderItemsNotifications from './HeaderItemsNotifications';
import HeaderItemsLanguage from './HeaderItemsLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { CommandBarButton } from '@fluentui/react';
import { EAppInitStatus, EIconName } from 'enums';
import HeaderItemsUser from './HeaderItemsUser';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { appStore } from 'store';
import { memo } from 'react';
import './Header.scss';

const VARIABLES = {
  signInButton: {
    iconProps: {
      iconName: EIconName.signin
    }
  }
};

const HeaderItems = () => {

  const _isUserConnected = useSelector(appStore.userConnected.get.isUserConnected);
  const _status = useSelector(appStore.startup.get.status);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOnClickSignIn = () => {
    appStore.userConnected.set.set(dispatch, l => ({ ...l, isLoggingIn: true }));
    httpRequestService.azureAD.loginRedirect();
  };

  return (
    <>
      <HeaderItemsLanguage />
      {
        _status === EAppInitStatus.unAuthenticated
          ? <CommandBarButton
            iconProps={VARIABLES.signInButton.iconProps}
            onClick={handleOnClickSignIn}
            text={t('common.signIn')}
            className='px-2' />
          : (_status === EAppInitStatus.error403 || _status === EAppInitStatus.error500) && _isUserConnected
            ? <HeaderItemsUser />
            : _isUserConnected
              ? <>
                <HeaderItemsNotifications />
                <HeaderItemsUser />
              </>
              : <></>
      }
    </>
  );

};

export default memo(HeaderItems);
