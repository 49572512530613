import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ControlledDropdown, ControlledTextField } from 'components';
import { useHttpRequestStatus, useNomenclatureData } from 'hooks';
import { IProject, ITranslationFolder } from 'interfaces';
import { emptyFunction, nameof } from 'functions';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useDispatch } from 'react-redux';
import { ENomenclatureType } from 'enums';
import { Stack } from '@fluentui/react';
import { memo, useEffect } from 'react';
import store from './store';

interface IProps {
  watch: UseFormWatch<ITranslationFolder>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  projectSelected: IProject;
};

const VARIABLES = {
  requestIds: {
    getMandrillPrefixes: 'CreateTranslationFolderForm.getMandrillPrefixes',
    getRepositories: 'CreateTranslationFolderForm.getRepositories',
    getBranches: 'CreateTranslationFolderForm.getBranches'
  }
};

const CreateTranslationFolderFileForm = ({ control, setValue, watch, projectSelected }: IProps) => {

  const { data: dataContainerType, isLoading: isLoadingContainerType } = useNomenclatureData<string>(ENomenclatureType.TranslationContainerType);
  const { isLoading: isLoadingGetRepositories, data: dataRepositories } = useHttpRequestStatus<string[]>(
    VARIABLES.requestIds.getRepositories,
    { data: [], isLoading: true }
  );
  const { isLoading: isLoadingGetBranches, data: dataBranches } = useHttpRequestStatus<string[]>(
    VARIABLES.requestIds.getBranches,
    { data: [], isLoading: true }
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      const subscriptionTicketType = watch(
        (data, { name }) => {

          switch (name) {

            case nameof<ITranslationFolder>('repository'):
              const repository = data.repository;

              repository &&
                httpRequestService
                  .devops
                  .projects
                  .repositories
                  .branches
                  .get(
                    projectSelected.id,
                    repository,
                    { requestId: VARIABLES.requestIds.getBranches }
                  )
                  .then(
                    branches => {
                      store.set.set(dispatch, l => ({ ...l, branches }));
                      const defaultBranch = branches.find(l => ['dev', 'develop', 'development'].includes(l.toLowerCase()));
                      setValue(nameof<ITranslationFolder>('branch'), defaultBranch);
                    }
                  )
                  .catch(emptyFunction);
              break;

            case nameof<ITranslationFolder>('folderPath'):
              const folderPath = data.folderPath;
              folderPath?.includes('\\') &&
                setValue(nameof<ITranslationFolder>('folderPath'), folderPath.replaceAll('\\', '/'));
              break;

            default:
              break;
          }

        }
      );

      const cancelTokenSource = httpRequestService.getCancelTokenSource();

      httpRequestService
        .devops
        .projects
        .repositories
        .get(
          projectSelected.id,
          { cancelToken: cancelTokenSource.token, requestId: VARIABLES.requestIds.getRepositories }
        )
        .then(repositories => store.set.set(dispatch, l => ({ ...l, repositories })))
        .catch(emptyFunction);

      return () => {
        subscriptionTicketType.unsubscribe();
        cancelTokenSource.cancel();
      };
    },
    // eslint-disable-next-line
    []
  );

  const isFormSubmitting = control._formState.isSubmitting;

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <ControlledTextField
        control={control}
        name={nameof<ITranslationFolder>('name')}
        rules={{ required: t('routes.settings.projects.panel.required').toString() }}
        disabled={isFormSubmitting}
        label={t('routes.projects.translationFiles.createTranslationFolderPanel.name')}
        maxLength={100} />
      <ControlledDropdown
        control={control}
        className='mb-2'
        name={nameof<ITranslationFolder>('repository')}
        rules={{ required: t('routes.settings.projects.panel.required').toString() }}
        disabled={isFormSubmitting || isLoadingGetRepositories}
        label={t('routes.projects.translationFiles.createTranslationFolderPanel.repository')}
        options={dataRepositories.map(l => ({ key: l, text: l }))} />
      <ControlledDropdown
        control={control}
        className='mb-2'
        name={nameof<ITranslationFolder>('branch')}
        rules={{ required: t('routes.settings.projects.panel.required').toString() }}
        disabled={isFormSubmitting || isLoadingGetBranches || dataBranches.length === 0}
        label={t('routes.projects.translationFiles.createTranslationFolderPanel.branch')}
        options={dataBranches.map(l => ({ key: l, text: l }))} />
      <ControlledDropdown
        required
        control={control}
        disabled={isLoadingContainerType || isFormSubmitting}
        options={dataContainerType.map(l => ({ key: l.id, text: l.title }))}
        label={t('common.containerType')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<ITranslationFolder>('containerType')} />
      <ControlledTextField
        control={control}
        placeholder='/src/assets/i18n/'
        name={nameof<ITranslationFolder>('folderPath')}
        rules={{ required: t('routes.settings.projects.panel.required').toString() }}
        disabled={isFormSubmitting}
        label={t('routes.projects.translationFiles.createTranslationFolderPanel.folderPath')}
        maxLength={100} />
    </Stack>
  );

};

export default memo(CreateTranslationFolderFileForm);
