import { EApplicationModule } from 'enums';
import { TRouteTimeTracking } from 'types';
import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module settings in application. */
const TIME_TRACKING = {
  module: EApplicationModule.TimeTracking,
  id: '/time-tracking',
  /** Parent of route. */
  parent: () => ROUTES as IRoutes,
  /** Base url of route */
  base: '/time-tracking',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), TIME_TRACKING.base),
  relativePath: () => `time-tracking/*`,
  /** Description of route. */
  description: (t: Function): string => t('common.timeTracking'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTracking'*/ 'modules/time-tracking/routes')),

  CLIENTS: {
    module: EApplicationModule.TimeTracking,
    id: '/time-tracking/clients',
    /** Parent of route. */
    parent: () => TIME_TRACKING as IRoutes,
    /** Base url of route */
    base: '/clients',
    /** Path of route. */
    path: () => concatPath(TIME_TRACKING.path(), TIME_TRACKING.CLIENTS.base),
    relativePath: () => 'clients',
    /** Description of route. */
    description: (t: Function): string => t('common.clients'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTrackingClients'*/ 'modules/time-tracking/pages/clients'))
  },

  SETTINGS: {
    module: EApplicationModule.TimeTracking,
    id: '/time-tracking/settings',
    /** Parent of route. */
    parent: () => TIME_TRACKING as IRoutes,
    /** Base url of route */
    base: '/settings',
    /** Path of route. */
    path: () => concatPath(TIME_TRACKING.path(), TIME_TRACKING.SETTINGS.base),
    relativePath: () => 'settings',
    /** Description of route. */
    description: (t: Function): string => t('common.settings'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTrackingSettings'*/ 'modules/time-tracking/pages/settings'))
  },

  PROJECTS: {
    module: EApplicationModule.TimeTracking,
    id: '/time-tracking/projects',
    /** Parent of route. */
    parent: () => TIME_TRACKING as IRoutes,
    /** Base url of route */
    base: '/projects',
    /** Path of route. */
    path: () => concatPath(TIME_TRACKING.path(), TIME_TRACKING.PROJECTS.base),
    relativePath: () => 'projects',
    /** Description of route. */
    description: (t: Function): string => t('common.projects'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTrackingProjects'*/ 'modules/time-tracking/pages/projects'))
  },

  ACTIVITIES: {
    module: EApplicationModule.TimeTracking,
    id: '/time-tracking/activities',
    /** Parent of route. */
    parent: () => TIME_TRACKING as IRoutes,
    /** Base url of route */
    base: '/activities',
    /** Path of route. */
    path: () => concatPath(TIME_TRACKING.path(), TIME_TRACKING.ACTIVITIES.base),
    relativePath: () => 'activities',
    /** Description of route. */
    description: (t: Function): string => t('common.activities'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTrackingActivities'*/ 'modules/time-tracking/pages/activities'))
  },

  TIME_TRACKING: {
    module: EApplicationModule.TimeTracking,
    id: '/time-tracking/time-tracking',
    /** Parent of route. */
    parent: () => TIME_TRACKING as IRoutes,
    /** Base url of route */
    base: '/time-tracking',
    /** Path of route. */
    path: () => concatPath(TIME_TRACKING.path(), TIME_TRACKING.TIME_TRACKING.base),
    relativePath: () => 'time-tracking',
    /** Description of route. */
    description: (t: Function): string => t('common.timeTracking'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeTimeTrackingTimeTracking'*/ 'modules/time-tracking/pages/time-tracking'))
  }
} as TRouteTimeTracking;

export default TIME_TRACKING;
