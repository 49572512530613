import { ILog, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';
import { EColumnLog, EOrder } from 'enums';

const baseUrl = '/logging';

const logging = {

  getLogs: (
    query: {
      orderBy: EOrder;
      sortBy?: EColumnLog;
    } = {
        orderBy: EOrder.None
      },
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<ILog>(
        `${baseUrl}/logs`,
        params,
        { params: query }
      )
      .then(response => response.data)

};

export default logging;
