import { IHttpRequestServiceOptions, ITicketComment } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';
import dayjs from 'config/dayjs';
import axiosInstance from "../../../config/axiosInstance";

const baseUrl = '/tickets';

const comments = {

  create: (ticketId: string, content: string) =>
      axiosInstance
      .post<string>(
        `tickets/${ticketId}/comments`,
        {
            htmlContent: content,
        }
      )
      .then(response => response.data),

  get: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<ITicketComment[]>(
        `${baseUrl}/${ticketId}/comments`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetCommentsOnTicket
        }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                createdOn: dayjs(l.createdOn, { utc: true }).toDate(),
                type: 'user'
              }) as ITicketComment
            )
        )
      ),

  delete: (ticketId: string, ticketCommentId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${ticketId}/comments/${ticketCommentId} `,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteCommentFromTicket
        },
        { method: 'DELETE' }
      )
      .then(response => response.data),

  edit: (
    ticketId: string,
    ticketCommentId: string,
    comment: string,
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<ITicketComment>(
        `${baseUrl}/${ticketId}/comments/${ticketCommentId} `,
        {
          ...params,
          httpRequestName: EHttpRequestName.UpdateCommentFromTicket
        },
        {
          data: {comment:comment},
          method: 'PUT'
        }
      )
      .then(response => response.data)
};

export default comments;
