import HeaderProgressIndicator from './HeaderProgressIndicator';
import { Stack } from '@fluentui/react';
import HeaderItems from './HeaderItems';
import HeaderLeft from './headerLeft';
import { memo } from 'react';
import './Header.scss';

const Header = () => (
  <Stack className='app-stackHeader' horizontal={false} verticalAlign='start'>
    <Stack className='app-stackHeaderContent' horizontal>
      <HeaderLeft />
      <Stack className='ms-auto' horizontal horizontalAlign='end'>
        <HeaderItems />
      </Stack>
    </Stack>
    <HeaderProgressIndicator />
  </Stack>
);

export default memo(Header);
