import { ENVIRONMENT_VARIABLES } from 'constant';
import { ActionButton } from '@fluentui/react';
import { IHasData, ITicket } from 'interfaces';
import { useSelector } from 'react-redux';
import { EIconName } from 'enums';
import { appStore } from 'store';

export const CellRendererFrameworkTicketWorkItemId = ({ data }: IHasData<ITicket>) => {

  const _projectSelected = useSelector(appStore.common.get.projectSelected);

  const url = `${ENVIRONMENT_VARIABLES.devopsBaseUrl}${_projectSelected?.name}/_workitems/edit/${data.workItemId}/`;

  if (!_projectSelected || !data.workItemId) {
    return <></>;
  }

  return (
    <ActionButton
      iconProps={{
        iconName: EIconName.vSTSLogo,
        className: 'ms-fontColor-blue'
      }}
      className='ms-fontColor-blue'
      target='_blank'
      href={url} >
      {data.workItemId}
    </ActionButton>
  );

};
