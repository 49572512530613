import { IHttpRequestServiceOptions, IConsultantAcademicBackground } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/consultancy-profiles';

const academicBackground = {

  get: (consultantId: string, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<Omit<IConsultantAcademicBackground, 'consultantId'>[]>(
        `${baseUrl}/${consultantId}/academic-background`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetConsultantAcademicBackground
        }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                startDate: new Date(l.startDate),
                endDate: (
                  l.endDate
                    ? new Date(l.endDate)
                    : null
                )
              })
            )
        )
      )
  ),

  create: (
    consultantId: string,
    data: Omit<IConsultantAcademicBackground, 'id'>,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<string>(
        `${baseUrl}/${consultantId}/academic-background`,
        {
          ...params,
          httpRequestName: EHttpRequestName.CreateConsultantAcademicBackground
        },
        {
          data: ({
            ...data,
            endDate: data.endDate
              ? new Date(data.endDate)
              : null,
            startDate: new Date(data.startDate)
          }) as Omit<IConsultantAcademicBackground, 'id'>,
          method: 'POST'
        }
      )
      .then(response => response.data)
  ),

  update: (
    consultantId: string,
    consultantAcademicBackgroundId: string,
    data: Omit<IConsultantAcademicBackground, 'id'>,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/academic-background/${consultantAcademicBackgroundId}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UpdateConsultantAcademicBackground
        },
        {
          data: ({
            ...data,
            endDate: data.endDate
              ? new Date(data.endDate)
              : null,
            startDate: new Date(data.startDate)
          }) as Omit<IConsultantAcademicBackground, 'id'>,
          method: 'PUT'
        }
      )
      .then(response => response.data)
  ),

  delete: (
    consultantId: string,
    consultantAcademicBackgroundId: string,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/academic-background/${consultantAcademicBackgroundId}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteConsultantAcademicBackground
        },
        { method: 'DELETE' }
      )
      .then(response => response.data)
  )

};

export default academicBackground;
