import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Stack } from '@fluentui/react';
import { EIconName } from 'enums';
import './QuickFilter.scss';

const VARIABLES = {
  initState: {
    clearFilterHasFocus: false,
    value: ''
  } as IState
};

/**Interface of state */
interface IState {
  clearFilterHasFocus: boolean;
  value: string;
}

/**Interface of component's props */
interface IProps {
  onChange?: (value: string) => void;
  onReset?: () => void;
  value?: string;
  width?: number;
}

/**
 * Component used to display a filter.
 * @returns JSX.Element.
 */
const QuickFilter = ({ value = '', width = 300, onChange, onReset }: IProps) => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  useEffect(
    () => setState(l => ({ ...l, value })),
    [value]
  );

  /** Handler of event 'onChange'. */
  const handleOnChange = (event: any) => {
    const newValue = event?.currentTarget.value ?? '';
    setState(l => ({ ...l, value: newValue }));
    onChange?.(newValue);
  };

  /** Handler of event 'onClick'. */
  const handleOnClickResetButton = () => {
    setState(l => ({ ...l, value: '' }));
    onChange?.('');
    onReset?.();
  };

  return (
    <Stack
      horizontal
      className='app-QuickFilter rounded box-shadow'
      verticalAlign='center'
      style={{ maxWidth: width }}>
      <Icon className='ms-fontColor-gray90' iconName={EIconName.filter} />
      <input
        placeholder={t('routes.settings.projects.filter.placeholder')}
        value={state.value}
        className='w-100'
        onChange={handleOnChange} />
      <Icon
        title={t('routes.settings.projects.filter.clear')}
        role={'button'}
        onMouseEnter={() => setState(l => ({ ...l, clearFilterHasFocus: true }))}
        onMouseLeave={() => setState(l => ({ ...l, clearFilterHasFocus: false }))}
        onClick={handleOnClickResetButton}
        className={`px-3 ${state.clearFilterHasFocus ? 'ms-fontColor-gray100' : 'ms-fontColor-gray60'}`}
        iconName={EIconName.clear} />
    </Stack>
  );

};

export default memo(QuickFilter);
