import { IBaseFile, IHasDescription, IHasTitle, IHttpRequestServiceOptions, ITicket } from 'interfaces';
import { EHttpRequestName, ETicketCriticality, ETicketState, ETicketType } from 'enums';
import { convertService, httpRequestService } from 'services';
import { TNullable } from 'types';
import axiosInstance from "../../../config/axiosInstance";

const baseUrl = '/projects';

const tickets = {

  get: (
    projectId: string,
    query: {
      states?: TNullable<ETicketState[]>;
      excludedProperties?: string[];
      ids?: string[];
    } = {},
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<ITicket[]>(

        [
          `${baseUrl}/${projectId}/tickets/?${convertService.toQueryString(query.ids, 'ids')}`,
          `${convertService.toQueryString(query.excludedProperties, 'excludedProperties')}`,
          `${convertService.toQueryString(query.states, 'states')}`
        ]
          .filter(l => l)
          .join('&')
        ,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetTickets
        }
      )
      .then(response => response.data),

  create: (
    projectId: string,
    data: IHasTitle & IHasDescription & {
      criticality: ETicketCriticality;
      attachments?: TNullable<IBaseFile[]>;
      type: ETicketType;
    },
    params: IHttpRequestServiceOptions = {}
  ) => {
    const formData = new FormData();
    formData.append('criticality', data.criticality.toString());
    formData.append('description', data.description);
    formData.append('type', data.type.toString());
    formData.append('title', data.title);

    (data.attachments ?? [])
      .forEach(item => formData.append('attachments', item as any));

    return (
      axiosInstance
        .post<string>(
          `${baseUrl}/${projectId}/tickets`,
            formData,
        )
        .then(response => response.data)
    );
  },

  getStatsByState: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<Record<ETicketState, number>>(
        `${baseUrl}/${projectId}/tickets/stats-by-state`,
        params
      )
      .then(response => response.data)
      .then(
        data => {
          const result: any = {};
          Object
            .keys(data)
            .forEach(
              key => {
                result[ETicketState[key as any] as any] = (data as any)[key];
              }
            );
          return result as Record<keyof typeof ETicketState, number>;
        }
      ),

  downloadExcelFormat: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<Blob>(
        `${baseUrl}/${projectId}/tickets/download-excel-format`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DownloadTicketsToExcelFormat
        },
        { responseType: 'blob' }
      )
      .then(
        response => ({
          fileName: response.headers['response-data-header'],
          blobData: response.data
        })
      )

};

export default tickets;
