import PushTicketOnDevopsFormCreate from './PushTicketOnDevopsFormCreate';
import { IconButton, Stack, Text } from '@fluentui/react';
import { IPushTicketOnDevopsFormData } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useCallback, useEffect } from 'react';
import { emptyFunction, nameof } from 'functions';
import { ControlledTextField } from 'components';
import { ENVIRONMENT_VARIABLES } from 'constant';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { EIconName } from 'enums';
import { appStore } from 'store';
import store from './store';
import { IProps } from '.';

const VARIABLES = {
  requestId: 'PushTicketOnDevopsFormLink.getWorkItem'
};

const PushTicketOnDevopsFormLink = ({ control, setValue, watch }: IProps) => {

  const _projectSelected = useSelector(appStore.common.get.projectSelected);
  const { isLoading } = useHttpRequestStatus(VARIABLES.requestId);
  const _workItemId = useSelector(store.get.workItemId);
  const _projectId = useSelector(store.get.projectId);
  const _workItem = useSelector(store.get.workItem);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      const subscriptionTicketType = watch(
        (data, { name }) => {

          if (name === nameof<IPushTicketOnDevopsFormData>('workItemId')) {
            store.set.set(dispatch, l => ({ ...l, workItemId: data.workItemId }));
          }

        }
      );

      _projectId &&
        _workItemId &&
        handleOnClickButtonSearch();

      return () => {
        subscriptionTicketType.unsubscribe();
      };
    },
    []
  );

  const handleOnClickButtonSearch = useCallback(
    (_?: any) => {
      _projectId &&
        _workItemId &&
        httpRequestService
          .devops
          .projects
          .getWorkItem(_projectId, _workItemId, { requestId: VARIABLES.requestId })
          .then(
            workItem => {
              setValue('description', workItem.description);
              setValue('sprint', workItem.iteration);
              setValue('title', workItem.title);
              setValue('workItem', workItem);
              store
                .set
                .set(
                  dispatch,
                  l => ({
                    ...l,
                    workItem,
                    description: workItem.description,
                    sprint: workItem.iteration,
                    title: workItem.title
                  })
                );
            }
          )
          .catch(emptyFunction);
    },
    [
      _workItemId,
      _projectId
    ]
  );

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Text className='ms-fontWeight-semibold' variant='medium'>
        {t('routes.projects.ticket.workItemForm.workItemId')}
      </Text>
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
        <ControlledTextField
          disabled={isLoading}
          control={control}
          className='w-100'
          min={1}
          rules={{ required: t('common.required').toString() }}
          name={nameof<IPushTicketOnDevopsFormData>('workItemId')} />
        <IconButton
          disabled={isLoading}
          onClick={handleOnClickButtonSearch}
          iconProps={{ iconName: EIconName.search }} />
        <IconButton
          disabled={isLoading}
          className='ms-fontColor-blue'
          onClick={() => window.open(`${ENVIRONMENT_VARIABLES.devopsBaseUrl}/${_projectSelected.name}/_workitems`.replace('//', '/'), '_blank')}
          iconProps={{ iconName: EIconName.vSTSLogo }} />
      </Stack>
      {
        _workItem &&
        <PushTicketOnDevopsFormCreate control={control} setValue={setValue} watch={watch} mode='read-only' />
      }
    </Stack>
  );

};

export default memo(PushTicketOnDevopsFormLink);
