import { IHttpRequestServiceOptions, ISkill } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/consultancy-profiles';

const skills = {

  get: (consultantId: string, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<(ISkill & { manDays: number; })[]>(
        `${baseUrl}/${consultantId}/skills`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetConsultantSkills
        }
      )
      .then(response => response.data)
  )

};

export default skills;
