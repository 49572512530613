import { useDispatch } from 'react-redux';
import { IRoutes } from 'interfaces';
import { useEffect } from 'react';
import { appStore } from 'store';

const useSetCurrentRoute = (route: IRoutes, changeCurrentModule = true) => {

  const dispatch = useDispatch();

  useEffect(
    () => {

      if (route.module !== undefined) {
        appStore.common.set.setCurrentRoute(dispatch, route);
        changeCurrentModule &&
          appStore.common.set.setCurrentModule(dispatch, route.module);
      }

    },
    [route]
  );

};

export default useSetCurrentRoute;
