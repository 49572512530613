import { EApplicationModule } from 'enums';
import { TRouteConsulting } from 'types';
import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module settings in application. */
const CONSULTING = {
  module: EApplicationModule.Consulting,
  id: '/consulting',
  /** Parent of route. */
  parent: () => ROUTES as IRoutes,
  /** Base url of route */
  base: '/consulting',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), CONSULTING.base),
  relativePath: () => `consulting/*`,
  /** Description of route. */
  description: (t: Function): string => t('common.consulting'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeConsulting'*/ 'modules/consulting/routes')),

  /** Route that redirects to tickets in feature projects in application. */
  SETTINGS: {
    module: EApplicationModule.Consulting,
    id: '/consulting/settings',
    /** Parent of route. */
    parent: () => CONSULTING as IRoutes,
    /** Base url of route */
    base: '/settings',
    /** Path of route. */
    path: () => concatPath(CONSULTING.path(), CONSULTING.SETTINGS.base),
    relativePath: () => 'settings',
    /** Description of route. */
    description: (t: Function): string => t('common.settings'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeConsultingSettings'*/ 'modules/consulting/pages/settings'))
  },

  SKILL_MANAGEMENT: {
    module: EApplicationModule.Consulting,
    id: '/consulting/skill-management',
    /** Parent of route. */
    parent: () => CONSULTING as IRoutes,
    /** Base url of route */
    base: '/skill-management',
    /** Path of route. */
    path: () => concatPath(CONSULTING.path(), CONSULTING.SKILL_MANAGEMENT.base),
    relativePath: () => 'skill-management',
    /** Description of route. */
    description: (t: Function): string => t('routes.consulting.skillManagement.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeConsultingSkillManagement'*/ 'modules/consulting/pages/skillManagement'))
  },

  /** Route that redirects to overview in module settings in application. */
  PROFILES: {
    module: EApplicationModule.Consulting,
    id: '/consulting/profiles',
    /** Parent of route. */
    parent: () => CONSULTING as IRoutes,
    /** Base url of route */
    base: '/profiles',
    /** Parameter in route. */
    param: '/:consultantId',
    /** Path of route. */
    path: () => concatPath(CONSULTING.path(), CONSULTING.PROFILES.base),
    relativePath: () => `profiles/*`,
    /** Description of route. */
    description: (t: Function): string => t('routes.consulting.profiles.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeConsultingProfiles'*/ 'modules/consulting/pages/profiles')),

    PROFILE: {
      module: EApplicationModule.Consulting,
      id: '/consulting/profiles/:consultantId',
      /** Parent of route. */
      parent: () => CONSULTING.PROFILES,
      /** Base url of route */
      base: '/',
      /** Path of route. */
      path: () => concatPath(CONSULTING.PROFILES.path(), CONSULTING.PROFILES.param ?? ''),
      relativePath: () => 'profiles/:consultantId',
      /** Path of route with parameters injected. */
      pathValue: (consultantId: string) => CONSULTING.PROFILES.PROFILE.path().replace(CONSULTING.PROFILES.param ?? '', `/${consultantId}`),
      /** Description of route. */
      description: (t: Function): string => t('routes.consulting.profiles.profile.title'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeConsultingProfile'*/ 'modules/consulting/pages/profile'))
    }
  }
} as TRouteConsulting;

export default CONSULTING;
