import { formPanelService, httpRequestService } from 'services';
import { CreateUpdateProjectForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import { IProject } from 'interfaces';

interface ICallback {
  onSuccess?: (data: IProject) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      description: '',
      displayName: '',
      name: '',
      id: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IProject>
};

const useCreateUpdateProjectForm = () => {

  const { handleSubmit, control, reset } = useForm<IProject>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerFormCreate = (callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: <CreateUpdateProjectForm control={control as any} />,
        title: t('common.createProject'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                ._projects
                .create(data, { showErrorMessage: false })
                .then(id => callback?.onSuccess?.({ ...data, id }))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  const triggerFormUpdate = (consultantId: string, experience: IProject, callback?: ICallback) => {
    // reset(experience);
    // formPanelService
    //   .publish({
    //     content: <CreateUpdateProjectForm control={control as any} />,
    //     title: t('common.updateProfessionalExperience'),
    //     showCancelButton: true,
    //     showConfirmButton: true,
    //     type: PanelType.medium,
    //     confirmActionCallBack: () => (
    //       handleSubmit(
    //         data => (
    //           httpRequestService
    //             .consultancyProfiles
    //             .experiences
    //             .update(consultantId, data.id, data, { showErrorMessage: false })
    //             .then(() => callback?.onSuccess?.(data))
    //             .catch(error => callback?.onError?.(error))
    //         ),
    //         () => Promise.reject(t('common.formError.invalidForm'))
    //       )()
    //     )
    //   });
  };

  return { triggerFormCreate, triggerFormUpdate };

};

export default useCreateUpdateProjectForm;
