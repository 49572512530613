import { ControlledRichTextEditor } from 'components';
import { useTranslation } from 'react-i18next';
import { IHasDescription } from 'interfaces';
import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
};

const DescriptionForm = ({ control }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack>
      <ControlledRichTextEditor
        control={control}
        label={t('common.description')}
        rules={{ required: t('common.required').toString() }}
        name={nameof<IHasDescription>('description')}/>
    </Stack>
  );

};

export default memo(DescriptionForm);
