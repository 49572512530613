import { useCreateUpdateConsultantExperienceFormDataSkillsFiltered } from './useCreateUpdateConsultantExperienceFormDataSkillsFiltered';
import CreateUpdateConsultantExperienceFormSkillsOptions from './CreateUpdateConsultantExperienceFormSkillsOptions';
import CreateUpdateConsultantExperienceFormSkillsMandays from './CreateUpdateConsultantExperienceFormSkillsMandays';
import CreateUpdateConsultantExperienceFormSkillsViewer from './CreateUpdateConsultantExperienceFormSkillsViewer';
import { SearchBox, Stack, DefaultButton } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { EIconName, ENomenclatureType } from 'enums';
import { useTranslation } from 'react-i18next';
import { useId } from '@fluentui/react-hooks';
import { useNomenclatureData } from 'hooks';
import { memo, useState } from 'react';
import store from './store';

interface IState {
  timeoutId?: NodeJS.Timeout;
}

const CreateUpdateConsultantExperienceFormSkills = () => {

  const { data: dataSkillsFiltered } = useCreateUpdateConsultantExperienceFormDataSkillsFiltered();
  const { data: dataSkills } = useNomenclatureData<string>(ENomenclatureType.Skill);
  const _skillMandaysEditing = useSelector(store.get.skillMandaysEditing);
  const elementId = useId('CreateConsultantExperienceFormSkillsId');
  const _skillMandays = useSelector(store.get.skillMandays);
  const _formSkill = useSelector(store.get.formSkill);
  const _totalDays = useSelector(store.get.totalDays);
  const [state, setState] = useState<IState>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnSearch = () => {
    const skillFound = dataSkillsFiltered.find(l => l.title.trim().toLowerCase() === _formSkill.value.trim().toLowerCase())?.title;
    skillFound &&
      store
        .set
        .set(
          dispatch,
          l => ({
            ...l,
            formSkill: ({ ...l.formSkill, value: '' }),
            skillMandays: [...l.skillMandays, ({ skillId: skillFound, manDays: 0 })]
          })
        );
  };

  const handleOnFocusSearchBox = (hasFocus: boolean) => {

    !hasFocus &&
      state?.timeoutId &&
      clearTimeout(state.timeoutId);

    if (hasFocus) {
      store.set.set(dispatch, l => ({ ...l, formSkill: ({ ...l.formSkill, hasFocus: true }) }));
    } else {
      const timeOut = setTimeout(
        () => {
          store.set.set(dispatch, l => ({ ...l, formSkill: ({ ...l.formSkill, hasFocus: false }) }));
        },
        250

      );
      setState(l => ({ ...l, timeoutId: timeOut }));
    }

  };

  const handleOnChange = (_: any, value = '') => {
    store.set.set(dispatch, l => ({ ...l, formSkill: ({ ...l.formSkill, value }) }));
  };

  const handleOnClickButtonEditMandays = () => {
    store.set.set(dispatch, l => ({ ...l, skillMandaysEditing: !l.skillMandaysEditing }));
  };

  const skillParentIdManDays = (
    _skillMandays
      .map(
        l => ({
          ...l,
          parentId: dataSkills.find(ll => ll.id === l.skillId)?.data as any as string
        })
      )
  );

  return (
    <>
      <Stack>
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 16 }}>
          <Stack.Item grow>
            <SearchBox
              underlined
              id={elementId}
              value={_formSkill.value}
              onFocus={() => handleOnFocusSearchBox(true)}
              onBlur={() => handleOnFocusSearchBox(false)}
              placeholder={`${t('common.AddSkill')}...`}
              onChange={handleOnChange}
              onSearch={handleOnSearch} />
          </Stack.Item>
          {
            skillParentIdManDays.some(l => l.parentId) &&
            <Stack.Item>
              <DefaultButton
                primary={_skillMandaysEditing}
                disabled={_skillMandays.length === 0}
                className={`${!_skillMandaysEditing && 'border-0 ms-bgColor-gray30'}`}
                onClick={handleOnClickButtonEditMandays}
                iconProps={{ iconName: EIconName.Edit }}>
                {t('common.manDays')} ({_skillMandays.reduce((sum, currentValue) => sum + currentValue.manDays, 0)})/{Math.round(_totalDays)}
              </DefaultButton>
            </Stack.Item>
          }
        </Stack>
        {
          _formSkill.hasFocus &&
          _formSkill.value &&
          <CreateUpdateConsultantExperienceFormSkillsOptions elementId={elementId} />
        }
      </Stack>
      {
        _skillMandaysEditing
          ? <CreateUpdateConsultantExperienceFormSkillsMandays />
          : <CreateUpdateConsultantExperienceFormSkillsViewer />
      }
    </>
  );

};

export default memo(CreateUpdateConsultantExperienceFormSkills);
