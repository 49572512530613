export enum EIconName {
  NavigateExternalInline = 'NavigateExternalInline',
  pageListMirroredSolid = 'PageListMirroredSolid',
  statusCircleCheckmark = 'StatusCircleCheckmark',
  QuestionnaireMirrored = 'QuestionnaireMirrored',
  multiSelectMirrored = 'MultiSelectMirrored',
  TextDocumentShared = 'TextDocumentShared',
  statusCircleErrorX = 'StatusCircleErrorX',
  StatusCircleOuter = 'StatusCircleOuter',
  StatusCircleInner = 'StatusCircleInner',
  ProjectCollection = 'ProjectCollection',
  ReleaseDefinition = 'ReleaseDefinition',
  squareShapeSolid = 'SquareShapeSolid',
  progressRingDots = 'ProgressRingDots',
  skypeCircleCheck = 'SkypeCircleCheck',
  FavoriteStarFill = 'FavoriteStarFill',
  CalendarMirrored = 'CalendarMirrored',
  StatusCircleRing = 'StatusCircleRing',
  TestBeakerSolid = 'TestBeakerSolid',
  globalNavButton = 'GlobalNavButton',
  statusErrorFull = 'StatusErrorFull',
  ConnectContacts = 'ConnectContacts',
  completedSolid = 'CompletedSolid',
  msnVideosSolid = 'MSNVideosSolid',
  LocaleLanguage = 'LocaleLanguage',
  locationCircle = 'LocationCircle',
  publishContent = 'PublishContent',
  ChevronDownMed = 'ChevronDownMed',
  excelDocument = 'ExcelDocument',
  AzureKeyVault = 'AzureKeyVault',
  ProjectLogo16 = 'ProjectLogo16',
  AnalyticsView = 'AnalyticsView',
  GridViewLarge = 'GridViewLarge',
  LocationFill = 'LocationFill',
  ChevronRight = 'ChevronRight',
  collapseMenu = 'CollapseMenu',
  backlogBoard = 'BacklogBoard',
  ladybugSolid = 'LadybugSolid',
  FabricFolder = 'FabricFolder',
  OpenInNewTab = 'OpenInNewTab',
  WordDocument = 'WordDocument',
  ChevronUpMed = 'ChevronUpMed',
  MoreVertical = 'MoreVertical',
  BulletedList = 'BulletedList',
  shieldSolid = 'ShieldSolid',
  fullHistory = 'FullHistory',
  contactList = 'ContactList',
  ChevronLeft = 'ChevronLeft',
  ChevronDown = 'ChevronDown',
  ringerSolid = 'RingerSolid',
  peopleBlock = 'PeopleBlock',
  chromeClose = 'ChromeClose',
  BIDashboard = 'BIDashboard',
  DocumentSet = 'DocumentSet',
  ContactInfo = 'ContactInfo',
  Certificate = 'Certificate',
  PreviewLink = 'PreviewLink',
  newsSearch = 'NewsSearch',
  errorBadge = 'ErrorBadge',
  Processing = 'Processing',
  checkmark = 'CheckMark',
  peopleAdd = 'PeopleAdd',
  testSuite = 'TestSuite',
  addFriend = 'AddFriend',
  ChevronUp = 'ChevronUp',
  completed = 'Completed',
  infoSolid = 'InfoSolid',
  Translate = 'Translate',
  taskSolid = 'TaskSolid',
  Education = 'Education',
  Separator = 'Separator',
  TimeEntry = 'TimeEntry',
  pageEdit = 'PageEdit',
  Download = 'Download',
  Settings = 'Settings',
  testCase = 'TestCase',
  taskLogo = 'TaskLogo',
  vSTSLogo = 'VSTSLogo',
  codeEdit = 'CodeEdit',
  fileCode = 'FileCode',
  fileHTML = 'FileHTML',
  EditNote = 'EditNote',
  Previous = 'Previous',
  Suitcase = 'Suitcase',
  Calendar = 'Calendar',
  refresh = 'Refresh',
  History = 'History',
  contact = 'Contact',
  Unknown = 'Unknown',
  warning = 'Warning',
  message = 'Message',
  Forward = 'Forward',
  Product = 'Product',
  Attach = 'Attach',
  thisPC = 'ThisPC',
  search = 'search',
  filter = 'filter',
  ringer = 'Ringer',
  Cancel = 'Cancel',
  Delete = 'Delete',
  export = 'Export',
  signin = 'Signin',
  Ticket = 'Ticket',
  Upload = 'Upload',
  MapPin = 'MapPin',
  Remove = 'Remove',
  People = 'People',
  Share = 'Share',
  admin = 'Admin',
  clear = 'Clear',
  error = 'Error',
  merge = 'Merge',
  split = 'Split',
  Group = 'Group',
  AddTo = 'AddTo',
  Phone = 'Phone',
  mail = 'Mail',
  Info = 'Info',
  Undo = 'Undo',
  Save = 'Save',
  stop = 'Stop',
  Edit = 'Edit',
  View = 'View',
  Next = 'Next',
  bug = 'Bug',
  Add = 'Add',
  PDF = 'PDF'
}
