import { ICreateUpdateTimeEntryFormData, IHttpRequestServiceOptions, ITimeEntry } from 'interfaces';
import { httpRequestService } from 'services';
import projects from './projects';
import { dayjs } from 'config';

const baseUrl = '/time-entries';

const timeEntries = {

  projects,

  create: (data: ICreateUpdateTimeEntryFormData, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<string>(
        `${baseUrl}`,
        params,
        {
          data: ({
            ...data,
            date: dayjs(data.date).format('YYYY-MM-DD') as any
          }) as ICreateUpdateTimeEntryFormData,
          method: 'POST'
        }
      )
      .then(response => response.data)
  ),

  update: (data: ICreateUpdateTimeEntryFormData, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<string>(
        `${baseUrl}/${data.id}`,
        params,
        {
          data: ({
            ...data,
          }) as ICreateUpdateTimeEntryFormData,
          method: 'PUT'
        }
      )
      .then(response => response.data)
  ),

  get: (
    query: {
      activityId?: string;
      userId?: string;
      ids?: string[];
      type?: string;
      from?: Date;
      to?: Date;
    },
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<ITimeEntry[]>(
        `${baseUrl}`,
        params,
        {
          params: {
            ...query,
            ids: query.ids?.join(',')
          }
        }
      )
      .then(response => response.data)
  ),

  delete: (id: string, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<void>(
        `${baseUrl}`,
        params,
        {
          params: {
            id: id
          },
          method: 'DELETE'
        }
      )
      .then(response => response.data)
  ),

};

export default timeEntries;
