import { EApplicationModule } from 'enums';
import { concatPath } from 'functions';
import { TRouteProjects } from 'types';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';
import store from 'store';


function getProjectName() {
  const state = store.getState();
  return state.common.projectSelected?.name;
}

/** Route that redirects to module1 in application. */
const PROJECTS = {
  module: EApplicationModule.Projects,
  id: '/projects',
  /** Base url of route */
  base: '/projects',
  /** Parameter in route. */
  param: '/:idProject',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), ROUTES.PROJECTS.base, ROUTES.PROJECTS.param ?? ''),
  relativePath: () => `projects/:idProject/*`,
  /** Description of route. */
  description: (t: Function): string => t('routes.projects.title'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjects'*/ 'modules/projects/routes')),
  /** Route that redirects to overView in module1 in application. */

  PROJECT: {
    module: EApplicationModule.Projects,
    id: '/projects/:projectId',
    /** Parent of route. */
    parent: () => ROUTES.PROJECTS as IRoutes,
    /** Base url of route */
    base: '/',
    /** Path of route. */
    path: () => concatPath(ROUTES.PROJECTS.path(), ROUTES.PROJECTS.PROJECT.param ?? ''),
    relativePath: () => `projects/:idProject/*`,
    /** Path of route with parameters injected. */
    pathValue: (idProject: string) => PROJECTS.PROJECT.path().replace(PROJECTS.param ?? '', `/${idProject}`),
    /** Description of route. */
    description: (t: Function): string | undefined => getProjectName(),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjects'*/ 'modules/projects/routes')),
    /** Route that redirects to overView in module1 in application. */

    OVERVIEW: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/',
      /** Parent of route. */
      parent: () => ROUTES.PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/',
      /** Path of route. */
      path: () => concatPath(ROUTES.PROJECTS.PROJECT.path(), ROUTES.PROJECTS.PROJECT.OVERVIEW.base),
      relativePath: () => `/`,
      /** Path of route with parameters injected. */
      pathValue: (idProject: string) => PROJECTS.PROJECT.OVERVIEW.path().replace(PROJECTS.param ?? '', `/${idProject}`),
      /** Description of route. */
      description: (t: Function): string => t('common.overview'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsOverview'*/ 'modules/projects/pages/overview'))
    },
    /** Route that redirects to translation in feature projects in application. */
    TRANSLATIONS: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/translations',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/translations',
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.TRANSLATIONS.base),
      relativePath: () => 'translations',
      /** Path of route with parameters injected. */
      pathValue: (projectId: string) => PROJECTS.PROJECT.TRANSLATIONS.path().replace(PROJECTS.param ?? '', `/${projectId}`),
      /** Description of route. */
      description: (t: Function): string => t('routes.projects.translationFiles.pageTitle'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsTranslations'*/'modules/projects/pages/translations'))
    },
    /** Route that redirects to tickets in feature projects in application. */
    TICKETS: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/tickets',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/tickets',
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.TICKETS.base),
      relativePath: () => `tickets`,
      /** Path of route with parameters injected. */
      pathValue: (idProject: string) => PROJECTS.PROJECT.TICKETS.path().replace(PROJECTS.param ?? '', `/${idProject}`),
      /** Description of route. */
      description: (t: Function): string => t('routes.projects.tickets.pageTitle'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsTranslations'*/'modules/projects/pages/tickets'))
    },
    TICKET: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/tickets/:ticketId',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/ticket',
      /** Parameter in route. */
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.TICKET.base),
      relativePath: () => `ticket/*`,
      /** Path of route with parameters injected. */
      pathValue: (idProject: string) => PROJECTS.PROJECT.TICKET.path().replace(PROJECTS.param ?? '', `/${idProject}`),
      /** Description of route. */
      description: (t: Function): string => t('routes.projects.ticket.pageTitle'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsTranslations'*/'modules/projects/pages/ticket')),

      TICKET_PARAM: {
        module: EApplicationModule.Projects,
        id: '/projects/:projectId/tickets/:ticketId/',
        /** Parent of route. */
        parent: () => PROJECTS.PROJECT.TICKETS,
        /** Base url of route */
        base: '',
        /** Parameter in route. */
        param: '/:idTicket',
        /** Path of route. */
        path: () => concatPath(PROJECTS.PROJECT.TICKET.path(), PROJECTS.PROJECT.TICKET.TICKET_PARAM.base, PROJECTS.PROJECT.TICKET.TICKET_PARAM.param ?? ''),
        relativePath: () => 'ticket/:idTicket',
        /** Path of route with parameters injected. */
        pathValue: (idProject: string, idTicket: string) => PROJECTS.PROJECT.TICKET.TICKET_PARAM.path()
                                                            .replace(PROJECTS.param ?? '', `/${idProject}`)
                                                            .replace(PROJECTS.PROJECT.TICKET.TICKET_PARAM.param ?? '', `/${idTicket}`),
        /** Description of route. */
        description: (t: Function): string => t('routes.projects.ticket.pageTitle'),
        /** Component to display on that route. */
        lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsTranslations'*/'modules/projects/pages/ticket'))
      }
    },
    /** Route that redirects to contracts in feature projects in application. */
    CONTRACTS: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/contacts',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/contracts',
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.CONTRACTS.base),
      relativePath: () => 'contracts',
      /** Path of route with parameters injected. */
      pathValue: (idProject: string) => PROJECTS.PROJECT.CONTRACTS.path().replace(PROJECTS.param ?? '', `/${idProject}`),
      /** Description of route. */
      description: (t: Function): string => t('Contracts'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsTranslations'*/'modules/projects/pages/contracts'))
    },
    /** Route that redirects to tickets in feature projects in application. */
    RELEASE_NOTES: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/release-notes',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/release-notes',
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.RELEASE_NOTES.base),
      relativePath: () => 'release-notes',
      /** Path of route with parameters injected. */
      pathValue: (projectId: string) => PROJECTS.PROJECT.RELEASE_NOTES.path().replace(PROJECTS.param ?? '', `/${projectId}`),
      /** Description of route. */
      description: (t: Function): string => t('routes.projects.releaseNotes.pageTitle'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsReleaseNotes'*/'modules/projects/pages/releaseNotes'))
    },
    /** Route that redirects to tickets in feature projects in application. */
    SETTINGS: {
      module: EApplicationModule.Projects,
      id: '/projects/:projectId/settings',
      /** Parent of route. */
      parent: () => PROJECTS.PROJECT as IRoutes,
      /** Base url of route */
      base: '/settings',
      /** Path of route. */
      path: () => concatPath(PROJECTS.PROJECT.path(), PROJECTS.PROJECT.SETTINGS.base),
      relativePath: () => 'settings',
      /** Path of route with parameters injected. */
      pathValue: (projectId: string) => PROJECTS.PROJECT.SETTINGS.path().replace(PROJECTS.param ?? '', `/${projectId}`),
      /** Description of route. */
      description: (t: Function): string => t('common.settings'),
      /** Component to display on that route. */
      lazyComponent: lazy(() => import(/*webpackChunkName: 'routeProjectsMandrillKeys'*/'modules/projects/pages/settings'))
    }
  }
} as TRouteProjects;

export default PROJECTS;
