import { IHttpRequestServiceOptions, IMandrillParameter } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/projects';

const settings = {

  mandrill: {

    get: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<IMandrillParameter>(
          `${baseUrl}/${projectId}/settings/mandrill`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetMandrillParameterOfProject
          }
        )
        .then(response => response.data),

    update: (projectId: string, data: IMandrillParameter, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(
          `${baseUrl}/${projectId}/settings/mandrill`,
          {
            ...params,
            httpRequestName: EHttpRequestName.UpdateMandrillParameterOfProject
          },
          {
            data,
            method: 'PUT'
          }
        )

  }

};

export default settings;
