import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit';
import { IHasIsLoading, IHttpRequestError } from 'interfaces';
import { EAppInitStatus } from 'enums';
import { IStore } from 'store';

export interface IStartupStore extends IHasIsLoading {
  data?: IHttpRequestError | string;
  status: EAppInitStatus;
}

const startupSlice = createSlice({
  name: 'startup',
  reducers: {
    set: (state, action) => {
      return {
        ...state,
        isLoading: action.payload.isLoading,
        status: action.payload.status,
        data: action.payload.data
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload
      };
    },
    setStatus: (state, action) => {
      return {
        ...state,
        status: action.payload
      };
    },
    setData: (state, action) => {
      return {
        ...state,
        data: action.payload
      };
    }
  },
  initialState: {
    status: EAppInitStatus.unAuthenticated,
    isLoading: true
  } as IStartupStore
});

export default startupSlice;

const set = {
  setLoading: (dispatch: Dispatch, payload: boolean) => (
    dispatch({
      type: `${startupSlice.name}/${set.setLoading.name}`,
      payload
    })
  ),
  setStatus: (dispatch: Dispatch, payload: EAppInitStatus) => (
    dispatch({
      type: `${startupSlice.name}/${set.setStatus.name}`,
      payload
    })
  ),
  setData: (dispatch: Dispatch, payload: any) => (
    dispatch({
      type: `${startupSlice.name}/${set.setData.name}`,
      payload
    })
  ),
  set: (dispatch: Dispatch, payload: IStartupStore) => (
    dispatch({
      type: `${startupSlice.name}/${set.set.name}`,
      payload
    })
  )
};

const get = {
  isLoading: createSelector(
    (state: IStore) => state.startup.isLoading,
    isLoading => isLoading
  ),
  status: createSelector(
    (state: IStore) => state.startup.status,
    status => status
  ),
  data: createSelector(
    (state: IStore) => state.startup.data,
    data => data
  ),
  value: createSelector(
    (state: IStore) => state.startup,
    startup => startup
  )
};

export const startupStore = {
  set,
  get
};

