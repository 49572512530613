import { DatePicker, IDatePickerProps } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';

type TProps = IHookFormProps & IDatePickerProps;

const ControlledDatePicker = (props: TProps) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.defaultValue || ''}
    render={
      ({ field: { name: fieldName, onChange, onBlur, value }, fieldState: { error } }) => (
        <DatePicker
          {...props}
          onSelectDate={
            date => {
              props.onSelectDate?.(date);
              onChange(date);
            }
          }
          value={value}
          onBlur={
            event => {
              props.onBlur?.(event);
              onBlur();
            }
          }
        />
      )
    }
  />
);

export default ControlledDatePicker;
