import { CellRendererFrameworkTicketState } from 'components';
import { IDropdownOption } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ETicketState } from 'enums';
import { useMemo } from 'react';

const useDropdownOptionsTicketState = () => {

  const { t } = useTranslation();

  const options = useMemo(
    () => (
      Object
        .keys(ETicketState)
        .filter(l => isNaN(l as any))
        .map(l => ETicketState[l as keyof typeof ETicketState])
        .map(
          l => ({
            key: l,
            data: l,
            text: t(`enum.ticketState.${l}`)
          }) as IDropdownOption<ETicketState>
        )
    ),
    []
  );

  return ({
    options,
    optionRender: OptionRender,
    titleRender: TitleRender
  });

};

const OptionRender = (option?: IDropdownOption<ETicketState>) => (
  option
    ? <CellRendererFrameworkTicketState data={{ state: option.data as any }} />
    : <></>
);

const TitleRender = (options?: IDropdownOption<ETicketState>[]) => (
  options?.[0]
    ? <CellRendererFrameworkTicketState data={{ state: options[0].data as any }} />
    : <></>
);

export default useDropdownOptionsTicketState;
