import { ControlledTextField } from 'components';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { IHasName } from 'interfaces';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
}

const CreateUpdateTimeTrackingClientForm = ({ control }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledTextField
        control={control}
        label={t('common.name')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IHasName>('name')} />
    </Stack>
  );

};

export default memo(CreateUpdateTimeTrackingClientForm);
