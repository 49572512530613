export const downloadFile = (blob: Blob, fileName: string) => {
  const href = window.URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');

  anchorElement.href = href;
  anchorElement.download = fileName;
  anchorElement.style.visibility = 'hidden';

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
};
