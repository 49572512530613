import { IBroadcastCommand } from 'interfaces';
import { Subject } from 'rxjs';

const VARIABLES = {
  subject: new Subject<IBroadcastCommand>()
};

const broadcastService = {
  publish: (action: IBroadcastCommand) => VARIABLES.subject.next(action),
  observable: () => VARIABLES.subject.asObservable()
};

export default broadcastService;
