import { dayjs } from 'config';

export const getWeekPositionInMonth = (date: Date) => {
  const dayJsDate = dayjs(date);
  let currentDate = dayJsDate.startOf('month');
  let lastWeekInMonth = currentDate.week();
  let result = 1;

  while (currentDate < dayJsDate) {
    currentDate = currentDate.add(1, 'day');

    let currentWeekInMonth = currentDate.week();

    if (lastWeekInMonth !== currentWeekInMonth) {
      result++;
      lastWeekInMonth = currentWeekInMonth;
    }
  };

  return result;
};
