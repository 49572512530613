import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import { ETicketCriticality, ETicketType } from 'enums';
import { CreateTicketForm } from 'components/forms';
import { ICreateTicketFormData } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (ticketId: string) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      criticality: ETicketCriticality.minor,
      type: ETicketType.task,
      attachments: [],
      description: '',
      title: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<ICreateTicketFormData>
};

const useCreateTicketForm = () => {

  const { handleSubmit, control, reset, setValue } = useForm<ICreateTicketFormData>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (projectId: string, callback?: ICallback) => {

    reset();

    formPanelService
      .publish({
        content: <CreateTicketForm control={control as any} setValue={setValue} />,
        title: t('common.createTicket'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                ._projects
                .tickets
                .create(
                  projectId,
                  {
                    description: data.description,
                    criticality: data.criticality,
                    attachments: data.attachments,
                    title: data.title,
                    type: data.type
                  }
                )
                .then(ticketId => callback?.onSuccess?.(ticketId))
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useCreateTicketForm;
