import { Pivot, PivotItem, SpinButton, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { groupByProperty } from 'functions';
import { useNomenclatureData } from 'hooks';
import { ENomenclatureType } from 'enums';
import { memo } from 'react';
import store from './store';

const VARIABLES = {
  getNumericPart: (value: string) => {
    const numberPart = value?.split(' ')[0] ?? '0';
    return parseFloat(numberPart);
  }
};

const CreateUpdateConsultantExperienceFormSkillsMandays = () => {

  const { data: dataSkills, getTitle } = useNomenclatureData<string>(ENomenclatureType.Skill);
  const _skillMandaysEditing = useSelector(store.get.skillMandaysEditing);
  const _skillMandays = useSelector(store.get.skillMandays);
  const _totalDays = useSelector(store.get.totalDays);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const skillParentIdManDays = (
    _skillMandays
      .map(
        l => ({
          ...l,
          parentId: dataSkills.find(ll => ll.id === l.skillId)?.data as any as string
        })
      )
  );

  const skillParentIdManDaysGroupedbyParentId = groupByProperty(skillParentIdManDays, l => l.parentId);

  const suffix = `${t('common.day')}(s)`;

  const totalMandays = _skillMandays.reduce((sum, currentValue) => sum + currentValue.manDays, 0);

  const handleOnIncrementSpinButton = (value: string) => {
    if (totalMandays + 1 > _totalDays) {
      return undefined;
    }

    const floatValue = VARIABLES.getNumericPart(value);

    if (isNaN(floatValue) === false) {
      return `${floatValue + 1} ${suffix}`;
    }

    return undefined;
  };

  const handleOnDecrementSpinButton = (value: string) => {
    const floatValue = VARIABLES.getNumericPart(value);

    if (isNaN(floatValue) === false && floatValue > 0) {
      return `${floatValue - 1} ${suffix}`;
    }

    return undefined;
  };

  const handleOnChangeSpinButton = (skillId: string, value: any) => {
    const floatValue = parseFloat(value);

    if (isNaN(floatValue) || floatValue < 0) {
      return;
    }

    const oldMandays = _skillMandays.find(l => l.skillId === skillId)?.manDays ?? 0;

    if ((totalMandays - oldMandays + floatValue) > _totalDays) {
      return;
    }

    store
      .set
      .set(
        dispatch,
        l => ({
          ...l,
          skillMandays: [
            ...l.skillMandays
              .map(
                ll => {
                  if (ll.skillId === skillId) {
                    return ({ ...ll, manDays: floatValue });
                  }

                  return ll;
                }
              )
          ]
        })
      );
  };

  if (_skillMandaysEditing === false) {
    return <></>;
  }

  return (
    <Pivot className='mt-2'>
      {
        Object
          .keys(skillParentIdManDaysGroupedbyParentId)
          .filter(skillParentId => skillParentIdManDaysGroupedbyParentId[skillParentId].some(l => l.parentId))
          .map(
            skillParentId => {
              const skillMandays = skillParentIdManDaysGroupedbyParentId[skillParentId];
              const totalMandays = skillMandays.reduce((sum, currentValue) => sum + currentValue.manDays, 0);
              const title = getTitle(skillParentId);

              return (
                <PivotItem
                  key={skillParentId}
                  className='mt-2'
                  onRenderItemLink={
                    () => (
                      <Text variant='small'>
                        {title} ({totalMandays})
                      </Text>
                    )
                  }>
                  <table>
                    <tbody>
                      {
                        skillMandays
                          .map(
                            skillManday => (
                              <tr key={skillManday.skillId}>
                                <td className='me-3 pe-3'>
                                  <Text variant='small'>
                                    {getTitle(skillManday.skillId)}
                                  </Text>
                                </td>
                                <td>
                                  <SpinButton
                                    min={0}
                                    value={`${skillManday.manDays} ${suffix}`}
                                    defaultValue={`${skillManday.manDays} ${suffix}`}
                                    onChange={(_, value) => handleOnChangeSpinButton(skillManday.skillId, value)}
                                    onIncrement={value => handleOnIncrementSpinButton(value)}
                                    onDecrement={value => handleOnDecrementSpinButton(value)} />
                                </td>
                              </tr>
                            )
                          )
                      }
                    </tbody>
                  </table>
                </PivotItem>
              );
            }
          )
      }
    </Pivot>
  );

};

export default memo(CreateUpdateConsultantExperienceFormSkillsMandays);
