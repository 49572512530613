import { IBroadcastCommand } from 'interfaces';
import { broadcastService } from 'services';
import { EEventType } from 'enums';
import { useEffect } from 'react';
import { filter } from 'rxjs';

interface IParams {
  callback: <TData = any>(data: IBroadcastCommand<TData>) => void;
  events: EEventType[];
}

const useEventSouscription = ({ events, callback }: IParams) => {

  useEffect(
    () => {
      const subscription = (
        broadcastService
          .observable()
          .pipe(filter(command => events.includes(command.type)))
          .subscribe(callback)
      );

      return () => {
        subscription.unsubscribe();
      };
    },
    [
      events,
      callback
    ]
  );

};

export default useEventSouscription;
