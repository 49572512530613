import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';
import { EMPTY } from 'constant';

/** Type of component's props. */
type TProps = IHookFormProps &
  ICheckboxProps & {
    showRemainingCharacters?: boolean;
  };

/**
 * Component which wrap element 'TextField' for library 'react-hook-form'.
 * @param props Properties of component.
 * @returns JSX.Element
 */
const ControlledCheckbox = (props: TProps) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.defaultValue || EMPTY}
    render={
      ({ field: { name: fieldName, onChange, onBlur, value }, fieldState: { error } }) => (
        <Checkbox
          {...props}
          onChange={
            (event, newValue) => {
              props.onChange?.(event, newValue);
              onChange(event, newValue);
              onBlur();
            }
          }
          checked={value}
          name={fieldName}
        />
      )
    }
  />
);

export default ControlledCheckbox;
