import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useHttpRequestStatus, useNotificationsForm } from 'hooks';
import { CommandBarButton, Icon, Stack } from '@fluentui/react';
import { alertService, httpRequestService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { ENVIRONMENT_VARIABLES } from 'constant';
import { useTranslation } from 'react-i18next';
import { EHubMethod, EIconName } from 'enums';
import { INotification } from 'interfaces';
import { emptyFunction } from 'functions';
import { memo, useEffect } from 'react';
import { appStore } from 'store';
import { dayjs } from 'config';

const VARIABLES = {
  requestId: 'HeaderItemsNotifications.countUnreadNotifications'
};

const HeaderItemsNotifications = () => {

  const { data: dataUserUnreadNotifications } = useHttpRequestStatus<number>(VARIABLES.requestId, { data: 0 });
  const _isNotificationPanelVisible = useSelector(appStore.userConnected.get.isNotificationPanelVisible);
  const _notifications = useSelector(appStore.userConnected.get.notifications);
  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const { triggerForm } = useNotificationsForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(
    () => {

      if (!_isNotificationPanelVisible) {
        const cancelTokenSource = httpRequestService.getCancelTokenSource();

        httpRequestService
          .users
          .notifications
          .unread
          .count(
            _userConnected.id,
            { cancelToken: cancelTokenSource.token, requestId: VARIABLES.requestId }
          )
          .catch(emptyFunction);

        return () => {
          cancelTokenSource.cancel();
        };
      }

      return emptyFunction;

    },
    // eslint-disable-next-line
    [
      _isNotificationPanelVisible,
      _notifications.length
    ]
  );

  useEffect(
    () => {
      // Build hub connection
      const hubConnection = new HubConnectionBuilder()
        .withUrl(
          `${ENVIRONMENT_VARIABLES.baseUrlServer}/${ENVIRONMENT_VARIABLES.appReactName}/notifications/hub`,
          { accessTokenFactory: async () => await httpRequestService.azureAD.getAccessToken() }
        )
        .configureLogging(LogLevel.Warning)
        .withAutomaticReconnect()
        .build();

      // Start hub connection
      hubConnection
        .start()
        .then(
          () => {

            // Listen functions
            hubConnection
              .on(
                EHubMethod.OnNotificationReceived,
                (data: INotification) => {
                  // Publish notification created
                  appStore
                    .userConnected
                    .set
                    .set(
                      dispatch,
                      l => ({
                        ...l,
                        notifications: [
                          ...l.notifications,
                          ({
                            ...data,
                            createdOn: dayjs(data.createdOn, { utc: true }).toDate(),
                            author: data.author ?? {
                              firstName: _userConnected.firstName,
                              lastName: _userConnected.lastName,
                              id: _userConnected.id
                            },
                            read: false
                          })
                        ]
                      }),
                      l => {
                        !l.isNotificationPanelVisible &&
                          alertService.info(t('notification.messageBarText'));
                      }
                    );
                }
              );
          }
        )
        .catch(
          () => {
            hubConnection.off(EHubMethod.OnNotificationReceived);
          }
        );

      return () => {
        hubConnection.stop();
      };
    },
    // eslint-disable-next-line
    []
  );

  const handleOnClickButtonNotification = () => triggerForm();

  return (
    <Stack
      horizontal
      verticalFill
      className='app-headerItemsNotifications'
      verticalAlign='stretch'
      horizontalAlign='center'>
      {
        dataUserUnreadNotifications > 0 &&
        <Icon
          title={`(${dataUserUnreadNotifications}) ${t('common.unreadNotifications')}`}
          iconName={EIconName.StatusCircleInner}
          className='app-headerItemsNotificationsMarker text-green' />
      }
      <CommandBarButton
        onClick={handleOnClickButtonNotification}
        className='px-1'
        title={t('Notifications')}
        iconProps={{ iconName: EIconName.ringer }} />
    </Stack>
  );

};

export default memo(HeaderItemsNotifications);
