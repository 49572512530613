import { useModuleConsulting, useModuleProjects, useModuleSettings, useModuleTimeTracking } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { EApplicationModule, EIconName } from 'enums';
import { CommandBarButton } from '@fluentui/react';
import { appStore } from 'store';
import { memo } from 'react';

const VARIABLES = {
  burgerButton: {
    iconProps: {
      iconName: EIconName.globalNavButton
    }
  }
};

const HeaderLeftLogoBurger = () => {

  const _currentModule = useSelector(appStore.common.get.currentModule);
  const { menuLinks: menuLinksModuleTimeTracking } = useModuleTimeTracking();
  const { menuLinks: menuLinksModuleConsulting } = useModuleConsulting();
  const { menuLinks: menuLinksModuleSettings } = useModuleSettings();
  const { menuLinks: menuLinksModuleProjects } = useModuleProjects();
  const dispatch = useDispatch();

  const handleOnClickButton = () => appStore.common.set.toggleMenuVisibility(dispatch);

  const menuNavLinks = (
    _currentModule === EApplicationModule.Projects
      ? menuLinksModuleProjects
      : _currentModule === EApplicationModule.Settings
        ? menuLinksModuleSettings
        : _currentModule === EApplicationModule.Consulting
          ? menuLinksModuleConsulting
          : _currentModule === EApplicationModule.TimeTracking
            ? menuLinksModuleTimeTracking
            : []
  );

  if (menuNavLinks.length === 0 || !_currentModule) {
    return <></>;
  }

  return (
    <CommandBarButton
      onClick={handleOnClickButton}
      iconProps={VARIABLES.burgerButton.iconProps}
      className='px-2 app-header-bourger' />
  );

};

export default memo(HeaderLeftLogoBurger);
