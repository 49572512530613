import { IConsultantUserSharedWith, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/consultancy-profiles';

const sharing = {

  add: (
    consultantId: string,
    data: IConsultantUserSharedWith[],
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/sharing`,
        {
          ...params,
          httpRequestName: EHttpRequestName.AddConsultantUserSharedWith
        },
        {
          data,
          method: 'PUT'
        }
      )
      .then(response => response.data)
  ),

  remove: (
    consultantId: string,
    userId: string,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/sharing/${userId}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteConsultantUserSharedWith
        },
        { method: 'DELETE' }
      )
      .then(response => response.data)
  )

};

export default sharing;
