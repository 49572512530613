import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { ENVIRONMENT_VARIABLES } from 'constant';

/** Msal Configurations. */
const configuration = {
  auth: {
    authority: `${ENVIRONMENT_VARIABLES.identityServer.url}/${ENVIRONMENT_VARIABLES.identityServer.tenantId}`,
    clientId: ENVIRONMENT_VARIABLES.identityServer.clientId,
    postLogoutRedirectUri: ENVIRONMENT_VARIABLES.baseUrl,
    redirectUri: ENVIRONMENT_VARIABLES.baseUrl
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
} as Configuration;

export const azureAd = new PublicClientApplication(configuration);

export default azureAd;
