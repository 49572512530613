import { ControlledDatePicker, ControlledRichTextEditor, ControlledTextField } from 'components';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IConsultantAcademicBackground } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { memo, useEffect } from 'react';
import { nameof } from 'functions';
import store from './store';

interface IProps {
  watch: UseFormWatch<IConsultantAcademicBackground>;
  setValue: UseFormSetValue<IConsultantAcademicBackground>;
  control: Control<any>;
}

const CreateUpdateConsultantAcademicBackgroundForm = ({ control, watch, setValue }: IProps) => {

  const _description = useSelector(store.get.description);
  const _minEndDate = useSelector(store.get.minEndDate);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      store
        .set
        .set(
          dispatch,
          l => ({
            ...l,
            description: control._defaultValues.description,
            minEndDate: control._defaultValues.startDate,
            endDate: control._defaultValues.endDate,
            formSkill: {
              hasFocus: false,
              value: ''
            }
          })
        );

      const subscription = watch(
        (data, { name }) => {
          name === nameof<IConsultantAcademicBackground>('startDate') &&
            store.set.set(dispatch, l => ({ ...l, minEndDate: data.startDate as Date }));
          name === nameof<IConsultantAcademicBackground>('endDate') &&
            store.set.set(dispatch, l => ({ ...l, endDate: data.endDate as Date }));
          name === nameof<IConsultantAcademicBackground>('description') &&
            store.set.set(dispatch, l => ({ ...l, description: data.description }));
        }
      );

      return () => {
        subscription.unsubscribe();
        store.set.clear(dispatch);
      };
    },
    // eslint-disable-next-line
    []
  );

  const handleOnChangeDescription = (description: string) => {
    setValue('description', description);
    store.set.set(dispatch, l => ({ ...l, description }));
  };

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledTextField
        required
        control={control}
        label={t('common.schoolName')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantAcademicBackground>('schoolName')} />
      <ControlledTextField
        required
        control={control}
        label={t('common.degree')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantAcademicBackground>('degree')} />
      <ControlledTextField
        control={control}
        label={t('common.fieldOfStudy')}
        name={nameof<IConsultantAcademicBackground>('fieldOfStudy')} />
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Stack.Item grow>
          <ControlledDatePicker
            isRequired
            maxDate={new Date()}
            control={control}
            label={t('common.startDate')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<IConsultantAcademicBackground>('startDate')} />
        </Stack.Item>
        <Stack.Item grow>
          <ControlledDatePicker
            showCloseButton
            minDate={_minEndDate}
            control={control}
            label={t('common.endDate')}
            name={nameof<IConsultantAcademicBackground>('endDate')} />
        </Stack.Item>
      </Stack>
      <ControlledTextField
        control={control}
        label={t('common.grade')}
        name={nameof<IConsultantAcademicBackground>('grade')} />
      <ControlledTextField
        control={control}
        label={t('common.activitiesAndSocieties')}
        name={nameof<IConsultantAcademicBackground>('activitiesAndSocieties')} />
      <ControlledRichTextEditor
        label={t('common.description')}
        control={control}
        wrapperStyle={{
          borderTop: 0,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#e1dfdd'
        }}
        editorStyle={{
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          paddingBottom: '1rem'
        }}
        defaultHtml={_description}
        onChange={value => handleOnChangeDescription(value.blocks[0].text)}
        name={nameof<IConsultantAcademicBackground>('description')} />
    </Stack>
  );

};

export default memo(CreateUpdateConsultantAcademicBackgroundForm);
