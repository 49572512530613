import { IHttpRequestStatus, IHttpStatusServiceCommand } from 'interfaces';
import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit';
import { IStore } from '.';

const DEFAULT_REQUEST_ID = 'default';

const httpRequestStatusSlice = createSlice({
  name: 'httpRequestStatus',
  reducers: {
    set: (state, action) => {
      // Get command from payload
      const command = (action.payload as IHttpStatusServiceCommand);
      // Get request id
      const requestId = command.requestId ?? DEFAULT_REQUEST_ID;
      // Get current request status
      const currentHttpRequestStatus: IHttpRequestStatus<any> | undefined = state[requestId];

      return {
        ...state,
        [requestId]: {
          ...state[requestId],
          requestId,
          data: command.type === 'success'
            ? command.data
            : currentHttpRequestStatus?.data,
          error: command.type === 'loading'
            ? null
            : command.type === 'fail'
              ? command.data
              : currentHttpRequestStatus?.error,
          isLoading: command.type === 'loading' ||
            command.type === 'success' ||
            command.type === 'fail'
        }
      };
      
    }
  },
  initialState: {
    [DEFAULT_REQUEST_ID]: {
      data: null,
      isLoading: false,
      error: null,
      requestId: DEFAULT_REQUEST_ID
    }
  } as Record<string, IHttpRequestStatus<any>>
});

export default httpRequestStatusSlice;

const set = {
  set: (dispatch: Dispatch, payload: IHttpStatusServiceCommand) => (
    dispatch({
      type: `${httpRequestStatusSlice.name}/${set.set.name}`,
      payload
    })
  )
};

const get = {
  dataHttpRequestStatus: <TData>(requestId = DEFAULT_REQUEST_ID, defaultData?: TData) => createSelector(
    (state: IStore) => state.httpRequestStatus[requestId]?.data,
    data => (data ?? defaultData) as TData
  ),
  httpRequestStatus: <TData>(requestId = DEFAULT_REQUEST_ID, defaultValue?: { data?: TData, isLoading?: boolean }) => createSelector(
    (state: IStore) => state.httpRequestStatus[requestId],
    httpRequestStatus => ({
      ...httpRequestStatus,
      requestId: httpRequestStatus?.requestId ?? requestId,
      isLoading: httpRequestStatus?.isLoading ?? defaultValue?.isLoading,
      data: (httpRequestStatus?.data ?? defaultValue?.data) as TData,
      error: httpRequestStatus?.error
    })
  ),
  isLoadingHttpRequestStatus: (requestId = DEFAULT_REQUEST_ID) => createSelector(
    (state: IStore) => state.httpRequestStatus[requestId]?.isLoading,
    isLoading => isLoading ?? false
  ),
  errorHttpRequestStatus: (requestId = DEFAULT_REQUEST_ID) => createSelector(
    (state: IStore) => state.httpRequestStatus[requestId]?.error,
    error => error
  )
};

export const httpRequestStatusStore = {
  set,
  get
};
