import { Icon, Link, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { EEventType, EIconName } from 'enums';
import { INotification } from 'interfaces';
import { ROUTES } from 'constant';
import { memo } from 'react';

interface IProps {
  notification: INotification;
}

const NotificationsFormItemUrl = ({ notification }: IProps) => {

  const { t } = useTranslation();

  const handleOnClickLink = () => {
    const url = ROUTES.PROJECTS.PROJECT.TICKET.TICKET_PARAM.pathValue?.(notification.project?.id, notification.ticketId) ?? '';
    notification.project?.id &&
      notification.ticketId &&
      window.open(url, '_blank');
  };

  if (
    notification.name === EEventType.ContractFileUpdated ||
    notification.name === EEventType.TranslationEdited ||
    notification.name === EEventType.ContractCreated
  ) {
    return <></>;
  }

  return (
    <Stack horizontal verticalAlign='center' className='mt-1' tokens={{ childrenGap: 5 }}>
      <Link
        underline
        className='ms-fontWeight-semibold'
        onClick={handleOnClickLink}>
        {t('common.see')}
      </Link>
      <Icon className='ms-fontSize-12' iconName={EIconName.OpenInNewTab} />
    </Stack>
  );

};

export default memo(NotificationsFormItemUrl);
