import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { memo, useEffect, useState } from 'react';
import { breadcrumbService } from 'services';
import { emptyFunction } from 'functions';
import { useSelector } from 'react-redux';
import { appStore } from 'store';

const VARIABLES = {
  elementDividerAs: () => <span className='px-1'>/</span>,
  initState: {
    items: []
  } as IState
};

interface IState {
  items: IBreadcrumbItem[];
}

const _Breadcrumb = () => {

  const _currentModule = useSelector(appStore.common.get.currentModule);
  const [state, setState] = useState(VARIABLES.initState);

  // Bahavior when component is mounted and unmounted
  useEffect(
    () => {
      // Subscription to alert service
      const subscription = (
        breadcrumbService
          .getItems()
          .subscribe(
            items => {
              // Update last item
              if (items.length !== 0) {
                const lastItem = items[items.length - 1];
                lastItem.onClick = emptyFunction;
                lastItem.isCurrentItem = true;
              }
              // Update state
              setState(l => ({ ...l, items }));
            }
          )
      );
      // When component is unmounted
      return () => subscription.unsubscribe();
    },
    []
  );

  if (!_currentModule || !state.items.some(l => true)) {
    return <></>;
  }

  return (
    <Breadcrumb
      items={state.items}
      maxDisplayedItems={5}
      dividerAs={VARIABLES.elementDividerAs} />
  );

};

export default memo(_Breadcrumb);
