import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpRequestService } from 'services';
import { IHasId, IHasName } from 'interfaces';
import { QUERY_KEYS } from 'constant';

export interface ITimeTrackingActivity extends IHasId, IHasName {
  projectId: string;
}


export const useHttpRequestGetTimeTrackingActivities = () => {

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: [QUERY_KEYS.getTimeTrackingActivities],
    queryFn: () => (
      httpRequestService
        .request<ITimeTrackingActivity[]>(`/time-entries/activities`)
        .then(response => response.data)
    )
  });

  const refreshData = () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingActivities] });

  return ({
    data: data ?? [],
    refreshData,
    isLoading
  });

};
