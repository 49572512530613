import { MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { LoadingScreen } from 'components';
import { memo } from 'react';
import AuthError from './AuthError';
import AuthSuccess from './AuthSuccess';

const Auth = () => {

  const { t } = useTranslation();

  return (
    <MsalProvider instance={httpRequestService.azureAD}>
      <MsalAuthenticationTemplate
        loadingComponent={() => <LoadingScreen description={`${t('common.authentication')}...`} />}
        errorComponent={(props: MsalAuthenticationResult) => <AuthError {...props} />}
        authenticationRequest={httpRequestService.azureAD.getAuthenticationRequest()}
        interactionType={InteractionType.Silent}>
          <AuthSuccess/>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
  
};

export default memo(Auth);
