import { CSSProperties, memo, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { alertService } from 'services';
import { IAlert } from 'interfaces';
import AlertItem from './AlertItem';
import './Alert.scss';

const VARIABLES = {
  initState: {
    alerts: [],
    style: {}
  } as IState
};

interface IState {
  alerts: IAlert[];
  style: CSSProperties;
}

const Alert = () => {

  const [state, setState] = useState(VARIABLES.initState);

  useEffect(
    () => {
      const subscription = (
        alertService
          .getAlerts()
          .subscribe(
            alert => {
              // Check if no alerts have been passed
              if (alert.type === 'clearAll') {
                setState(l => ({ ...l, alerts: [] }));

                return;
              }

              // Check if a new alert have been published
              if (alert.type === 'add') {
                const newData = alert.data as IAlert;
                setState(l => ({ ...l, alerts: [...l.alerts, newData] }));

                const style = {
                  width: newData.width
                } as CSSProperties;

                if (newData.align === 'right') {
                  style.right = 25;
                } else {
                  style.right = 0;
                  style.left = 0;
                }

                setState(l => ({ ...l, style }));
              }
            }
          )
      );

      return () => subscription.unsubscribe();
    },
    []
  );

  return (
    <Stack
      horizontal={false}
      className='app-alert mt-3'
      style={state.style}
      horizontalAlign='center'>
      {state.alerts.map(item => (
        <AlertItem
          key={item.id}
          {...item}
          onRemove={() =>
            setState(l => ({
              ...l,
              alerts: [...l.alerts.filter(ll => ll.id !== item.id)]
            }))
          }
        />
      ))}
    </Stack>
  );

};

export default memo(Alert);
