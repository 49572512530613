import { ContentState, EditorState, RawDraftContentState } from 'draft-js';
import { ILabelProps, Label, Stack } from '@fluentui/react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { memo } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export type RichTextEditorProps = {
  defaultHtml?: string,
  onHtmlChange?: (html: string) => void,
  fullWrapperClassName?: string,
  labelProps?: ILabelProps,
  label?: string | JSX.Element,
} & EditorProps;


const RichTextEditor = (props: RichTextEditorProps) => {

  const getEditorStateFromHtml = (html: string) => {
    const { contentBlocks, entityMap } = htmlToDraft(html);
    const a = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(a);
  };

  const defaulEditorState = props.defaultEditorState
    ?? (props.defaultHtml
      ? getEditorStateFromHtml(props.defaultHtml)
      : undefined);

  const onContentStateChange = (contentState: RawDraftContentState) => {
    props.onHtmlChange?.(draftToHtml(contentState));
    props.onContentStateChange?.(contentState);
  };

  const id = Math.round(Math.random() * (1000000000));
  const fullId = 'rdw-wrapper-' + id.toString();

  const wrapperStyle = props.wrapperStyle ?? {};

  if (props.label && !(props.wrapperStyle?.border)) {
    wrapperStyle.border = '1px solid rgb(96, 94, 92)';
    wrapperStyle.borderRadius = '2px';
  }

  const handlePaste = (e: any) => {
    if (props.uploadCallback === undefined) {
      return;
    }
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      props.uploadCallback(fileObject);
    }
  };

  const handleDragDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.uploadCallback === undefined) {
      return;
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileObject = e.dataTransfer.files[0];
      props.uploadCallback(fileObject);
    }
  };

  return (
    <Stack className={'richtexteditor-fullwrapper ' + props.fullWrapperClassName}
      onPaste={handlePaste}
      onDragOver={
        event => {
          event.stopPropagation();
          event.preventDefault();
        }
      }
      onDrop={handleDragDrop}>
      {
        props.label &&
        <Label
          {...props.labelProps}
          className={'ms-label ' + props.labelProps?.className}
          htmlFor={fullId} >
          {props.label}
        </Label>
      }
      <Editor
        {...props}
        wrapperId={id}
        wrapperStyle={wrapperStyle}
        defaultEditorState={defaulEditorState}
        onContentStateChange={onContentStateChange} />
    </Stack>
  );

};

export default memo(RichTextEditor);
