import { ControlledDropdown, ControlledRichTextEditor, ControlledTextField } from 'components';
import { IDevopsSprint, IPushTicketOnDevopsFormData } from 'interfaces';
import { IDropdownOption, Stack, StackItem } from '@fluentui/react';
import { emptyFunction, nameof } from 'functions';
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { useSelector } from 'react-redux';
import store from './store';
import { IProps } from '.';

const VARIABLES = {
  requestId: 'PushTicketOnDevopsFormCreate.getSprintsOnProject'
};

const PushTicketOnDevopsFormCreate = ({ control, mode, setValue }: IProps & { mode?: 'read-only' }) => {

  const { isLoading: isLoadingSprints, data: dataSprints } = useHttpRequestStatus<IDevopsSprint[]>(VARIABLES.requestId, { data: [], isLoading: true });
  const _projectId = useSelector(store.get.projectId);
  const { t } = useTranslation();

  useEffect(
    () => {
      _projectId &&
        httpRequestService
          .devops
          .projects
          .getSprints(_projectId, { requestId: VARIABLES.requestId })
          .then(
            sprints => {
              // Get the current sprint either get las t sprint created
              const sprint = sprints.find(l => l.isCurrentSprint)?.iterationPath ?? sprints[0].iterationPath;
              setValue('sprint', sprint);
            }
          )
          .catch(emptyFunction);
    },
    [_projectId]
  );

  const dropdownOptionsSprints = useMemo(
    () => (
      (dataSprints ?? [])
        .map(
          l => ({
            data: l.iterationPath,
            key: l.iterationPath,
            text: l.name
          }) as IDropdownOption<string>
        )
    ),
    [dataSprints]
  );

  return (
    <Stack horizontal={false} tokens={{ childrenGap: 5 }}>
      <ControlledTextField
        disabled={mode === 'read-only'}
        control={control}
        label={t('common.title')}
        rules={{ required: t('routes.settings.projects.panel.required').toString() }}
        name={nameof<IPushTicketOnDevopsFormData>('title')} />
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <StackItem grow>
          <ControlledDropdown
            disabled={isLoadingSprints || mode === 'read-only'}
            control={control}
            options={dropdownOptionsSprints}
            label={t('common.sprint')}
            rules={{ required: t('routes.settings.projects.panel.required').toString() }}
            name={nameof<IPushTicketOnDevopsFormData>('sprint')} />
        </StackItem>
        <StackItem>
          <ControlledTextField
            disabled={mode === 'read-only'}
            control={control}
            style={{ width: 100 }}
            label={t('common.priority')}
            type='number'
            min={0}
            max={10}
            rules={{ required: t('common.required').toString() }}
            name={nameof<IPushTicketOnDevopsFormData>('priority')} />
        </StackItem>
      </Stack>
      <ControlledRichTextEditor
        control={control}
        label={t('common.description')}
        name={nameof<IPushTicketOnDevopsFormData>('description')} />
    </Stack>
  );

};

export default memo(PushTicketOnDevopsFormCreate);
