import { IPushTicketOnDevopsFormData, IWorkItem } from 'interfaces';
import { Dispatch } from '@reduxjs/toolkit';
import { TNullable } from 'types';
import { appStore } from 'store';

const VARIABLES = {
  key: 'PushTicketOnDevopsForm',
  initStore: {
    linkToExistingWorkItem: false,
    description: '',
    priority: 1
  } as IPushTicketOnDevopsFormStore
};

interface IPushTicketOnDevopsFormStore extends IPushTicketOnDevopsFormData {
  workItem?: TNullable<IWorkItem>;
}

const set = {
  set: (dispatch: Dispatch, setter: (state: IPushTicketOnDevopsFormStore) => IPushTicketOnDevopsFormStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  linkToExistingWorkItem: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.linkToExistingWorkItem ?? VARIABLES.initStore.linkToExistingWorkItem),
  workItemId: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.workItemId ?? VARIABLES.initStore.workItemId),
  projectId: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.projectId ?? VARIABLES.initStore.projectId),
  ticketId: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.ticketId ?? VARIABLES.initStore.ticketId),
  priority: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.priority ?? VARIABLES.initStore.priority),
  workItem: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.workItem ?? VARIABLES.initStore.workItem),
  sprint: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.sprint ?? VARIABLES.initStore.sprint),
  title: appStore.record.get.get(VARIABLES.key, (record: IPushTicketOnDevopsFormStore) => record?.title ?? VARIABLES.initStore.title)
};

const store = {
  set,
  get
};

export default store;
