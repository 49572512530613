import userConnectedSlice, { ICurrentUserStore, userConnectedStore } from './userConnected';
import httpRequestStatusSlice, { httpRequestStatusStore } from './httpRequestStatus';
import startupSlice, { IStartupStore, startupStore } from './startup';
import commonSlice, { commonStore, ICommonStore } from './common';
import { IHttpRequestStatus } from 'interfaces';
import recordSlice, { recordStore } from './record';
import { configureStore } from '@reduxjs/toolkit';

export interface IStore {
  httpRequestStatus: Record<string, IHttpRequestStatus<any>>;
  userConnected?: ICurrentUserStore;
  record: Record<string, any>;
  startup: IStartupStore;
  common: ICommonStore;
}

const store = configureStore({
  reducer: {
    httpRequestStatus: httpRequestStatusSlice.reducer,
    userConnected: userConnectedSlice.reducer,
    startup: startupSlice.reducer,
    common: commonSlice.reducer,
    record: recordSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;

export const appStore = {
  httpRequestStatus: httpRequestStatusStore,
  userConnected: userConnectedStore,
  startup: startupStore,
  common: commonStore,
  record: recordStore
};
