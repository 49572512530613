import { Icon, Stack, Text } from '@fluentui/react';
import { EIconName, ETicketType } from 'enums';
import { IHasData } from 'interfaces';
import { useEnumTitle } from 'hooks';
import { memo } from 'react';

type TProps = IHasData<{ type: ETicketType }> & {
  className?: string;
}

const CellRendererFrameworkTicketType = ({ data, className = '' }: TProps) => {  

  const { title: enumTitle } = useEnumTitle(ETicketType[data.type] as keyof typeof ETicketType);

  let iconName = EIconName.taskSolid;
  let colorClassName = 'ms-fontColor-yellow';

  if (data.type === ETicketType.bug) {
    iconName = EIconName.ladybugSolid;
    colorClassName = 'ms-fontColor-red';
  }

  return (
    <Stack horizontal verticalAlign='center'>
      <Icon iconName={iconName} className={colorClassName} />
      <Text className={`ms-1 ${className}`}>
        {enumTitle}
      </Text>
    </Stack>
  );

};

export default memo(CellRendererFrameworkTicketType);
