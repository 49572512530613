import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@fluentui/react';
import { INotification } from 'interfaces';
import { getFullName } from 'functions';
import { dayjs } from 'config';
import { memo } from 'react';

interface IProps {
  notification: INotification;
}

const NotificationsFormItemFooter = ({ notification }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack className='mt-2' horizontal horizontalAlign='space-between'>
      <Text className='ms-fontWeight-light italic' variant='small'>
        {dayjs(notification.createdOn, { utc: true }).fromNow()}
      </Text>
      {
        notification.author &&
        <Text className='ms-fontWeight-semibold italic' variant='small'>
          {`${t('common.by')} ${getFullName(notification.author)}`}
        </Text>
      }
    </Stack>
  );

};

export default memo(NotificationsFormItemFooter);
