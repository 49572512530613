import { IHasDescription, IHasTitle, ITicket } from 'interfaces';
import { formPanelService, httpRequestService } from 'services';
import { CreateReleaseNoteForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { ETicketCriticality, ETicketType } from 'enums';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (releaseNoteId: string) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      criticality: ETicketCriticality.minor,
      type: ETicketType.task,
      attachments: [],
      description: '',
      title: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IHasTitle & IHasDescription>
};

const useCreateReleaseNoteForm = () => {

  const { handleSubmit, control, reset } = useForm<IHasTitle & IHasDescription>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (projectId: string, tickets: ITicket[], callback?: ICallback) => {

    reset();

    formPanelService
      .publish({
        content: <CreateReleaseNoteForm control={control as any} tickets={tickets} />,
        title: t('common.createReleaseNote'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                ._projects
                .releaseNotes
                .create(
                  projectId,
                  {
                    ticketIds: tickets.map(l => l.id),
                    description: data.description,
                    title: data.title
                  }
                )
                .then(releaseNoteId => callback?.onSuccess?.(releaseNoteId))
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useCreateReleaseNoteForm;
