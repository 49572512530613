import { CellRendererFrameworkTypeCriticality } from 'components';
import { IDropdownOption } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ETicketCriticality } from 'enums';
import { useMemo } from 'react';

const useDropdownOptionsTicketCriticality = () => {

  const { t } = useTranslation();

  const options = useMemo(
    () => (
      Object
        .keys(ETicketCriticality)
        .filter(l => isNaN(l as any))
        .map(
          l => ({
            key: ETicketCriticality[l as keyof typeof ETicketCriticality],
            data: ETicketCriticality[l as keyof typeof ETicketCriticality],
            text: t(`common.${l}`)
          }) as IDropdownOption<ETicketCriticality>
        )
    ),
    []
  );

  return ({
    options,
    optionRender: OptionRender,
    titleRender: TitleRender
  });

};

const OptionRender = (option?: IDropdownOption<ETicketCriticality>) => (
  option
    ? <CellRendererFrameworkTypeCriticality data={{ criticality: option.data as any }} />
    : <></>
);

const TitleRender = (options?: IDropdownOption<ETicketCriticality>[]) => (
  options?.[0]
    ? <CellRendererFrameworkTypeCriticality data={{ criticality: options[0].data as any}} />
    : <></>
);

export default useDropdownOptionsTicketCriticality;
