import { IConsultantLanguage } from 'interfaces';
import { ControlledDropdown } from 'components';
import { useTranslation } from 'react-i18next';
import { useNomenclatureData } from 'hooks';
import { Control } from 'react-hook-form';
import { ENomenclatureType } from 'enums';
import { Stack } from '@fluentui/react';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  codeISO2LanguagesExcluded: string[];
  control: Control<any>;
};

const ConsultantLanguagesForm = ({ control, codeISO2LanguagesExcluded }: IProps) => {

  const { data: dataLanguageProficiencies } = useNomenclatureData<string>(ENomenclatureType.LanguageProficiency);
  const { data: dataLanguages } = useNomenclatureData<string>(ENomenclatureType.Language);
  const { t } = useTranslation();  

  return (
    <Stack className='mt-2'>
      <ControlledDropdown
        required
        options={
          dataLanguages
            .filter(l => !codeISO2LanguagesExcluded.includes(l.id))
            .map(l => ({ key: l.id, data: l.id, text: l.title }))
        }
        control={control}
        label={t('common.languages')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantLanguage>('codeISO2Language')} />
      <ControlledDropdown
        required
        options={
          dataLanguageProficiencies
            .map(l => ({ key: l.id, data: l.id, text: l.title }))
        }
        control={control}
        label={t('common.proficiency')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantLanguage>('proficiency')} />
    </Stack>
  );

};

export default memo(ConsultantLanguagesForm);
