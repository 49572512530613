import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from 'modules/time-tracking/pages/projects/store';

type TDataVariables = string[];


export const useHttpRequestDeleteTimeTrackingProject = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const _gridApi = useSelector(store.get.gridApi);
  const mutation = useMutation({
    mutationFn: (ids: TDataVariables) => (
      httpRequestService
        .request<void>(
          `/time-entries/projects`,
          { showErrorMessage: false },
          {
            method: 'DELETE',
            params: {
              ids: ids.join(',')
            }
          }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingProjects] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingActivities] });
    }
  });

  return (mutation);

};
