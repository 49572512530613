import { IHttpRequestServiceOptions, IEmbeddedSignatureLink } from 'interfaces';
import { httpRequestService } from 'services';

const helloSign = {

  getEmbeddedSignatureLink: (id: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IEmbeddedSignatureLink>(`/helloSign/embedded/${id}`, params)
      .then(response => response.data)

};

export default helloSign;
