import { ControlledRichTextEditor } from 'components';
import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { IHasValue } from 'interfaces';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
  defaultHtml?: string;
};

const TranslationFilesRichTextEditorForm = ({ control, defaultHtml }: IProps) => (
  <Stack className='mt-3'>
    <ControlledRichTextEditor
      control={control}
      wrapperStyle={{
        borderTop: 0,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#e1dfdd'
      }}
      editorStyle={{
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        paddingBottom: '1rem',
      }}
      name={nameof<IHasValue<string>>('value')}
      defaultHtml={defaultHtml}
      label='' />
  </Stack>
);

export default memo(TranslationFilesRichTextEditorForm);
