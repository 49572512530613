import { useListenBroadcastHttpStatuses } from 'hooks';
import Auth from 'auth';
import './App.scss';

const App = () => {

  useListenBroadcastHttpStatuses();

  return (
    <div className='app'>
      <Auth />
    </div>
  );

};

export default App;
