import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpRequestService } from 'services';
import { QUERY_KEYS } from 'constant';

interface IQueryParams {
  activityId?: string;
  userId?: string;
  ids?: string[];
  type?: string;
  from?: Date;
  to?: Date;
}

export const useHttpRequestGetTimeEntries = (queryParams: IQueryParams) => {

  const queryClient = useQueryClient();

  const { isLoading, data, dataUpdatedAt } = useQuery({
    queryKey: [QUERY_KEYS.getTimeEntries, queryParams],
    queryFn: ({ queryKey: [_, _queryParams] }) =>
      httpRequestService
        .timeEntries
        .get(_queryParams as any)
        .then(
          data => (
            data
              .map(
                l => ({
                  ...l,
                  date: new Date(l.date)
                })
              )
          )
        )
  });

  const refreshData = () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeEntries] });

  return ({
    dataUpdatedAt,
    refreshData,
    isLoading,
    data
  });

};
