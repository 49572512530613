import { Dropdown, IDropdownProps } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';

type TProps = IHookFormProps & IDropdownProps;

const ControlledDropdown = (props: TProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={
        ({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Dropdown
            {...props}
            onChange={
              (event, newValue, index) => {                
                props.onChange?.(event, newValue, index);
                onChange({ target: { value: newValue?.key, index } });
              }
            }
            selectedKey={
              !props.multiSelect
                ? (value ?? '')
                : undefined
            }
            onBlur={
              event => {
                props.onBlur?.(event);
                onBlur();
              }
            }
            errorMessage={error?.message} />
        )
      }
    />
  );
};

export default ControlledDropdown;
