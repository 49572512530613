import { ComboBox, IComboBoxProps } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';
import { EMPTY } from 'constant';

/** Type of component's props. */
type TProps = IHookFormProps & IComboBoxProps;

/**
 * Component which wrap element 'TextField' for library 'react-hook-form'.
 * @param props Properties of component.
 * @returns JSX.Element
 */
const ControlledComboBox = (props: TProps) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || EMPTY}
      render={
        ({ field: { name: fieldName, onChange, onBlur, value }, fieldState: { error } }) => (
          <ComboBox
            {...props}
            onChange={
              (event, newValue) => {
                props.onChange?.(event, newValue);
                
                //TODO: sending value to the controller, this is ugly
                onChange({target: {value: newValue?.key}});
                //onChange(event, newValue); <-- doesn't work when we click on dropdown because event.target is "span" element instead of "input"
              }
            }
            selectedKey={value ?? EMPTY}
            onBlur={
              (event) => {
                props.onBlur?.(event);
                onBlur();
              }}
            errorMessage={error?.message}
          />
        )
      }
    />
  );
  };

export default ControlledComboBox;
