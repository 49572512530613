import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

type TDataVariables = string;

export const useHttpRequestDeleteTimeEntry = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (id: TDataVariables) =>
      httpRequestService
      .timeEntries
      .delete(id, { showErrorMessage: false }),
      onSuccess: () => { 
        alertService.success(t('common.processSuccessfullyCompleted'));
      },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeEntries] });
    }
  });

  return (mutation);

};
