import { Text, Stack } from '@fluentui/react';
import { useNomenclatureData } from 'hooks';
import { ENomenclatureType } from 'enums';
import { IHasId } from 'interfaces';
import { memo } from 'react';
import './CreateUpdateConsultantExperienceFormSkillsOptionItem.scss';

interface IProps extends IHasId {
  onSelect: (id: string) => void;
}

const CreateUpdateConsultantExperienceFormSkillsOptionItem = ({ id, onSelect }: IProps) => {

  const { getTitle } = useNomenclatureData<string>(ENomenclatureType.Skill);

  const title = getTitle(id);

  return (
    <Stack onClick={() => onSelect(id)} className='app-CreateUpdateConsultantExperienceFormSkillsOptionItem'>
      <Stack role='button' className='px-3 py-1'>
        <Text>
          {title}
        </Text>
      </Stack>
    </Stack>
  );

};

export default memo(CreateUpdateConsultantExperienceFormSkillsOptionItem);
