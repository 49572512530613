import { IConsultantLanguage, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/consultancy-profiles';

const languages = {

  add: (
    consultantId: string,
    data: IConsultantLanguage,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/languages`,
        {
          ...params,
          httpRequestName: EHttpRequestName.AddConsultantLanguages
        },
        {
          data,
          method: 'PUT'
        }
      )
      .then(response => response.data)
  ),

  remove: (
    consultantId: string,
    codeISO2Language: string,
    params: IHttpRequestServiceOptions = {}
  ) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}/languages/${codeISO2Language}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteConsultantLanguage
        },
        { method: 'DELETE' }
      )
      .then(response => response.data)
  )

};

export default languages;
