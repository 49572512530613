/** Enum of distinct status of application after initialization. */
export enum EAppInitStatus {
  /** Indicates that user has been successfuly authenticated. */
  unAuthenticated,
  /** Indicates that user has been successfuly authenticated. */
  authenticated,
  /** Indicates that server is turned off or not available. */
  errorNetwork,
  /** Indicates that access has been denied to user authenticated. */
  error403,
  /** Indicates that internal server error happened. */
  error500
}
