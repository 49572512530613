import { Callout, PersonaSize, Stack } from '@fluentui/react';
import HeaderItemsUserPopup from './headerItemsUserPopup';
import { useId } from '@fluentui/react-hooks';
import { useSelector } from 'react-redux';
import { UserPersona } from 'components';
import { memo, useState } from 'react';
import { appStore } from 'store';

interface IState {
  isPopupVisible: boolean;
}

const VARIABLES = {
  initState: {
    isPopupVisible: false
  } as IState
};

const HeaderItemsUser = () => {

  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const [state, setState] = useState(VARIABLES.initState);
  const elementId = useId('HeaderItemsUserId');

  const togglePopupVisibility = () => setState(l => ({ ...l, isPopupVisible: !l.isPopupVisible }));

  const elementPopup = () =>
    state.isPopupVisible && (
      <Callout
        role='alertdialog'
        isBeakVisible={false}
        gapSpace={0}
        target={`#${elementId}`}
        onDismiss={togglePopupVisibility}
        children={<HeaderItemsUserPopup />} />
    );

  return (
    <>
      <Stack
        onClick={togglePopupVisibility}
        horizontalAlign='center'
        verticalAlign='center'
        id={elementId}
        role='button'>
        <UserPersona
          hidePersonaDetails
          className='mx-2'
          id={_userConnected.id}
          size={PersonaSize.size32} />
      </Stack>
      {elementPopup()}
    </>
  );

};

export default memo(HeaderItemsUser);
