import { ENVIRONMENT_VARIABLES } from 'constant';
import { useEffect } from 'react';

/**
 * Update contain of the title of the document.
 * @param title Title to be display.
 * @param appendToAppName Append title to app name.
 */
const useSetTitleDocument = (title = '', appendToAppTitle = true) => {

  // Triggered when value's changing.
  useEffect(
    () => {
      // Update content of document title.
      document.title =  appendToAppTitle
                          ? `${ENVIRONMENT_VARIABLES.appName}${title ? (' - ' + title) : ''}`
                          : title;
    },
    [
      title,
      appendToAppTitle
    ]
  );

};

export default useSetTitleDocument;
