import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

type TDataVariables = string[];

export const useHttpRequestDeleteTimeTrackingClient = () => {

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (ids: TDataVariables) => (
      httpRequestService
        .request<void>(
          `/time-entries/clients`,
          { showErrorMessage: false },
          {
            method: 'DELETE',
            params: {
              ids: ids.join(',')
            }
          }
        )
        .then(response => response.data)
    ),
    onSuccess: () => { 
      alertService.success(t('common.processSuccessfullyCompleted'));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingClients] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingProjects] });
    }
  });

  return (mutation);

};
