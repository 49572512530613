import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpRequestService } from 'services';
import { QUERY_KEYS } from 'constant';

const VARIABLES = {
  queryKeyId: QUERY_KEYS.getUserParameters
};

export const useHttpRequestGetUserParameters = () => {

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: [VARIABLES.queryKeyId],
    queryFn: () => httpRequestService.users.current.parameters.get()
  });

  const refreshData = () =>
    queryClient.invalidateQueries({ queryKey: [VARIABLES.queryKeyId] });

  return ({
    refreshData,
    isLoading,
    data
  });

};
