import { SkillManagementComponentAddSkillsForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

interface IFormData {
  parentName: string;
  skillNames: string[];
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      parentName: '',
      skillNames: []
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IFormData>
};

const useSkillManagementComponentAddSkillsForm = () => {

  const { handleSubmit, control, reset, setValue } = useForm<IFormData>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: <SkillManagementComponentAddSkillsForm setValue={setValue} control={control as any} />,
        title: t('common.createNewSkills'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .skills
                .applyTransaction({ addRange: data }, { showErrorMessage: false })
                .then(() => callback?.onSuccess?.())
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useSkillManagementComponentAddSkillsForm;
