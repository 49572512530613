import { ITextProps, Text} from "@fluentui/react"
import { memo } from "react";

export type SizeFormatProps = {
  size: number,
  stringFormat?: string
} & ITextProps;

const SizeFormat = (props: SizeFormatProps) => {
  let unit:string = '';
  let value = props.size;

  if (props.size > 999999999) {
    unit = "Go";
    value = Math.round(props.size/1000000000);
  } else if (props.size > 999999) {
    unit = "Mo";
    value = Math.round(props.size/1000000);
  } else if (props.size > 999) {
    unit = "ko";
    value = Math.round(props.size/1000);
  } else {
    unit = "o";
  }

  const sizeFormat =  value.toString() + ' ' + unit;

  const stringFormat = props.stringFormat ?? '{0}';

  return (
    <Text {...props} >
      {stringFormat.replace('{0}', sizeFormat)}
    </Text>
  );
};

export default memo(SizeFormat);