import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, IModalProps, Stack } from '@fluentui/react';
import { EIconName, EMessageBoxType } from 'enums';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messageBoxService } from 'services';
import { IHasIsLoading } from 'interfaces';
import { TFunction } from 'i18next';
import './MessageBox.scss';

interface IState extends IHasIsLoading {
  confirmActionFallback?: () => Promise<void>;
  confirmActionText: string;
  cancelActionText: string;
  type: EMessageBoxType;
  isHidden: boolean;
  content: string;
  title?: string;
}

const VARIABLES = {
  /** Icon name displayed according type of message box. */
  iconName: (type: EMessageBoxType) => {
    switch (type) {
      case EMessageBoxType.question:
        return EIconName.Unknown;

      case EMessageBoxType.info:
        return EIconName.Info;

      case EMessageBoxType.error:
        return EIconName.errorBadge;

      case EMessageBoxType.warning:
        return EIconName.warning;

      default:
        return EIconName.completed;
    }
  },
  /** Classname applied on title of message box according to his type. */
  classNameTitle: (type: EMessageBoxType) => {
    switch (type) {
      case EMessageBoxType.success:
        return 'text-green';

      case EMessageBoxType.error:
        return 'text-red';

      case EMessageBoxType.warning:
        return 'text-yellow';

      case EMessageBoxType.info:
        return 'text-blue';

      default:
        return '';
    }
  },
  /** Title of message box displayed according to type of message. */
  title: (state: IState, t: TFunction) => {
    if (state.title) {
      return state.title;
    } else {
      switch (state.type) {
        case EMessageBoxType.question:
          return t('messageBox.confirm');

        case EMessageBoxType.info:
          return 'Information';

        case EMessageBoxType.error:
          return t('messageBox.error');

        case EMessageBoxType.warning:
          return t('messageBox.warning');

        default:
          return t('messageBox.done');
      }
    }
  },

  modalProps: {
    isBlocking: true,
    className: 'app-messageBox'
  } as IModalProps,

  initState: {
    confirmActionFallback: undefined,
    confirmActionText: '',
    cancelActionText: '',
    isLoading: false,
    isHidden: true,
    content: ''
  } as IState
};

const MessageBox = () => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  useEffect(
    () => {
      const subscription = (
        messageBoxService
          .getMessages()
          .subscribe(
            message => {
              setState(
                l => ({
                  ...l,
                  confirmActionFallback: message.options?.confirmActionCallBack,
                  confirmActionText: message.options?.confirmActionText ?? t('messageBox.ok'),
                  cancelActionText: message.options?.cancelActionText ?? t('messageBox.cancel'),
                  title: message.options?.title,
                  content: message.subject,
                  type: message.type,
                  isLoading: false,
                  isHidden: false
                })
              );
            }
          )
      );

      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  const handleOnDismiss = () => setState(l => ({ ...l, isHidden: true }));

  const handleOnClickConfirm = () => {

    if (state.confirmActionFallback) {
      setState(l => ({ ...l, isLoading: true }));

      state
        .confirmActionFallback?.()
        .then(handleOnDismiss)
        .catch(error => messageBoxService.error(error))
        .finally(() => setState(l => ({ ...l, isLoading: false })));
    } else {
      setState(l => ({ ...l, isHidden: true }));
    }

  };

  return (
    <Dialog
      modalProps={VARIABLES.modalProps}
      onDismiss={handleOnDismiss}
      hidden={state.isHidden}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <Stack className={VARIABLES.classNameTitle(state.type)} horizontal verticalAlign='center'>
            <Icon className='mt-1 me-2' iconName={VARIABLES.iconName(state.type)} />
            {VARIABLES.title(state, t)}
          </Stack>
        ),
        subText: (state.content as any)?.Title ?? state.content
      }}>
      <DialogFooter>
        <DefaultButton
          primary
          disabled={state.isLoading}
          onClick={handleOnClickConfirm}
          text={state.confirmActionText} />
        {
          state.confirmActionFallback &&
          <DefaultButton disabled={state.isLoading} onClick={handleOnDismiss} text={state.cancelActionText} />
        }
      </DialogFooter>
    </Dialog>
  );
};

export default memo(MessageBox);
