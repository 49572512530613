import { useDispatch, useSelector } from 'react-redux';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { INomenclature } from 'interfaces';
import { ENomenclatureType } from 'enums';
import { emptyFunction } from 'functions';
import { useEffect } from 'react';
import store from './store';

const useNomenclatureData = <TId, TData = null>(type: ENomenclatureType, forceReset = false) => {

  const requestId = `useNomenclatureData.${type}`;

  const { isLoading } = useHttpRequestStatus(requestId, { isLoading: true });
  const _store = useSelector(store.get.store);
  const dispatch = useDispatch();

  const hasBeenLoaded = ![null, undefined].includes((_store as any)?.[type]);
  const data = (_store?.[type] ?? []) as any as INomenclature<TId, TData>[];

  useEffect(
    () => {

      if (forceReset || !hasBeenLoaded) {
        const cancelTokenSource = httpRequestService.getCancelTokenSource();

        httpRequestService
          .nomenclatures
          .get<number>(type, { cancelToken: cancelTokenSource.token, requestId })
          .then(nomenclatures => updateStore(nomenclatures));

        return () => cancelTokenSource.cancel();
      }

      return emptyFunction();

    },
    // eslint-disable-next-line
    [
      type,
      forceReset,
      hasBeenLoaded
    ]
  );

  const updateStore = (value: any) => store.set.set(dispatch, l => ({ ...l, [type]: value }));

  return ({
    data,
    isLoading,
    getTitle: (id: TId) => data.find(l => l.id === id)?.title
  });

};

export default useNomenclatureData;
