import { TextField } from '@fluentui/react';
import { CustomCellEditorProps } from 'ag-grid-react';

export const CellTextEditor = ({ value, onValueChange }: CustomCellEditorProps) => {

    const handleOnChangeTextField = (event: any) => {
      const val = event.target.value as string;
      onValueChange(val);
    };

    return (
      <TextField
        multiline
        rows={5}
        style={{ minWidth: 400 }}
        onChange={handleOnChangeTextField}
        onBlur={handleOnChangeTextField}
        value={value} />
    );
  }
