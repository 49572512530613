import { IHasDescriptionOptional, IHasId, IHasName, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';

export interface ITimeTrackingProject extends IHasId, IHasName, IHasDescriptionOptional {
  clientId: string;
}

const baseUrl = '/time-entries/projects';

const projects = {

  get: (params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<ITimeTrackingProject[]>(`${baseUrl}`, params,)
      .then(response => response.data)
  )

};

export default projects;
