export enum EEventType {
  projectCreated = 'projectCreated',
  projectUpdated = 'projectUpdated',
  projectDeleted = 'projectDeleted',

  // Tickets  
  MoreInformationRequestedOnTicket = 'MoreInformationRequestedOnTicket',
  AttachmentDeletedFromTicket = 'AttachmentDeletedFromTicket',
  TicketPublishedOnProduction = 'TicketPublishedOnProduction',
  CommentDeletedFromTicket = 'CommentDeletedFromTicket',
  DevelopementDoneOnTicket = 'DevelopementDoneOnTicket',
  TicketPublishedOnStaging = 'TicketPublishedOnStaging',
  AttachmentAddedOnTicket = 'AttachmentAddedOnTicket',
  NewCommentAddedOnTicket = 'NewCommentAddedOnTicket',
  TicketDescriptionEdited = 'TicketDescriptionEdited',
  TicketPropertiesChanged = 'TicketPropertiesChanged',
  TicketPublishedOnDevOps = 'TicketPublishedOnDevOps',
  TicketWipedFromDatabase = 'TicketWipedFromDatabase',
  TicketUnassignedToUser = 'TicketUnassignedToUser',
  TicketUnlinkFromDevOps = 'TicketUnlinkFromDevOps',
  TicketCommentArchived = 'TicketCommentArchived',
  CommentEditedOnTicket = 'CommentEditedOnTicket',
  TicketCommentRestored = 'TicketCommentRestored',
  TicketTestedRejected = 'TicketTestedRejected',
  TicketAssignedToUser = 'TicketAssignedToUser',
  TicketValidated = 'TicketValidated',
  TicketApproved = 'TicketApproved',
  TicketRejected = 'TicketRejected',
  TicketReOpened = 'TicketReOpened',
  TicketCreated = 'TicketCreated',
  TicketStarted = 'TicketStarted',
  TicketClosed = 'TicketClosed',
  TicketOpened = 'TicketOpened',

  // Projects
  TranslationEdited = 'TranslationEdited',

  // Contract
  ContractFileUpdated = 'ContractFileUpdated',
  ContractCreated = 'ContractCreated',

  ticketUpdated = 'ticketUpdated',

  // ReleaseNotes
  releaseNoteCreated = 'releaseNoteCreated',

  consultancyProfileCreated = 'consultancyProfileCreated',
  consultancyProfileUpdated = 'consultancyProfileUpdated',

  timeEntryCreated = 'timeEntryCreated'
}
