import packageJson from '../../package.json';
import { EMPTY } from 'constant';

/** Object that contain all environment variables. */
const ENVIRONMENT_VARIABLES = {
  /** Identity server. */
  identityServer: {
    /** Url to join identity server. */
    url: process.env.REACT_APP_IDENTITY_SERVER_URL ?? EMPTY,
    /** Scope requested to authenticate. */
    scope : process.env.REACT_APP_IDENTITY_SERVER_SCOPE ?? EMPTY,
    /** Identity of client configured in identity server. */
    clientId : process.env.REACT_APP_IDENTITY_SERVER_CLIENT_ID ?? EMPTY,
    /** Identity of tenant configured in identity server. */
    tenantId : process.env.REACT_APP_IDENTITY_SERVER_TENANT_ID ?? EMPTY
  },
  /** Name of environment that run application. */
  environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
  /** If environment is prod */
  isProd: process.env.REACT_APP_ENVIRONMENT_NAME === 'PROD',
  /** Title of application. */
  appName: process.env.REACT_APP_APPLICATION_NAME ?? EMPTY,
  /** Url to join identity server. */
  baseUrlServer: `${process.env.REACT_APP_SERVER}/api`,
  /** Url of application. */
  baseUrl: process.env.REACT_APP_URL ?? EMPTY,
  /** Version of application. */
  version: packageJson.version,
  /** Base Url of TP's Azure Devops */
  devopsBaseUrl: process.env.REACT_APP_DEVOPS_BASE_URL ?? EMPTY,
  /** HelloSign client id */
  helloSignClientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID ?? EMPTY,
  
  appReactName: 'XChange'
};

export default ENVIRONMENT_VARIABLES;
