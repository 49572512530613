import { IBaseFile, IHttpRequestServiceOptions, ITicketFile } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/tickets';

const attachments = {

  getBinaryData: (ticketId: string, attachmentId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<Blob>(
        `${baseUrl}/${ticketId}/attachments/${attachmentId}`,
        params,
        { responseType: 'blob' }
      )
      .then(response => response.data),

  get: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<ITicketFile[]>(
        `${baseUrl}/${ticketId}/attachments`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetTicketAttachments
        }
      )
      .then(response => response.data),

  delete: (ticketId: string, attchmentId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${ticketId}/attachments/${attchmentId}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteTicketAttachments
        },
        { method: 'DELETE' }
      ),

  add: (ticketId: string, file: IBaseFile, params: IHttpRequestServiceOptions = {}) => {
    const data = new FormData();
    data.append('file', file as any);

    return httpRequestService
      .request<string>(
        `${baseUrl}/${ticketId}/attachments`,
        {
          ...params,
          httpRequestName: EHttpRequestName.AddTicketAttachment
        },
        {
          method: 'POST',
          data
        }
      )
      .then(response => response.data);
  }

};

export default attachments;
