import { IHttpRequestServiceOptions, IWorkItem, IDevopsSprint, IHasTitle, IHasTicketId } from 'interfaces';
import { EHttpRequestName } from 'enums';
import httpRequestService from '.';
import { TNullable } from 'types';

const baseUrl = '/devops';

const devops = {

  projects: {

    getWorkItem: (projectId: string, workItemId: number, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<IWorkItem>(
          `${baseUrl}/projects/${projectId}/workitems/${workItemId}`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetDevopsWorkItem
          }
        )
        .then(response => response.data),

    getSprints: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<IDevopsSprint[]>(
          `${baseUrl}/projects/${projectId}/sprints`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetDevopsSprintsOnProject
          }
        )
        .then(response => response.data ?? []),

    get: (params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<string[]>(
          `/devops/projects`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetDevopsProjects
          }
        )
        .then(response => response.data),

    repositories: {

      get: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
          .request<string[]>(
            `/devops/projects/${projectId}/repositories`,
            {
              ...params,
              httpRequestName: EHttpRequestName.GetDevopsRepositoriesOnProject
            }
          )
          .then(response => response.data),

      branches: {

        get: (projectId: string, repositoryName: string, params: IHttpRequestServiceOptions = {}) =>
          httpRequestService
            .request<string[]>(
              `/devops/projects/${projectId}/repositories/${repositoryName}/branches`,
              {
                ...params,
                httpRequestName: EHttpRequestName.GetDevopsBranchesOfRepositoryOnProject
              }
            )
            .then(response => response.data)

      }
    },

    workItems: {
      /** Get list of work items (tasks and bugs only) with parents of project from Azure Devops */
      getAll: (projectId: string, sprint = '', params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
          .request<IWorkItem[]>(`/devops/projects/${projectId}/workItems?sprint=${sprint}`, params)
          .then(response => response.data)
    }

  },

  createWorkItem: (
    data: IHasTitle & IHasTicketId & {
      description?: TNullable<string>;
      priority: number;
      sprint: string;
    },
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<number>(
        `${baseUrl}/workitem`,
        {
          ...params,
          httpRequestName: EHttpRequestName.CreateDevopsWorkItem
        },
        {
          data,
          method: 'POST'
        }
      )
      .then(response => response.data)
};

export default devops;
