import { IBreadcrumbItem } from '@fluentui/react';
import { Subject } from 'rxjs';

/** Global variables */
const VARIABLES = {
  /** Subject of service 'alert'. */
  subject: new Subject<IBreadcrumbItem[]>()
};

/** Service used to handle Breadcrumb in application. */
const breadcrumbService = {
  /**
   * Publish items in breadcrumb.
   * @param items Items to display in breadcrumb.
   */
  publish: (items: IBreadcrumbItem[]) => VARIABLES.subject.next(items),
  /**
   * Get items ob breadcrumb published.
   * @returns
   */
  getItems: () => VARIABLES.subject.asObservable()
};

export default breadcrumbService;
