import { DependencyList, useEffect } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

interface IProps {
  set: {
    clear: (dispatch: Dispatch) => any;
    init: (dispatch: Dispatch) => any;
  }
}

const useStore = (store: IProps, deps: DependencyList = []) => {

  const dispatch = useDispatch();

  useEffect(
    () => {
      store.set.init(dispatch);
      return () => {
        store.set.clear(dispatch);
      };
    },
    // eslint-disable-next-line
    deps
  );

};

export default useStore;
