import imgLogo from 'assets/images/png/Logo-Xchange_minim.png';
import { useDispatch, useSelector } from 'react-redux';
import { ENVIRONMENT_VARIABLES } from 'constant';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { appStore } from 'store';
import { memo } from 'react';

const HeaderItemsUserPopupHeader = () => {

  const _isUserConnected = useSelector(appStore.userConnected.get.isUserConnected);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnClickLogout = () => {
    appStore.userConnected.set.set(dispatch, l => ({ ...l, isLoggingOut: true }));
    httpRequestService.azureAD.logoutRedirect();
  };

  if (!_isUserConnected) {
    return <></>;
  }

  return (
    <div>
      <div>
        <img src={imgLogo} alt={ENVIRONMENT_VARIABLES.appName} />
      </div>
      <div onClick={handleOnClickLogout} className='cursor-pointer'>
        {t('common.signOut')}
      </div>
    </div>
  );

};

export default memo(HeaderItemsUserPopupHeader);
