import { broadcastService, convertService, httpRequestService } from 'services';
import { IHttpRequestServiceOptions, IProject } from 'interfaces';
import { EEventType, EHttpRequestName } from 'enums';
import translations from './translations';
import releaseNotes from './releaseNotes';
import settings from './settings';
import members from './members';
import tickets from './tickets';

const baseUrl = '/projects';

const projects = {

  delete: (ids: string[], params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}?${convertService.toQueryString(ids, 'ids')}`,
        params,
        { method: 'DELETE' }
      )
      .then(() => broadcastService.publish({ type: EEventType.projectDeleted, payload: [...ids] })),

  update: (id: string, data: Omit<Omit<IProject, 'id'>, 'name'>, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${id}`,
        params,
        {
          data,
          method: 'PUT'
        }
      )
      .then(
        () => {
          broadcastService.publish({ type: EEventType.projectUpdated, payload: { ...data } as IProject });
        }
      ),

  create: (data: IProject, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<string>(
        `${baseUrl}`,
        params,
        {
          data,
          method: 'POST'
        }
      )
      .then(
        response => {
          // Publish event
          broadcastService.publish({ type: EEventType.projectCreated, payload: { ...data, id: response.data } as IProject });
          // Send result
          return response.data;
        }
      ),

  get: (
    query: {
      name?: string;
      ids?: string[];
    } = {},
    params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IProject[]>(
        `${baseUrl}?${convertService.toQueryString(query.ids, 'ids')}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjects
        },
        {
          params: {
            name: query.name
          }
        }
      )
      .then(response => response.data),

  getSingle: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    projects
      .get({ ids: [projectId] }, params)
      .then(projects => projects[0]),

  releaseNotes,
  translations,
  settings,
  tickets,
  members

};

export default projects;
