import { Dispatch } from 'redux';
import { appStore } from 'store';

export const selectCurrentProject = (httpRequestService: any, dispatch: Dispatch, projectId: string) =>
  (
    async () => {

      if (projectId) {
        const project = await httpRequestService._projects.getSingle(projectId, { showErrorMessage: false });
        const members = await httpRequestService._projects.members.getMembersRoles(projectId, { showErrorMessage: false }).catch(() => []);

        appStore
          .common
          .set
          .setProjectSelected(
            dispatch,
            {
              description: project?.description,
              displayName: project?.displayName,
              name: project?.name,
              id: project?.id,
              members
            }
          );
      }

    }
  )();

export default selectCurrentProject;
