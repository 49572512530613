import ProfileSectionExperiencesItemParent from 'modules/consulting/pages/profile/profileSection/profileSectionExperiences/ProfileSectionExperiencesItemParent';
import { Text, Separator, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { groupByProperty } from 'functions';
import { useNomenclatureData } from 'hooks';
import { ENomenclatureType } from 'enums';
import { memo } from 'react';
import store from './store';

const CreateUpdateConsultantExperienceFormSkillsViewer = () => {

  const { data: dataSkills } = useNomenclatureData<string>(ENomenclatureType.Skill);
  const _skillMandaysEditing = useSelector(store.get.skillMandaysEditing);
  const _skillMandays = useSelector(store.get.skillMandays);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnDeleteSkill = (skillId: any) => {
    const skillMandays = [..._skillMandays.filter(l => l.skillId !== skillId)];
    store.set.set(dispatch, l => ({ ...l, skillMandays }));
    return Promise.resolve();
  };

  const skillParentIdManDays = (
    _skillMandays
      .map(
        l => ({
          ...l,
          parentId: dataSkills.find(ll => ll.id === l.skillId)?.data as any as string
        })
      )
  );

  const skillParentIdManDaysGroupedbyParentId = groupByProperty(skillParentIdManDays, l => l.parentId);

  if (_skillMandaysEditing) {
    return <></>;
  }

  if (_skillMandays.length === 0) {
    return (
      <Separator className='mt-2'>
        <Text className='ms-fontColor-gray80 italic'>
          {t('common.none')}
        </Text>
      </Separator>
    );
  }

  if (_skillMandays.length === 0) {
    return <></>;
  }

  return (
    <Stack className='mt-2'>
      {
        Object
          .keys(skillParentIdManDaysGroupedbyParentId)
          .filter(skillParentId => skillParentIdManDaysGroupedbyParentId[skillParentId].some(l => l.parentId))
          .map(
            skillParentId => (
              <ProfileSectionExperiencesItemParent
                allowDeletion
                key={skillParentId}
                skillParentId={skillParentId}
                onDelete={handleOnDeleteSkill}
                skillMandays={skillParentIdManDaysGroupedbyParentId[skillParentId]} />
            )
          )
      }
    </Stack>
  );

};

export default memo(CreateUpdateConsultantExperienceFormSkillsViewer);
