import { IBreadcrumbItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { breadcrumbService } from 'services';
import { useSelector } from 'react-redux';
import { IRoutes } from 'interfaces';
import { useEffect } from 'react';
import { ROUTES } from 'constant';
import { appStore } from 'store';

const useBreadcrumb = (route?: IRoutes, textLastRoute?: string) => {

  const _projectSelected = useSelector(appStore.common.get.projectSelected);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getKey = (route?: IRoutes) => {

    // For some case
    if (route?.path() === ROUTES.PROJECTS.path()
      || route?.parent?.().path() === ROUTES.PROJECTS.path()) {
      return route?.pathValue?.(_projectSelected?.id) ?? '';
    }

    return route?.path() ?? '';

  };

  // Triggered when value's changing.
  useEffect(
    () => {
      // Items to publish to breadcrumb
      let items: IBreadcrumbItem[] = [];

      // Check variable
      if (route) {
        // Current route
        let _route = { ...route } as IRoutes | undefined;
        do {
          // Append to result
          items.push({
            onClick: (_, item) => navigate(item?.key ?? ROUTES.base),
            style: { fontSize: 14 },
            as: 'a',
            text: _route?.description(t) ?? '',
            href: `#`,
            key: getKey(_route)
          });
          // Update current route
          _route = _route?.parent?.();
        } while (_route);

        if (textLastRoute && items[0]) {
          items[0].text = textLastRoute;
        }

        // Reverse array
        items = items.reverse();
      }

      // Publish breadcrum items
      breadcrumbService.publish(items);

    },
    // eslint-disable-next-line
    [
      _projectSelected,
      textLastRoute,
      route,
      t
    ]
  );

};

export default useBreadcrumb;
