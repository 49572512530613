import { EMPTY } from 'constant';

declare global {

  interface String {
    /** Append string value to current string. */
    append(value: string): string;
    /** Prepend string value to current string. */
    prepend(value: string): string;
    /** Prepare string for base64 value. */
    toBase64(): string;
  }

}

/** Append string value to current string. */
// eslint-disable-next-line no-extend-native
String.prototype.append = (appendString: string) => `${this}${appendString}`;

/** Prepend string value to current string. */
// eslint-disable-next-line no-extend-native
String.prototype.prepend = (appendString: string) => `${appendString}${this}`;

/** Prepare string for base64 value. */
// eslint-disable-next-line no-extend-native
String.prototype.toBase64 = function (this: string) {  
  return (
    !this
      ? EMPTY
      : `data:image/jpeg;base64,${this}`
  );
};
