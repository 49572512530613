import imgNoResult from 'assets/images/png/no-result.png';
import { Stack, Text } from '@fluentui/react';
import { memo } from 'react';
import './NoResult.scss';

interface IProps {
  description?: string;
  hideImage?: boolean;
  className?: string;
  title?: string;
}


const NoResult = ({ description = '', title = 'No data to display', className = '', hideImage = false }: IProps) => {
  return (
    <Stack className={`app-NoResult mt-5 ${className}`} horizontal={false}>
      {
        hideImage === false &&
        <Stack.Item align='center'>
          <img src={imgNoResult} alt='No result' />
        </Stack.Item>
      }
      <Stack.Item className='my-3' align='center'>
        <Text variant='xLarge'>{title}</Text>
      </Stack.Item>
      <Stack.Item align='center'>
        <Text variant='mediumPlus'>{description}</Text>
      </Stack.Item>
    </Stack>
  );
};

export default memo(NoResult);
