import { useHttpRequestGetTimeTrackingProjects } from 'hooks/httpRequest';
import { ControlledDropdown, ControlledTextField } from 'components';
import { IDropdownOption, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { IHasName } from 'interfaces';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
}

const CreateUpdateTimeTrackingActivityForm = ({ control }: IProps) => {

  const { data: dataProjects } = useHttpRequestGetTimeTrackingProjects();
  const { t } = useTranslation();

  const dataOptionProjects = (
    dataProjects
      .map(
        l => ({
          key: l.id,
          data: l.id,
          text: l.name
        }) as IDropdownOption<string>
      )
  );

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledDropdown
        className='w-100'
        control={control}
        options={dataOptionProjects}
        label={t('common.project')}
        rules={{ required: t('common.formError.required').toString() }}
        name={'projectId'} />
      <ControlledTextField
        control={control}
        label={t('common.name')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IHasName>('name')} />
    </Stack>
  );

};

export default memo(CreateUpdateTimeTrackingActivityForm);
