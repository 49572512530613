import { GridApi } from 'ag-grid-community';
import { IHasId, IHasName } from 'interfaces';
import { Dispatch } from '@reduxjs/toolkit';
import { appStore } from 'store';

interface IData extends IHasId, IHasName {
}

const VARIABLES = {
  key: 'timeTracking.activities',
  initStore: {
    rowsDataTableSelected: [],
    filter: {
      value: ''
    }
  } as IProjectsStore
};

export interface IProjectsStore {
  rowsDataTableSelected: IData[];
  gridApi?: GridApi;
  filter: {
    value: string;
  }
}

const set = {
  set: (dispatch: Dispatch, setter: (state: IProjectsStore) => IProjectsStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  rowsDataTableSelected: appStore.record.get.get(VARIABLES.key, (record: IProjectsStore) => record?.rowsDataTableSelected ?? VARIABLES.initStore.rowsDataTableSelected),
  gridApi: appStore.record.get.get(VARIABLES.key, (record: IProjectsStore) => record?.gridApi ?? VARIABLES.initStore.gridApi),
  filter: appStore.record.get.get(VARIABLES.key, (record: IProjectsStore) => record?.filter ?? VARIABLES.initStore.filter)
};

const store = {
  set,
  get
};

export default store;
