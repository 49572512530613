import {EEventType, EHttpRequestName, ETicketCriticality, ETicketType} from 'enums';
import {IHasTitle, IHttpRequestServiceOptions} from 'interfaces';
import {broadcastService, httpRequestService} from 'services';
import attachments from './attachments';
import {TNullable} from 'types';
import comments from './comments';
import events from './events';
import {axiosInstance} from "../../../config";

const baseUrl = '/tickets';

const tickets = {

    open: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/open`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.OpenTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketOpened, payload: {ticketId}});
                }
            ),

    update: (
        ticketId: string,
        data: IHasTitle & {
            description?: TNullable<string>;
            criticality: ETicketCriticality;
            type: ETicketType;
        },
        params: IHttpRequestServiceOptions = {}
    ) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.UpdateTicket
                },
                {
                    data,
                    method: 'PUT'
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.ticketUpdated, payload: ({...data, ticketId})});
                }
            ),

    delete: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.DeleteTicket
                },
                {method: 'DELETE'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketWipedFromDatabase, payload: {ticketId}});
                }
            ),

    approve: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/approve`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.ApproveTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketApproved, payload: {ticketId}});
                }
            ),

    reject: (ticketId: string, comment?: string) =>
        axiosInstance
            .put<void>(
                `${baseUrl}/${ticketId}/reject`,
                {
                    comment: comment,
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketRejected, payload: {ticketId, comment}});
                }
            ),

    rejectTest: (ticketId: string, comment: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/reject-test`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.RejectTestOnTicket
                },
                {
                    data: {comment: comment},
                    method: 'PUT'
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketTestedRejected, payload: {ticketId, comment}});
                }
            ),

    close: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/close`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.CloseTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketClosed, payload: {ticketId}});
                }
            ),

    linkToDevops: (ticketId: string, workItemId: number, description: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/link-to-devops`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.LinkTicketToDevops
                },
                {
                    data: {
                        workItemId,
                        description
                    },
                    method: 'PUT'
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketPublishedOnDevOps, payload: {ticketId}});
                }
            ),

    requestMoreInfo: (ticketId: string, comment: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/request-more-info`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.RequestmoreInfoOnTicket
                },
                {
                    data: {comment:comment},
                    method: 'PUT'
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.MoreInformationRequestedOnTicket, payload: {ticketId}});
                }
            ),

    start: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/start`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.StartTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketStarted, payload: {ticketId}});
                }
            ),

    completeDevelopement: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/complete-developement`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.CompleteDevelopementOnTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.DevelopementDoneOnTicket, payload: {ticketId}});
                }
            ),

    pushOnStaging: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/push-on-staging`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.PustTicketOnStaging
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketPublishedOnStaging, payload: {ticketId}});
                }
            ),

    validate: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/validate`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.ValidateTicket
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketValidated, payload: {ticketId}});
                }
            ),

    pushOnProduction: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/push-on-production`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.PustTicketOnProduction
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketPublishedOnProduction, payload: {ticketId}});
                }
            ),

    reOpen: (ticketId: string, comment: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/re-open`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.OpenTicket
                },
                {
                    data: {comment: comment},
                    method: 'PUT'
                }
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketReOpened, payload: {ticketId}});
                }
            ),

    removeLinkWorkItem: (ticketId: string, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<void>(
                `${baseUrl}/${ticketId}/remove-workItem`,
                {
                    ...params,
                    httpRequestName: EHttpRequestName.RemoveTicketWorkItem
                },
                {method: 'PUT'}
            )
            .then(
                () => {
                    broadcastService.publish({type: EEventType.TicketUnlinkFromDevOps, payload: {ticketId}});
                }
            ),

    attachments,
    comments,
    events
};

export default tickets;
