import { ControlledRichTextEditor, ControlledTextField } from 'components';
import { IHasDescription, IHasTitle, ITicket } from 'interfaces';
import { Separator, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { convertService } from 'services';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
  tickets: ITicket[];
};

const CreateReleaseNoteForm = ({ control, tickets }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledTextField
        control={control}
        label={t('common.title')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IHasTitle>('title')} />
      <ControlledRichTextEditor
        editorStyle={{ paddingLeft: 10, paddingRight: 10, maxHeight: 400, minHeight: 200, backgroundColor: 'white' }}
        control={control}
        label={t('common.description')}
        name={nameof<IHasDescription>('description')} />
      <Stack horizontal={false} className='mt-3 mb-0'>
        <Text variant='medium' className='ms-fontWeight-semibold'>
          {t('common.tickets')}
        </Text>
        <Separator />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {
            tickets
              .map(
                item => (
                  <Text key={item.id} className='badge ms-bgColor-gray20'>
                    {convertService.ticketNumberToString(item.ticketNumber)}
                  </Text>
                )
              )
          }
        </Stack>
      </Stack>
    </Stack>
  );

};

export default memo(CreateReleaseNoteForm);
