import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module demo in application. */
const DASHBOARD = {
  id: '/dashboard',
  /** Base url of route */
  base: '/dashboard',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), ROUTES.DASHBOARD.base),
  relativePath: () => 'dashboard',
  /** Description of route. */
  description: (t: Function): string => t('Dashboard'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeDashboard'*/ 'modules/dashboard')),
  /** Parent of route. */
  parent: () => ROUTES as IRoutes
} as IRoutes;

export default DASHBOARD;
