import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'modules/time-tracking/pages/activities/store';

type TDataVariables = string[];

const VARIABLES = {
  queryKeyId: () => [QUERY_KEYS.deleteTimeTrackingActivities]
};

export const useHttpRequestDeleteTimeTrackingActivities = () => {
  const _gridApi = useSelector(store.get.gridApi);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    //mutationKey: VARIABLES.queryKeyId(),
    mutationFn: (ids: TDataVariables) => (
      httpRequestService
        .request<void>(
          `/time-entries/activities`,
          { showErrorMessage: false },
          {
            method: 'DELETE',
            params: {
              ids: ids.join(',')
            }
          }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey:[QUERY_KEYS.getTimeTrackingActivities] });
    }
  });

  return (mutation);

};
