import { TranslationFilesRichTextEditorForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formPanelService } from 'services';
import { PanelType } from '@fluentui/react';
import { IHasValue } from 'interfaces';

interface ICallback {
  onSuccess?: (value: string) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      value: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IHasValue<string>>
};

const useTranslationFilesRichTextEditorForm = () => {

  const { handleSubmit, control, reset } = useForm<IHasValue<string>>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (value: string, callback?: ICallback) => {

    reset({ value });

    formPanelService
      .publish({
        content: <TranslationFilesRichTextEditorForm control={control as any} />,
        title: t('HTML Editor'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => new Promise(
              resolve => {
                let newValue = data.value;

                if (newValue.endsWith('\n')) {
                  newValue = newValue.replace('\n', '');
                }

                if (newValue === `<p>${value}</p>`) {
                  newValue = value;
                }

                if (newValue === '<p></p>') {
                  newValue = '';
                }

                callback?.onSuccess?.(newValue);
                resolve(newValue);
              }
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useTranslationFilesRichTextEditorForm;
