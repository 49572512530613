import { CellRendererFrameworkTicketType } from 'components';
import { IDropdownOption } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ETicketType } from 'enums';
import { useMemo } from 'react';

const useDropdownOptionsTicketType = () => {

  const { t } = useTranslation();

  const options = useMemo(
    () => (
      Object
        .keys(ETicketType)
        .filter(l => isNaN(l as any))
        .map(l => ETicketType[l as keyof typeof ETicketType])
        .map(
          l => ({
            key: l,
            data: l,
            text: t(`enum.ticketType.${l}`)
          }) as IDropdownOption<ETicketType>
        )
    ),
    []
  );

  return ({
    options,
    optionRender: OptionRender,
    titleRender: TitleRender
  });

};

const OptionRender = (option?: IDropdownOption<ETicketType>) => (
  option
    ? <CellRendererFrameworkTicketType data={{ type: option.data as any }} />
    : <></>
);

const TitleRender = (options?: IDropdownOption<ETicketType>[]) => (
  options?.[0]
    ? <CellRendererFrameworkTicketType data={{ type: options[0].data as any }} />
    : <></>
);

export default useDropdownOptionsTicketType;
