import {
  CheckboxVisibility,
  CommandBarButton,
  DetailsList,
  SpinnerSize,
  Separator,
  PanelType,
  IColumn,
  Spinner,
  Stack,
  Icon
} from '@fluentui/react';
import { downloadFile, nameof } from 'functions';
import { useTranslation } from 'react-i18next';
import { formPanelService } from 'services';
import { MAX_FILE_SIZE } from 'constant';
import { FileViewer } from 'components';
import { IBaseFile } from 'interfaces';
import { memo, useState } from 'react';
import { EIconName } from 'enums';
import { TNullable } from 'types';

interface IProps {
  onFileViewer?: (file: IBaseFile) => Promise<TNullable<Blob>>;
  onRemove?: (file: IBaseFile) => Promise<void>;
  onAdd?: (file: IBaseFile) => Promise<void>;
  allowFileTobeViewed?: boolean;
  isReadOnly?: boolean;
  classNames?: string;
  files: IBaseFile[];
}

interface IState {
  fileOnLoadingToDownload?: TNullable<IBaseFile>;
  fileOnDeleting?: TNullable<IBaseFile>;
  fileOnAdding?: TNullable<IBaseFile>;
}

const VARIABLES = {
  initState: {
    fileOnDeleting: null,
    fileOnAdding: null
  } as IState
};

const FilesDataTableHandler = ({ onAdd: onAddFile, onRemove: onRemoveFile, onFileViewer, files, isReadOnly = false, allowFileTobeViewed = false, classNames = '' }: IProps) => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  const columns = [
    isReadOnly
      ? null
      : {
        key: 'action',
        minWidth: 15,
        maxWidth: 15,
        onRender: (file: IBaseFile) => (
          state.fileOnDeleting?.name === file.name
            ? <Spinner />
            : <Icon
              className='mt-1 cursor-pointer'
              iconName={EIconName.Cancel}
              style={{ fontSize: '15px' }}
              onClick={
                () => {

                  if (onRemoveFile) {
                    setState(l => ({ ...l, fileOnDeleting: file }));
                    onRemoveFile(file)
                      .finally(() => setState(l => ({ ...l, fileOnDeleting: null })));
                  }

                }
              } />
        )
      },
    allowFileTobeViewed === false
      ? null
      : {
        key: 'view',
        minWidth: 40,
        maxWidth: 40,
        onRender: (file: IBaseFile) => (
          <Stack horizontal className='mt-1' verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Icon
              className='ms-fontSize-16 cursor-pointer'
              style={{ fontSize: '15px' }}
              title={t('common.see')}
              iconName={EIconName.View}
              onClick={() => handleOnClickIconViewDocument(file)} />
            {
              state.fileOnLoadingToDownload?.name === file.name
                ? <Spinner size={SpinnerSize.small} />
                : <Icon
                  className='ms-fontSize-16 cursor-pointer'
                  style={{ fontSize: '15px' }}
                  title={t('common.download')}
                  iconName={EIconName.Download}
                  onClick={() => handleOnClickIconDownloadDocument(file)} />
            }
          </Stack>
        )
      },
    {
      key: nameof<File>('name'),
      name: 'Name',
      fieldName: nameof<File>('name'),
      data: 'string'
    },
    {
      key: nameof<File>('size'),
      name: 'File size',
      fieldName: nameof<File>('size'),
      minWidth: 70,
      maxWidth: 90,
      data: 'number',
      onRender: (item: IBaseFile) => <span>{Math.round(item.size / 1024)} Kb</span>
    }
  ] as IColumn[];

  const handleOnClickIconViewDocument = (file: IBaseFile) => {

    if (onFileViewer) {
      formPanelService
        .publish({
          content: <FileViewer defaultSelected={file} files={files} getFile={onFileViewer} />,
          cancelActionText: t('common.close'),
          showConfirmButton: false,
          type: PanelType.large,
          title: file.name
        });
    }

  };

  const handleOnClickIconDownloadDocument = (file: IBaseFile) => {

    if (onFileViewer) {
      setState(l => ({ ...l, fileOnLoadingToDownload: file }));
      onFileViewer(file)
        .then(blob => downloadFile(blob as Blob, file.name))
        .finally(() => setState(l => ({ ...l, fileOnLoadingToDownload: null })));
    }

  };

  const handleOnFileAdded = (event?: any) => {
    const file: IBaseFile = event.target.files[0];

    if (!file) {
      event.target.value = null;
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      // formPanelService.publishError(t('common.errorImageSize').replace('{0}', `${VARIABLES.maxSize / 1024}`));
      event.target.value = null;
      return;
    }

    if (files.some(l => l.name === file.name)) {
      return;
    }

    if (onAddFile) {
      setState(l => ({ ...l, fileOnAdding: file }));
      onAddFile(file)
        .finally(() => setState(l => ({ ...l, fileOnAdding: null })));
    }
  };

  return (
    <Stack horizontal={false} className={classNames}>
      {
        isReadOnly === false &&
        <>
          <Stack horizontal horizontalAlign='start'>
            <CommandBarButton
              disabled={state.fileOnAdding !== null || state.fileOnDeleting !== null}
              text={t('common.addAttachment')}
              style={{ backgroundColor: 'transparent' }}
              iconProps={{ iconName: EIconName.Add }}
              onClick={
                event => {
                  event?.persist();
                  const htmlInputElement = document.createElement('input');
                  htmlInputElement.style.visibility = 'hidden';
                  htmlInputElement.setAttribute('type', 'file');

                  htmlInputElement.onchange = handleOnFileAdded;

                  document.body.appendChild(htmlInputElement);

                  htmlInputElement.click();

                  setTimeout(
                    () => htmlInputElement.remove(),
                    10000
                  );
                }
              }
            />
          </Stack>
          <Separator />
        </>
      }
      <DetailsList
        compact
        isHeaderVisible={false}
        onItemInvoked={file => allowFileTobeViewed && file && handleOnClickIconViewDocument(file)}
        checkboxVisibility={CheckboxVisibility.hidden}
        columns={columns.filter(l => l !== null)}
        items={files} />
    </Stack>
  );

};

export default memo(FilesDataTableHandler);
