import { EIconName, EProjectRole, EUserRole } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { INavLink } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constant';
import { appStore } from 'store';

const VARIABLES = {
  requestId: 'useModuleProjects.getTranslationEmails',
  route: ROUTES.SETTINGS,
  defaultNavLink: ({
    url: '',
    iconProps: ({
      className: 'mx-2',
      style: {
        fontSize: 'large'
      }
    })
  }) as INavLink
};

const useModuleProjects = () => {

  const _hasRolesAdminUserConnected = useSelector(appStore.userConnected.get.asRoles(EUserRole.Admin, EUserRole.Tester));
  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const _projectSelected = useSelector(appStore.common.get.projectSelected);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuLinks = _projectSelected?.id
    ? [
      // Overview
      {
        ...VARIABLES.defaultNavLink,
        name: ROUTES.PROJECTS.PROJECT.OVERVIEW.description(t),
        key: ROUTES.PROJECTS.PROJECT.OVERVIEW.id,
        onClick: () => navigate(ROUTES.PROJECTS.PROJECT.OVERVIEW.pathValue?.(_projectSelected.id) as string),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.AnalyticsView
        }),
        routeIds: [
          ROUTES.PROJECTS.PROJECT.OVERVIEW.id
        ]
      },
      // Translations
      {
        ...VARIABLES.defaultNavLink,
        name: ROUTES.PROJECTS.PROJECT.TRANSLATIONS.description(t),
        key: ROUTES.PROJECTS.PROJECT.TRANSLATIONS.id,
        onClick: () => navigate(ROUTES.PROJECTS.PROJECT.TRANSLATIONS.pathValue?.(_projectSelected.id) as string),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.Translate
        }),
        routeIds: [
          ROUTES.PROJECTS.PROJECT.TRANSLATIONS.id
        ]
      } as INavLink,
      // Tickets
      {
        ...VARIABLES.defaultNavLink,
        name: ROUTES.PROJECTS.PROJECT.TICKETS.description(t),
        key: ROUTES.PROJECTS.PROJECT.TICKETS.id,
        onClick: () => navigate(ROUTES.PROJECTS.PROJECT.TICKETS.pathValue?.(_projectSelected.id) as string),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.Ticket
        }),
        routeIds: [
          ROUTES.PROJECTS.PROJECT.TICKETS.id,
          ROUTES.PROJECTS.PROJECT.TICKET.TICKET_PARAM.id
        ]
      },
      // ReleaseNotes
      (
        (
          _hasRolesAdminUserConnected ||
          _projectSelected
            ?.members
            .some(
              l => l.userId === _userConnected.id &&
                (
                  l.role === EProjectRole.Administrator ||
                  l.role === EProjectRole.Developer
                )
            )
        ) && {
          ...VARIABLES.defaultNavLink,
          name: ROUTES.PROJECTS.PROJECT.RELEASE_NOTES.description(t),
          key: ROUTES.PROJECTS.PROJECT.RELEASE_NOTES.id,
          onClick: () => navigate(ROUTES.PROJECTS.PROJECT.RELEASE_NOTES.pathValue?.(_projectSelected.id) as string),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.ReleaseDefinition
          }),
          routeIds: [
            ROUTES.PROJECTS.PROJECT.RELEASE_NOTES.id
          ]
        }
      ),
      // Contracts
      {
        ...VARIABLES.defaultNavLink,
        name: ROUTES.PROJECTS.PROJECT.CONTRACTS.description(t),
        key: ROUTES.PROJECTS.PROJECT.CONTRACTS.id,
        onClick: () => navigate(ROUTES.PROJECTS.PROJECT.CONTRACTS.pathValue?.(_projectSelected.id) as string),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.DocumentSet
        }),
        routeIds: [
          ROUTES.PROJECTS.PROJECT.CONTRACTS.id
        ]
      },
      // Settings
      (
        _hasRolesAdminUserConnected &&
        {
          ...VARIABLES.defaultNavLink,
          name: ROUTES.PROJECTS.PROJECT.SETTINGS.description(t),
          key: ROUTES.PROJECTS.PROJECT.SETTINGS.id,
          onClick: () => navigate(ROUTES.PROJECTS.PROJECT.SETTINGS.pathValue?.(_projectSelected.id) as string),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.Settings
          }),
          routeIds: [
            ROUTES.PROJECTS.PROJECT.SETTINGS.id
          ]
        }
      )
    ]
      .filter(l => l) as INavLink[]
    : [];

  return ({
    menuLinks
  });

};

export default useModuleProjects;
