
import { ComboBox, IComboBox, IComboBoxOption, Stack } from '@fluentui/react';
import { CustomCellEditorProps } from 'ag-grid-react';
import { FormEvent } from 'react';

export const CellComboBoxListEditor = ({ value, onValueChange, options }: CustomCellEditorProps & {options: IComboBoxOption[];}) => {

  const handleOnSelectionChange = (_: FormEvent<IComboBox>, option: IComboBoxOption | undefined) => {
    onValueChange(option?.key);
  }

    return (
      <Stack className='my-1'>
        <ComboBox
          allowFreeform
          autoComplete='on'
          className='w-100'
          options={options}
          selectedKey={value}
          onChange={handleOnSelectionChange} />
      </Stack>
    );
  }
