import { ICurrentUser, ICurrentUserParameter, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService, sessionService } from 'services';
import { EHttpRequestName, ELang } from 'enums';

const baseUrl = '/users';

const current = {

  changeLang: (lang?: ELang, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/current/lang`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UpdateUserLang
        },
        {
          method: 'PUT',
          params: {
            lang: lang ?? sessionService.currentLang.get()
          }
        }
      )
      .then(response => response.data),

  sync: (params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<string>(
        `${baseUrl}/current`,
        {
          ...params,
          httpRequestName: EHttpRequestName.SyncCurrentUser
        },
        {
          method: 'PUT',
          params: {
            lang: sessionService.currentLang.get()
          }
        }
      )
      .then(response => response.data),

  get: (params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<ICurrentUser>(
        `${baseUrl}/current`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetCurrentUser
        }
      )
      .then(response => response.data)
      .then(
        user => ({
          ...user,
          photo: user.photo?.toBase64()
        })
      ),

  parameters: {

    get: (params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<ICurrentUserParameter>(
          `${baseUrl}/current/parameters`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetCurrentUserParameters
          }
        )
        .then(response => response.data),

    updateBbcv: (data: { color: string; }, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(
          `${baseUrl}/current/parameters/bbcv`,
          {
            ...params,
            httpRequestName: EHttpRequestName.SetCurrentUserParameters
          },
          {
            method: 'PUT',
            data
          }
        )
        .then(response => response.data),

    updateTimeTracking: (data: { color: string; }, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(
          `${baseUrl}/current/parameters/time-tracking`,
          {
            ...params,
            httpRequestName: EHttpRequestName.SetCurrentUserParameters
          },
          {
            method: 'PUT',
            data
          }
        )
        .then(response => response.data)

  }

};

export default current;
