import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

type TScope = 'bbcv' | 'time-tracking';

type TDataVariables = {
  color: string;
};

const VARIABLES = {
  queryKeyId: (scope: TScope) => [QUERY_KEYS.updateUserParameter, scope]
};

export const useHttpRequestUpdateUserParameters = (scope: TScope) => {

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    //mutationKey: VARIABLES.queryKeyId(scope),
    mutationFn: ({ color }: TDataVariables) => (
      scope === 'bbcv'
        ? httpRequestService.users.current.parameters.updateBbcv({ color }, { showErrorMessage: false })
        : httpRequestService.users.current.parameters.updateTimeTracking({ color }, { showErrorMessage: false })
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getUserParameters] });
    }
  });

  return (mutation);

};
