import { IClient, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';
import { emptyFunction } from 'functions';

/** Object used to get project and client list of current user from api */
const clients = {

  /** Get list of active clients */
  getClientsListActive: (params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IClient[]>('/clients/active', params)
      .then(response => response.data),

  /** Get list of archived clients */
  getClientsArchived: (params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IClient[]>('/clients/archived', params)
      .then(response => response.data),

  /** create new client */
  createClient: (data: IClient, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<string>('/clients', params, {
        data,
        method: 'POST'
      })
      .then(response => response.data),

  /** update client */
  updateClient: (data: IClient, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<string>(`/clients/${data.id}`, params, {
        data,
        method: 'PUT'
      })
      .then(emptyFunction),

  /** remove client */
  removeClient: (id: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(`/clients/${id}`, params, {
        method: 'DELETE'
      })
      .then(emptyFunction),

  /** remove clients */
  removeClients: (idList: string[], params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>('/clients?ids=' + idList.join('&ids='), params, {
        method: 'DELETE'
      })
      .then(emptyFunction),

  /** restore client */
  restoreClient: (id: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(`/clients/restore/${id}`, params, {
        method: 'PUT'
      })
      .then(emptyFunction),

  /** restore clients. */
  restoreClients: (idList: string[], params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>('/clients/restore?ids=' + idList.join('&ids='), params, {
        method: 'PUT'
      })
      .then(emptyFunction)

};

export default clients;
