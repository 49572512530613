import { ReactQueryClientProvider } from 'components';
import { Provider } from 'react-redux';
import store from 'store';
import { createRoot } from 'react-dom/client';

import '@fluentui/react/dist/css/fabric.min.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'assets/scss/index.scss';
import 'constant';
import 'config';
import 'extensions';

import App from 'app';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ReactQueryClientProvider>
      <App />
    </ReactQueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
