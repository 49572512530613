import {
  IHttpRequestServiceOptions,
  ISignatureRequest,
  IWorkItemLink,
  IContract,
  IHasId
} from 'interfaces';
import { fileFromServerToFile, IFileFromServer } from 'interfaces/file.interface';
import { IContractCreation } from 'interfaces/contract.interface';
import { convertService } from 'services';
import httpRequestService from '.';

const baseUrl = '/xchange/projects';

const projects = {

  tickets: {

    linkWorkItem: ({ projectId, id }: IHasId & { projectId: string }, data: IWorkItemLink, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(`${baseUrl}/${projectId}/tickets/${id}/workitem`, params, {
          data,
          method: 'PUT'
        })
  },

  contracts: {

    signatureRequests: {

      /** Get all signatureRequests. */
      getAllSignatureRequests: (projectId: string, contractId: string | undefined = undefined, includeClosed = false, params: IHttpRequestServiceOptions = {}) =>
        projects
          .contracts
          .signatureRequests
          .getSignatureRequests(projectId, { contractId, includeClosed }, params),

      /** Get signatureRequest. */
      getSignatureRequest: (projectId: string, id: string, includeClosed = false, params: IHttpRequestServiceOptions = {}) =>
        projects
          .contracts
          .signatureRequests
          .getSignatureRequests(projectId, { ids: [id], includeClosed }, params)
          .then(signatureRequests => signatureRequests[0]),

      /** Get list of signatureRequests. */
      getSignatureRequests: (
        projectId: string,
        query: {
          ids?: string[];
          contractId?: string;
          includeClosed?: boolean;
        } = {},
        params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
          .request<ISignatureRequest[]>(
            `${baseUrl}/${projectId}/signatureRequests?${convertService.toQueryString(query.ids, 'ids')}`,
            params,
            {
              params: {
                idContract: query.contractId,
                includeClosed: query.includeClosed ?? false
              }
            }
          )
          .then(response => response.data),

      sendSignatureRequest: (signatureRequest: ISignatureRequest, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
          .request<ISignatureRequest>(`${baseUrl}/${signatureRequest.projectId}/signatureRequests`, params, {
            method: 'POST',
            data: signatureRequest
          })
          .then(response => response.data),

      cancelSignatureRequest: ({ projectId, id }: IHasId & { projectId: string }, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
          .request<void>(`${baseUrl}/${projectId}/signatureRequests/${id}`, params, {
            method: 'DELETE'
          })
    },

    /** Get all contracts. */
    getAllContracts: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
      projects
        .contracts
        .getContracts(projectId, { all: true }, params),

    /** Get contract. */
    getContract: (projectId: string, id: string, params: IHttpRequestServiceOptions = {}) =>
      projects
        .contracts
        .getContracts(projectId, { ids: [id], all: false }, params)
        .then(contracts => contracts[0]),

    /** Get list of contracts. */
    getContracts: (
      projectId: string,
      query: {
        all?: boolean;
        ids?: string[];
      } = { all: true, ids: [] },
      params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<IContract[]>(
          `${baseUrl}/${projectId}/contracts?${(query?.ids ?? []).some(l => true) ? 'ids=' : ''}${(query?.ids ?? []).join('&ids=')}`,
          params,
          {
            params: {
              all: query.all ?? true
            }
          }
        )
        .then(response => response.data),

    createContracts: (contract: IContractCreation, params: IHttpRequestServiceOptions = {}) => {
      const data = new FormData();
      data.append('file', contract.contractFile as File);
      data.append('name', contract.name);
      data.append('description', contract.description);

      return httpRequestService
        .request<string>(
          `${baseUrl}/${contract.projectId}/contracts`, params,
          {
            method: 'POST',
            data
          }
        )
        .then(response => response.data);
    },

    downloadContract: ({ projectId, id }: IHasId & { projectId: string; }, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<IFileFromServer>(`${baseUrl}/${projectId}/contracts/${id}/file`, params)
        .then(response => (fileFromServerToFile(response.data))),

    updateContractFile: ({ projectId, id }: IHasId & { projectId: string; }, file: File, params: IHttpRequestServiceOptions = {}) => {
      const data = new FormData();
      data.append('file', file);

      return httpRequestService
        .request<IContract>(
          `${baseUrl}/${projectId}/contracts/${id}/file`,
          params,
          {
            method: 'PUT',
            data
          }
        )
        .then(response => response.data);
    }

  }
  
};

export default projects;
