import { Dispatch } from '@reduxjs/toolkit';
import { IHasId } from 'interfaces';
import { appStore } from 'store';

interface IUserData extends IHasId {
  firstName: string;
  lastName: string;
  photo?: string;
}

export interface IUserPersonaStore {
  users: IUserData[];
}

const VARIABLES = {
  key: 'UserPersona',
  initStore: {
    users: []
  } as IUserPersonaStore
};

const set = {
  set: (dispatch: Dispatch, setter: (state: IUserPersonaStore) => IUserPersonaStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  userInfo: (userId: string) => (
    appStore.record.get.get(
      VARIABLES.key,
      (record: IUserPersonaStore) => {
        const _users = record?.users ?? VARIABLES.initStore.users;
        return _users.find(l => l.id === userId);
      }
    )
  )
};

const store = {
  set,
  get
};

export default store;
