import { memo, MemoExoticComponent } from 'react';
import { useSelector } from 'react-redux';
import { appStore } from 'store';

interface IProps {
  unAuthenticated?: JSX.Element | MemoExoticComponent<() => JSX.Element>;
  authenticated?: JSX.Element | MemoExoticComponent<() => JSX.Element>;
  children?: JSX.Element | MemoExoticComponent<() => JSX.Element>;
}

const AuthenticatedComponent = ({ authenticated, children, unAuthenticated = <></> }: IProps) => {

  const _isUserConnected = useSelector(appStore.userConnected.get.isUserConnected);

  return (
    <>
      {
        _isUserConnected
          ? authenticated ?? children
          : unAuthenticated
      }
    </>
  );

};

export default memo(AuthenticatedComponent);
