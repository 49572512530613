import RichTextEditor, { RichTextEditorProps } from 'components/richTextEditor/RichTextEditor';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';

type TProps = IHookFormProps & RichTextEditorProps;

const ControlledRichTextEditor = (props: TProps) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.defaultValue || ''}
    render={
      ({ field: { onChange, onBlur, value } }) => (
        <RichTextEditor
          {...props}
          onHtmlChange={
            html => {
              props.onHtmlChange?.(html);
              onChange({ target: { value: html } });
            }
          }
          defaultHtml={value ?? ''}
          onChange={props.onChange}
          onBlur={
            event => {
              props.onBlur?.(event);
              onBlur();
            }
          }
        />
      )
    }
  />
);

export default ControlledRichTextEditor;
