import { IHttpRequestServiceOptions, INomenclature } from 'interfaces';
import { EHttpRequestName, ENomenclatureType } from 'enums';
import { httpRequestService } from 'services';

const baseUrl = '/nomenclatures';

const nomenclatures = {

  get: <TId, TData = {}>(type: ENomenclatureType, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<INomenclature<TId, TData>[]>(
        `${baseUrl}/${ENomenclatureType[type]}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetNomenclatures
        }
      )
      .then(response => response.data)

};

export default nomenclatures;
