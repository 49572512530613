import { MsalAuthenticationResult } from '@azure/msal-react';
import { EAppInitStatus } from 'enums';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect } from 'react';
import { appStore } from 'store';
import { LoadingScreen } from 'components';
import Layout from 'layout';
import { useTranslation } from 'react-i18next';

const AuthError = ({ error: authError }: MsalAuthenticationResult) => {

  const _isLoading = useSelector(appStore.startup.get.isLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // When component is mounted.
  useEffect(
    () => {
      (
        async () => {
          try {
            // Check if authError detected
            if (authError) {
              // Update store
              appStore.startup.set.setStatus(dispatch, EAppInitStatus.unAuthenticated);
              appStore.startup.set.setData(dispatch, authError.message);
              // Quit function
              return;
            } 
          } catch (error){}
          
          finally {
          // Update store
          appStore.startup.set.setLoading(dispatch, false);
          }
        }
      )();
    },
    // eslint-disable-next-line
    []
  );

  // When statup is working
  if (_isLoading) {
    return <LoadingScreen description={`${t('common.loading')}...`} />;
  }

  return <Layout />;

};

export default memo(AuthError);
