import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EAppInitStatus } from 'enums';
import { appStore } from 'store';
import { ROUTES } from 'constant';

export type Props = {
  component: any;
}

const RoutesGuard = ({component} : Props) => {

  const _status = useSelector(appStore.startup.get.status);

  if (_status === EAppInitStatus.error403) {
    return <Navigate to={ROUTES.ERRORS.FORBIDDEN.path()} replace />;
  }

  if (_status === EAppInitStatus.error500) {
    return <Navigate to={ROUTES.ERRORS.INTERNAL_SERVER_ERROR.path()} replace />;
  }

  if (_status === EAppInitStatus.errorNetwork) {
    return <Navigate to={ROUTES.ERRORS.NETWORK.path()} replace />;
  }

  return component;
  
};

export default RoutesGuard;
