import { IHasId, IHasName } from 'interfaces';
import { httpRequestService } from 'services';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';

export interface ITimeTrackingClient extends IHasId, IHasName {
}

export const useHttpRequestGetTimeTrackingClients = () => {

  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery({
    queryKey: [QUERY_KEYS.getTimeTrackingClients],
    queryFn: () => (
      httpRequestService
        .request<ITimeTrackingClient[]>(`/time-entries/clients`)
        .then(response => response.data)
    )
  });

  const refreshData = () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingClients] });

  return ({
    data: data ?? [],
    refreshData,
    isLoading
  });

};
