import sanitizeHTML, {IOptions} from 'sanitize-html';
import React, {memo} from 'react';

type TProps = {
    html: string;
    className?: string;
    style?: React.CSSProperties;
    id?: string;
} & IOptions;

const SanitizedHTML = ({html, className = '', id = '', style = {}, ...props}: TProps) => {
    // Define allowed tags and attributes for sanitization
    const defaultOptions: IOptions = {
        allowedTags: sanitizeHTML.defaults.allowedTags.concat([
            'img', 'figure', 'figcaption', 'iframe', 'a'  // Add <a> for links
        ]),
        allowedAttributes: {
            ...sanitizeHTML.defaults.allowedAttributes,
            img: ['src', 'alt', 'width', 'height', 'style'],  // Allow style for <img>
            iframe: ['src', 'width', 'height', 'frameborder', 'allowfullscreen'], // Allow iframe attributes
            a: ['href'],  // Allow href for <a>
            figure: ['class'],  // Allow necessary attributes for <figure>
        },
        parseStyleAttributes: false  // Disable style parsing
    };

    // Merge provided options with default options
    const options = {...defaultOptions, ...props};

    // Replace <oembed> tags with <iframe> tags and wrap <img> tags with <a> tags
    let updatedHTML = html?.replace(/<oembed url="(.*?)"><\/oembed>/g, (match, url) => {
        const videoId = url.split('v=')[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return `<iframe src="${embedUrl}" width="100%" height="600" frameborder="0" allowfullscreen></iframe>`;
    })



    // Sanitize the HTML input
    let sanitizedHTML = sanitizeHTML(updatedHTML, options);

    sanitizedHTML = sanitizedHTML?.replace(/<img\s+src="(.*?)"([^>]*)>/g, (match, src, rest) => {
        return `<a href="${src}" rel="noopener noreferrer">${match}</a>`;
    });

    return (
        <div
            dangerouslySetInnerHTML={{__html: sanitizedHTML}}
            className={className}
            style={style}
            id={id}
        />
    );
};

export default memo(SanitizedHTML);
