import PushTicketOnDevopsFormCreate from './PushTicketOnDevopsFormCreate';
import PushTicketOnDevopsFormLink from './PushTicketOnDevopsFormLink';
import { IPushTicketOnDevopsFormData } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useCallback, useEffect } from 'react';
import { ControlledCheckbox } from 'components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { nameof } from 'functions';
import store from './store';
import { IProps } from '.';

const PushTicketOnDevopsForm = ({ control, setValue, watch }: IProps) => {

  const _linkToExistingWorkItem = useSelector(store.get.linkToExistingWorkItem);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      const initFormData = { ...control._formValues } as IPushTicketOnDevopsFormData;
      store.set.set(dispatch, l => initFormData);

      return () => {
        store.set.clear(dispatch);
      };
    },
    []
  );

  const handleOnChangeCheckbox = useCallback(
    (_: any, checked = false) => {
      const linkToExistingWorkItem = checked;
      store.set.set(dispatch, l => ({ ...l, linkToExistingWorkItem }));

      if (linkToExistingWorkItem) {
        store.set.set(dispatch, l => ({ ...l, workItem: null, workItemId: null }));
        setValue('workItemId', null);
        setValue('workItem', null);
      }
    },
    []
  );

  return (
    <Stack className='mt-2' verticalFill>
      <ControlledCheckbox
        control={control}
        className='mb-2'
        onChange={handleOnChangeCheckbox}
        label={t('common.linkToExistingWorkItem')}
        name={nameof<IPushTicketOnDevopsFormData>('linkToExistingWorkItem')} />
      {
        _linkToExistingWorkItem
          ? <PushTicketOnDevopsFormLink control={control} setValue={setValue} watch={watch} />
          : <PushTicketOnDevopsFormCreate control={control} setValue={setValue} watch={watch} />
      }
    </Stack>
  );

};

export default memo(PushTicketOnDevopsForm);
