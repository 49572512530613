import { IPersonaProps, IRenderFunction, Persona, PersonaPresence, PersonaSize } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { emptyFunction } from 'functions';
import { memo, useEffect } from 'react';
import { IHasId } from 'interfaces';
import store from './store';

interface IProps extends IHasId {
  size?: PersonaSize;
  hidePersonaDetails?: boolean;
  className?: string;
  initialsColor?: string;
  presence?: PersonaPresence;
  secondaryText?: string;
  tertiaryText?: string;
  showSecondaryText?: boolean;
  onRenderPrimaryText?: IRenderFunction<IPersonaProps>;
  onRenderSecondaryText?: IRenderFunction<IPersonaProps>;
  onRenderTertiaryText?: IRenderFunction<IPersonaProps>;
  onRenderOptionalText?: IRenderFunction<IPersonaProps>;
}

const UserPersona = ({ id, size, className, presence = PersonaPresence.none, initialsColor = '#1d6257', hidePersonaDetails = true, ...props }: IProps) => {

  const requestId = `UserPersona.${id}`;

  const _userInfo = useSelector(store.get.userInfo(id));
  const { isLoading } = useHttpRequestStatus(requestId);
  const dispatch = useDispatch();

  size = size ?? PersonaSize.size40;

  useEffect(
    () => {

      if (!_userInfo?.id && id) {
        const cancelTokenSource = httpRequestService.getCancelTokenSource();

        httpRequestService
          .users
          .get({ ids: [id] }, { cancelToken: cancelTokenSource.token, requestId })
          .then(users => users[0])
          .then(
            userInfo => {
              store
                .set
                .set(
                  dispatch,
                  l => ({
                    ...l,
                    users: [
                      ...l?.users ?? [],
                      ({
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        photo: userInfo.photo,
                        id: userInfo.id
                      })
                    ]
                  })
                );
            }
          );

        return () => {
          cancelTokenSource.cancel();
        };
      }

      return emptyFunction();

    },
    [id, _userInfo]
  );

  if (isLoading || !_userInfo) {
    return <Persona showUnknownPersonaCoin hidePersonaDetails={hidePersonaDetails} size={size} />;
  }

  const fullName = `${_userInfo.firstName} ${_userInfo.lastName}`.trim();

  return (
    <Persona
      imageUrl={_userInfo.photo}
      imageInitials={_userInfo.firstName.charAt(0).toUpperCase().concat(_userInfo.lastName.charAt(0).toUpperCase())}
      onRenderSecondaryText={props.onRenderSecondaryText}
      onRenderTertiaryText={props.onRenderTertiaryText}
      onRenderOptionalText={props.onRenderOptionalText}
      onRenderPrimaryText={props.onRenderPrimaryText}
      showSecondaryText={props.showSecondaryText}
      hidePersonaDetails={hidePersonaDetails}
      secondaryText={props.secondaryText}
      tertiaryText={props.tertiaryText}
      initialsColor={initialsColor}
      className={className}
      imageAlt={fullName}
      presence={presence}
      title={fullName}
      text={fullName}
      size={size} />
  );

};

export default memo(UserPersona);
