import {
  IPickerItemProps,
  PersonaSize,
  TagPicker,
  Persona,
  Label,
  Stack,
  ITag,
  Icon
} from '@fluentui/react';
import { useHttpRequestStatus, useNomenclatureData, useUserConnected } from 'hooks';
import { EAccessMode, EIconName, ENomenclatureType } from 'enums';
import { Control, UseFormSetValue } from 'react-hook-form';
import { emptyFunction, getFullName } from 'functions';
import { IHasData, IHasName, IUser } from 'interfaces';
import { memo, useEffect, useState } from 'react';
import { ControlledDropdown } from 'components';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';

interface IProps {
  control: Control<any>;
  userIdsExcluded: string[];
  setValue: UseFormSetValue<{ role: EAccessMode; userIds: string[]; }>;
}

interface IState {
  userIdsSharedWith: string[];
}

const VARIABLES = {
  requestId: 'ConsultantUsersSharedWithForm',
  initState: {
    userIdsSharedWith: []
  } as IState
};

const ConsultantUsersSharedWithForm = ({ control, userIdsExcluded, setValue }: IProps) => {

  const { isLoading: isLoadingAccessMode, data: dataAccessModes } = useNomenclatureData<number>(ENomenclatureType.AccessMode);
  const { data: dataUsers } = useHttpRequestStatus<IUser[]>(
    VARIABLES.requestId, { data: [] },
    {
      data: data => (
        (data ?? [])
          .map(
            l => ({
              ...l, photo: l.photo?.toBase64()
            })
          )
      )
    }
  );
  const [state, setState] = useState<IState>(VARIABLES.initState);
  const _userConnected = useUserConnected();
  const { t } = useTranslation();

  useEffect(
    () => {
      const cancelTokenSource = httpRequestService.getCancelTokenSource();

      httpRequestService
        .users
        .get(
          { idsExcluded: [...userIdsExcluded, _userConnected.id] },
          { cancelToken: cancelTokenSource.token, requestId: VARIABLES.requestId }
        )
        .finally(emptyFunction);

      return () => cancelTokenSource.cancel();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => setValue('userIds', state.userIdsSharedWith),
    [state.userIdsSharedWith]
  );

  const handleOnResolveSuggestions = async (filter: string, selectedItems?: ITag[]) => {
    filter = filter?.toLowerCase().trim();

    if (filter) {
      return (
        dataUsers
          .filter(l => !state.userIdsSharedWith.some(ll => ll === l.id))
          .filter(
            l => (
              l.firstName.toLowerCase().includes(filter) ||
              l.lastName.toLowerCase().includes(filter) ||
              l.email.toLowerCase().includes(filter)
            )
          )
          .map(l => ({ key: l.id, name: getFullName(l), data: l } as ITag))
      );
    }

    return [];
  };

  const handleOnRenderSuggestionsItem = ({ data, name }: IHasData<IUser> & IHasName) => (
    <Persona
      size={PersonaSize.size24}
      imageUrl={data.photo}
      imageAlt={name}
      className='m-1'
      key={data.id}
      text={name} />
  );

  const handleOnRenderItem = ({ item }: IPickerItemProps<ITag>) => (
    <Stack key={item.key} className='px-1'>
      <Stack
        horizontal
        verticalAlign='center'
        className='ms-bgColor-gray40 pe-2'
        style={{ borderRadius: '10px' }}
        tokens={{ childrenGap: 4 }}>
        <Persona
          imageUrl={(item as any as IHasData<IUser>)?.data.photo}
          size={PersonaSize.size24}
          imageAlt={item.name}
          text={item.name} />
        <Icon
          onClick={() => setState(l => ({ ...l, userIdsSharedWith: [...l.userIdsSharedWith.filter(ll => ll !== item.key)] }))}
          iconName={EIconName.chromeClose}
          style={{ fontSize: 10 }}
          title={t('Remove')}
          role='button' />
      </Stack>
    </Stack>
  );

  const handleOnItemSelected = (item?: ITag) => {
    item?.key &&
      !state.userIdsSharedWith.some(l => l === item.key) &&
      setState(l => ({ ...l, userIdsSharedWith: [...l.userIdsSharedWith, item.key as string] }));

    return item ?? null;
  };

  const selectedItems = (
    dataUsers
      .filter(l => state.userIdsSharedWith.some(ll => ll === l.id))
      .map(l => ({ key: l.id, name: getFullName(l), data: l } as ITag))
  );

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <Stack horizontal={false}>
        <Label>
          {t('common.users')} ({state.userIdsSharedWith.length})
        </Label>
        <TagPicker
          selectedItems={selectedItems}
          onRenderItem={handleOnRenderItem}
          onItemSelected={handleOnItemSelected}
          onResolveSuggestions={handleOnResolveSuggestions}
          onRenderSuggestionsItem={props => handleOnRenderSuggestionsItem(props as any)} />
      </Stack>
      <ControlledDropdown
        required
        control={control}
        disabled={isLoadingAccessMode}
        options={dataAccessModes.map(l => ({ key: l.id, text: l.title }))}
        label={t('common.accessMode')}
        rules={{ required: t('common.formError.required').toString() }}
        name={'role'} />
    </Stack>
  );

};

export default memo(ConsultantUsersSharedWithForm);
