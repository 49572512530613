import CreateTranslationFolderFileForm from './CreateTranslationFolderFileForm';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ITranslationFolder, IProject } from 'interfaces';
import { Stack } from '@fluentui/react';
import { useStore } from 'hooks';
import { memo } from 'react';
import store from './store';

interface IProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  projectSelected: IProject;
  watch: UseFormWatch<ITranslationFolder>;
}

const CreateTranslationFolderForm = ({ control, setValue, projectSelected, watch }: IProps) => {

  useStore(store);

  return (
    <Stack className='mt-2' verticalFill tokens={{ childrenGap: 10 }}>
      <CreateTranslationFolderFileForm
        projectSelected={projectSelected}
        setValue={setValue}
        control={control}
        watch={watch} />
    </Stack>
  );

};

export default memo(CreateTranslationFolderForm);
