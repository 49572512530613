import { ISessionObject } from 'interfaces';
import { ESessionKeys, ELang } from 'enums';

/** Current language of application. */
const currentLang: ISessionObject<ELang> = {
  /** Get value. */
  get: (): ELang => {
    // Get value.
    const stringValue = localStorage.getItem(ESessionKeys.lang);

    // Set default value if not found
    if (!stringValue) {
      // Set in session.
      currentLang.set(ELang.en);
      // Return value
      return currentLang.get();
    }

    return stringValue as ELang;
  },
  /**
   * Set value in session.
   * @param value Value applied.
   */
  set: (value: ELang) => {
    // Save in session
    localStorage.setItem(ESessionKeys.lang, value);
  }
};

/** Object used to wrapp access of object stored in session. */
const sessionService = {
  currentLang
};

export default sessionService;
