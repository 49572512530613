import { Separator, Stack, Label, ActionButton, IconButton } from '@fluentui/react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { ControlledTextField } from 'components';
import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { nameof } from 'functions';
import { EIconName } from 'enums';
import { nanoid } from 'nanoid';

interface IState {
  skillNames: string[];
}

interface IFormData {
  parentName: string;
  skillNames: string[];
}

interface IProps {
  setValue: UseFormSetValue<IFormData>;
  control: Control<any>;
};

const VARIABLES = {
  initState: {
    skillNames: []
  } as IState
};

const SkillManagementComponentAddSkillsForm = ({ control, setValue }: IProps) => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  const handleOnClickButtonAdd = () => {
    const skillNames = [...state.skillNames, ''];
    setState(l => ({ ...l, skillNames }));
  };

  const handleOnClickButtonRemove = (index: number) => {
    let skillNames = [...state.skillNames];
    delete skillNames[index];
    skillNames = skillNames.filter(l => l || l === '');

    setState(l => ({ ...l, skillNames }));
    setValue('skillNames', skillNames);
  };

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledTextField
        control={control}
        label={t('common.parent')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IFormData>('parentName')} />
      <Stack tokens={{ childrenGap: -10 }}>
        <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
          <Label>
            {t('common.skills')}
          </Label>
          <ActionButton
            onClick={handleOnClickButtonAdd}
            iconProps={{ iconName: EIconName.Add }}>
            {t('common.add')}
          </ActionButton>
        </Stack>
        <Separator />
      </Stack>
      <Stack horizontal={false} tokens={{ childrenGap: 5 }}>
        {
          state
            .skillNames
            .map(
              (l, index) => (
                <ControlledTextField
                  underlined
                  styles={{
                    suffix: {
                      backgroundColor: 'transparent'
                    }
                  }}
                  onRenderSuffix={
                    l => (
                      <IconButton
                        onClick={() => handleOnClickButtonRemove(index)}
                        iconProps={{
                          iconName: EIconName.Cancel,
                          className: 'ms-fontSize-14'
                        }} />
                    )
                  }
                  key={nanoid()}
                  control={control}
                  label={`N° ${index}`}
                  rules={{ required: t('common.formError.required').toString() }}
                  name={`skillNames.[${index}]`} />
              )
            )
        }
      </Stack>
    </Stack>
  );

};

export default memo(SkillManagementComponentAddSkillsForm);
