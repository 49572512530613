import { ITextFieldProps, TextField } from '@fluentui/react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';

type TProps = IHookFormProps &
  ITextFieldProps & {
    showRemainingCharacters?: boolean;
  };

const ControlledTextField = (props: TProps) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.defaultValue || ''}
    render={
      ({ field: { name: fieldName, onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          {...props}
          onChange={
            (event, newValue) => {
              props.onChange?.(event, newValue);
              onChange(event, newValue);
            }
          }
          value={value ?? ''}
          onBlur={
            (event) => {
              props.onBlur?.(event);
              onBlur();
            }
          }
          name={fieldName}
          errorMessage={error?.message}
          multiline={props.multiline}
          description={
            (props.showRemainingCharacters ?? false) && props.maxLength
              ? `${props.maxLength - value.length} characters left`
              : ''
          }
        />
      )
    }
  />
);

export default ControlledTextField;
