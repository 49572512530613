import {httpRequestService, sessionService} from 'services';
import {ENVIRONMENT_VARIABLES} from 'constant';
import {IHttpRequestError} from 'interfaces';
import {EHttpResponseStatus} from 'enums';
import axios from 'axios';

/** Global variables */
const VARIABLES = {
    throwCancelledError: () =>
        Promise.reject(
            {
                Status: EHttpResponseStatus.Cancelled,
                Title: 'Cancelled',
                Extensions: {},
                Detail: '',
                Type: ''
            } as IHttpRequestError
        ),
    throwForbiddenError: () =>
        Promise.reject(
            {
                Status: EHttpResponseStatus.Forbidden,
                Title: 'Access denied',
                Extensions: {},
                Detail: '',
                Type: ''
            } as IHttpRequestError
        ),
    throwNotFoundError: () =>
        Promise.reject(
            {
                Status: EHttpResponseStatus.NotFound,
                Title: 'Not found',
                Extensions: {},
                Detail: '',
                Type: ''
            } as IHttpRequestError
        ),
    throwNotAllowedError: () =>
        Promise.reject(
            {
                Status: EHttpResponseStatus.NotAllowed,
                Title: 'Method not allowed',
                Extensions: {},
                Detail: '',
                Type: ''
            } as IHttpRequestError
        ),
    throwRequestError: (error: any) =>
        Promise.reject({
            Status: EHttpResponseStatus.Request,
            Detail: error.message,
            Title: error.message,
            Extensions: {},
            Type: ''
        } as IHttpRequestError),
    throwNetworkError: () =>
        Promise.reject({
            Status: EHttpResponseStatus.Network,
            Extensions: {},
            Detail: '',
            Title: '',
            Type: ''
        } as IHttpRequestError)
};

/** Instance axios. */
const axiosInstance = axios.create({baseURL: ENVIRONMENT_VARIABLES.baseUrlServer});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async config => {
        // Get access token
        const accessToken = await httpRequestService.azureAD.getAccessToken();
        // Get current lang of app
        const lang = sessionService.currentLang.get();
        // Create header object
        config.headers.set({
            ...config.headers,
            'Accept-Language': lang,
            'Authorization': config.headers?.['Authorization']
                ? config.headers['Authorization']
                : accessToken
                    ? `Bearer ${accessToken}`
                    : ''
        });
        const isFormData = config.data instanceof FormData;
        if (!isFormData) {
            config.headers['Content-Type'] = 'application/json';
        }
        
        return config;
    },
    error => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    error => {

        // Check if request has been canceled
        if (axios.isCancel(error)) {
            return VARIABLES.throwCancelledError();
        }

        // The request was made and the server responded with a status code
        if (error.response) {
            // Check status of response
            if (error.response.status === EHttpResponseStatus.NotFound) {
                return VARIABLES.throwNotFoundError();
            } else if (error.response.status === EHttpResponseStatus.Forbidden) {
                return VARIABLES.throwForbiddenError();
            } else if (error.response.status === EHttpResponseStatus.NotAllowed) {
                return VARIABLES.throwNotAllowedError();
            } else {
                return Promise.reject(error.response.data as IHttpRequestError);
            }

        }

        // The request was made but no response was received
        if (error.request) {
            return VARIABLES.throwNetworkError();
        }

        // Something happened while setting up the request that triggered an Error
        return VARIABLES.throwRequestError(error);

    }
);

export default axiosInstance;
