import { ENVIRONMENT_VARIABLES } from 'constant';
import { Stack, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { appStore } from 'store';
import { memo } from 'react';

/** Component used to display footer of application. */
const Footer = () => {
  
  /** Is logging in value from reducer. */
  const _version = useSelector(appStore.common.get.version);

  // Get version of back end
  const elementApiVersion = () => (
    _version
      ? <span>/ api v.{`${_version.major}.${_version.minor}.${_version.build}.${_version.revision}`}</span>
      : <></>
  );

  return (
    <Stack className='py-4' horizontal horizontalAlign='center' >
      <Text variant='smallPlus'>
        © Technology Partner 2022 • <b>{ENVIRONMENT_VARIABLES.appName} v.{ENVIRONMENT_VARIABLES.version} {elementApiVersion()}</b>
      </Text>
    </Stack>
  );

};

export default memo(Footer);
