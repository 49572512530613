import { IHttpRequestServiceOptions, ITicketEvent } from 'interfaces';
import { convertService, httpRequestService } from 'services';
import { EEventType, EHttpRequestName } from 'enums';
import dayjs from 'config/dayjs';

const baseUrl = '/tickets';

const events = {

  get: (
    ticketId: string,
    query: {
      excludedEvents?: EEventType[];
      includedEvents?: EEventType[];
    },
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<ITicketEvent[]>(
        [
          `${baseUrl}/${ticketId}/events?`,
          convertService.toQueryString(query?.excludedEvents, 'excludedEvents'),
          convertService.toQueryString(query?.includedEvents, 'includedEvents')
        ]
          .filter(l => l)
          .join('&'),
        {
          ...params,
          httpRequestName: EHttpRequestName.GetEventsOnTicket
        },
        {
          params
        }
      )
      .then(response => response.data)
      .then(data => data.map(l => ({ ...l, createdOn: dayjs(l.createdOn, { utc: true }).toDate() })))

};

export default events;
