import { IDataTableCellChange, IHttpRequestServiceOptions, ITranslation, ITranslationFileContent, ITranslationFileLine, ITranslationFolder } from 'interfaces';
import { groupByProperty, nameof } from 'functions';
import { httpRequestService } from 'services';
import emailTemplate from './emailTemplate';
import { EHttpRequestName } from 'enums';
import { TNullable } from 'types';

interface IUpdateContentData {
  translationKey: string;
  translations: Record<string, string>;
  comment: TNullable<string>;
}

const baseUrl = '/projects';

const translations = {

  emailTemplate,

  get: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<ITranslation[]>(
        `${baseUrl}/${projectId}/translations`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjectTranslations
        }
      )
      .then(response => response?.data ?? []),

  files: {

    getContent: (projectId: string, queryParams: { fileId: string; translationFolderName: string; }, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<ITranslationFileContent>(
          `${baseUrl}/${projectId}/translations/files`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetProjectTranslationFile
          },
          { params: queryParams }
        )
        .then(response => response.data),

    updateContent: (projectId: string, queryParams: { fileId: string; translationFolderName: string; }, changes: IDataTableCellChange[], params: IHttpRequestServiceOptions = {}) => {
      const changesGroupedByTranslationKey = groupByProperty(changes, l => l.rowId);
      const data = [] as IUpdateContentData[];
      Object
        .keys(changesGroupedByTranslationKey)
        .forEach(
          translationKey => {
            const newData = { translationKey, translations: {}, comment: null } as IUpdateContentData;
            changesGroupedByTranslationKey[translationKey]
              .forEach(
                change => {
                  if (change.colId === nameof<ITranslationFileLine>('comment')) {
                    newData.comment = change.newValue;
                  } else {
                    newData.translations[change.colId] = change.newValue;
                  }
                }
              );
            data.push(newData);
          }
        );

      return (
        httpRequestService
          .request<void>(
            `${baseUrl}/${projectId}/translations/files`,
            {
              ...params,
              httpRequestName: EHttpRequestName.UpdateProjectTranslationFile
            },
            {
              data,
              params: queryParams,
              method: 'PUT'
            }
          )
          .then(response => response.data)
      );
    }
  },

  folders: {

    create: (projectId: string, data: ITranslationFolder, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<string>(
          `${baseUrl}/${projectId}/translations/folders`,
          {
            ...params,
            httpRequestName: EHttpRequestName.CreateTranslationFolderOnProject
          },
          {
            data,
            method: 'POST'
          }
        )
        .then(response => response.data),


    delete: (projectId: string, translationFolderName: string, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(
          `${baseUrl}/${projectId}/translations/folders/${translationFolderName}`,
          {
            ...params,
            httpRequestName: EHttpRequestName.CreateTranslationFolderOnProject
          },
          { method: 'DELETE' }
        )

  }

};

export default translations;
