export enum ENomenclatureType {
  ProjectRole = 0,
  TicketState = 1,
  TicketType = 2,
  TicketCriticality = 3,
  UserRole = 4,
  Skill = 5,
  WorkingLocationType = 6,
  EmploymentType = 7,
  Language = 8,
  Gender = 9,
  TranslationContainerType = 10,
  Country = 11,
  LanguageProficiency = 12,
  AccessMode = 13
}
