import { useDispatch, useSelector } from 'react-redux';
import { Icon, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { INotification } from 'interfaces';
import { EIconName } from 'enums';
import { appStore } from 'store';
import { memo } from 'react';

interface IProps {
  notification: INotification;
}

const NotificationsFormItemHeader = ({ notification }: IProps) => {

  const _idUserConnected = useSelector(appStore.userConnected.get.id);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOnClickIconDeleteNotification = () => {
    httpRequestService
      .users
      .notifications
      .delete(_idUserConnected, { ids: [notification.id] })
      .then(
        () => {
          appStore
            .userConnected
            .set
            .set(
              dispatch,
              l => ({
                ...l,
                notifications: [
                  ...l.notifications.filter(ll => ll.id !== notification.id)
                ]
              })
            );
        }
      );
  };

  const classNames = notification.read
    ? 'ms-fontWeight-semibold'
    : 'ms-fontWeight-bold text-green';

  return (
    <Stack horizontal horizontalAlign='space-between'>
      <Text className={classNames}>
        {notification.project?.name}
      </Text>
      <Icon
        role='button'
        onClick={handleOnClickIconDeleteNotification}
        className='deleteOneNotificationButton ms-fontSize-12'
        title={t('Delete')}
        iconName={EIconName.clear} />
    </Stack>
  );
};

export default memo(NotificationsFormItemHeader);
