import { useCreateUpdateConsultantExperienceFormDataSkillsFiltered } from './useCreateUpdateConsultantExperienceFormDataSkillsFiltered';
import CreateUpdateConsultantExperienceFormSkillsOptionItem from './CreateUpdateConsultantExperienceFormSkillsOptionItem';
import { Text, Separator, Stack, Callout, DirectionalHint } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISkillManDays } from 'interfaces';
import { memo } from 'react';
import store from './store';

interface IProps {
  elementId: string;
}

const CreateUpdateConsultantExperienceFormSkillsOptions = ({ elementId }: IProps) => {

  const { data: dataSkillsFiltered } = useCreateUpdateConsultantExperienceFormDataSkillsFiltered();
  const _skillMandays = useSelector(store.get.skillMandays);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnSelectySkill = (skill: string) => {
    const skillMandays = new Set<ISkillManDays>([..._skillMandays, ({ skillId: skill, manDays: 0 }) as ISkillManDays]);
    store
      .set
      .set(
        dispatch,
        l => ({
          ...l,
          formSkill: ({ ...l.formSkill, value: '' }),
          skillMandays: Array.from(skillMandays)
        })
      );
  };

  return (
    <Callout
      role='alertdialog'
      directionalHint={DirectionalHint.bottomLeftEdge}
      isBeakVisible={false}
      gapSpace={0}
      target={`#${elementId}`}>
      <Stack>
        <Stack className='mt-2 mx-3' style={{ marginBottom: -5 }}>
          <Text variant='small' className='text-green ms-fontWeight-semibold'>
            {t('common.suggestedSkils')}
          </Text>
        </Stack>
        <Separator />
        <Stack horizontal={false} className='mb-2' style={{ marginTop: -5 }}>
          {
            dataSkillsFiltered
              .map(
                l => (
                  <CreateUpdateConsultantExperienceFormSkillsOptionItem
                    onSelect={handleOnSelectySkill}
                    key={l.id}
                    id={l.id} />
                )
              )
          }
        </Stack>

        {
          dataSkillsFiltered.length === 0 &&
          <Stack horizontalAlign='center' className='mb-2 mx-3 mt-0 pt-0' style={{ marginTop: -10 }}>
            <Text variant='small' className='ms-fontColor-gray80 italic'>
              {t('common.noData')}
            </Text>
          </Stack>
        }
      </Stack>
    </Callout>
  );

};

export default memo(CreateUpdateConsultantExperienceFormSkillsOptions);
