import httpRequestService from 'services/httpRequestService';
import { IHttpRequestServiceOptions } from 'interfaces';

const identity = {

  users: {
    
    updateUsersStatuses: (idList: string[], isActive: boolean, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<void>(
          '/identity/users?ids=' + idList.join('&ids='),
          params,
          {
            method: 'PUT',
            data: { isActive }
          }
        )
  }

};

export default identity;
