import {
  IUpdateConsultancyProfileFormData,
  ICreateConsultancyProfileFormData,
  IHttpRequestServiceOptions,
  IHasIdsOptional,
  IConsultant
} from 'interfaces';
import { broadcastService, convertService, httpRequestService } from 'services';
import academicBackground from './academicBackground';
import { EEventType, EHttpRequestName } from 'enums';
import { TExtensionFile, TNullable } from 'types';
import experiences from './experiences';
import currentUser from './currentUser';
import languages from './languages';
import sharing from './sharing';
import skills from './skills';

const baseUrl = '/consultancy-profiles';

const consultancyProfiles = {

  academicBackground,
  currentUser,
  experiences,
  languages,
  sharing,
  skills,

  download: (consultantId: string, extension: TExtensionFile, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<Blob>(
        `${baseUrl}/${consultantId}/download?extension=${extension}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DownloadConsultancyProfile
        },
        { responseType: 'blob' }
      )
      .then(
        response => ({
          fileName: response.headers['response-data-header'],
          blobData: response.data
        })
      ),

  get: (query: IHasIdsOptional & { includePhoto?: boolean; } = {}, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IConsultant[]>(
        `${baseUrl}?${convertService.toQueryString(query?.ids, 'ids')}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetConsultancyProfiles
        }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                photo: l.photo?.toBase64(),
                birthDate: convertService.convertToDate(l.birthDate)
              })
            )
        )
      ),

  create: (
    userIdOwner: string,
    data: ICreateConsultancyProfileFormData,
    params: IHttpRequestServiceOptions = {}
  ) => {
    const formData = new FormData();
    data.description && formData.append('description', data.description);
    data.photo && formData.append('photo', data.photo);
    formData.append('nationalityCountryCodeIso2', data.nationalityCountryCodeIso2);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('gender', data.gender);
    formData.append('title', data.title);

    return (
      httpRequestService
        .request<string>(
          `${baseUrl}`,
          params,
          {
            data: formData,
            method: 'POST',
            headers: { userIdOwner }
          }
        )
        .then(response => response.data)
        .then(
          consultantId => {
            broadcastService.publish({ type: EEventType.consultancyProfileCreated, payload: { consultantId } });
            return consultantId;
          }
        )
    );
  },

  updateProfile: (
    userIdOwner: string,
    consultantId: string,
    data: IUpdateConsultancyProfileFormData,
    params: IHttpRequestServiceOptions = {}
  ) => {
    const formData = new FormData();
    data.description && formData.append('description', data.description);
    data.photo && formData.append('photo', data.photo);
    formData.append('nationalityCountryCodeIso2', data.nationalityCountryCodeIso2);
    formData.append('photoUpdated', (data.photoUpdated ?? false).toString());
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('gender', data.gender);
    formData.append('title', data.title);

    return (
      httpRequestService
        .request<string>(
          `${baseUrl}/${consultantId}`,
          params,
          {
            data: formData,
            method: 'PUT',
            headers: { userIdOwner }
          }
        )
        .then(response => response.data)
        .then(
          consultantId => {
            broadcastService.publish({ type: EEventType.consultancyProfileUpdated, payload: { consultantId, data } });
            return consultantId;
          }
        )
    );
  },

  delete: (consultantId: string, params: IHttpRequestServiceOptions = {}) => (
    httpRequestService
      .request<void>(
        `${baseUrl}/${consultantId}`,
        params,
        { method: 'DELETE' }
      )
      .then(response => response.data)
  ),

  updateContactInfo: (
    consultantId: string,
    data: {
      phoneNumberIndicatif?: TNullable<string>;
      phoneNumber?: TNullable<string>;
      birthDate?: TNullable<Date>;
      address?: TNullable<string>;
      email?: TNullable<string>;
    },
    params: IHttpRequestServiceOptions = {}
  ) => {

    if (!data.phoneNumber || !data.phoneNumberIndicatif) {
      delete data.phoneNumberIndicatif;
      delete data.phoneNumber;
    }

    !data.birthDate && delete data.birthDate;
    !data.address && delete data.address;
    !data.email && delete data.email;   

    return (
      httpRequestService
        .request<void>(
          `${baseUrl}/${consultantId}/contact-info`,
          params,
          {
            data,
            method: 'PUT'
          }
        )
        .then(response => response.data)
    );
  }

};

export default consultancyProfiles;
