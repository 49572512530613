import { IHttpRequestError, IMessageBoxCommand, IMessageBoxOptions } from 'interfaces';
import { getErrorDescription } from 'functions';
import { EMessageBoxType } from 'enums';
import { Subject } from 'rxjs';

/** Global variables */
const VARIABLES = {
  /** Subject of service 'alert'. */
  subject: new Subject<IMessageBoxCommand>()
};

/** Service used to spread notifications in application. */
const messageBoxService = {
  /**
   * Command to display error in message box.
   * @param subject Subject displayed in message box.
   * @param options Option to apply on message box.
   */
  error: (
    subject: string,
    options?: Omit<
      Omit<Omit<IMessageBoxOptions, 'confirmActionCallBack'>, 'cancelActionText'>,
      'confirmActionText'
    >
  ) => messageBoxService.show(subject, EMessageBoxType.error, options),
  /**
   * Command to display error in message box.
   * @param error http request error.
   * @param options Option to apply on message box.
   */
  errorHttpRequest: (
    error: IHttpRequestError | any,
    options?: Omit<
      Omit<Omit<IMessageBoxOptions, 'confirmActionCallBack'>, 'cancelActionText'>,
      'confirmActionText'
    >
  ) => messageBoxService.show([...getErrorDescription(error)].join('\n'), EMessageBoxType.error, options),
  /**
   * Command to display warning in message box.
   * @param subject Subject displayed in message box.
   * @param options Option to apply on message box.
   */
  warning: (
    subject: string,
    options?: Omit<
      Omit<Omit<IMessageBoxOptions, 'confirmActionCallBack'>, 'cancelActionText'>,
      'confirmActionText'
    >
  ) => messageBoxService.show(subject, EMessageBoxType.warning, options),
  /**
   * Command to display information in message box.
   * @param subject Subject displayed in message box.
   * @param options Option to apply on message box.
   */
  info: (
    subject: string,
    options?: Omit<
      Omit<Omit<IMessageBoxOptions, 'confirmActionCallBack'>, 'cancelActionText'>,
      'confirmActionText'
    >
  ) => messageBoxService.show(subject, EMessageBoxType.info, options),
  /**
   * Command to display success in message box.
   * @param subject Subject displayed in message box.
   * @param options Option to apply on message box.
   */
  success: (
    subject: string,
    options?: Omit<
      Omit<Omit<IMessageBoxOptions, 'confirmActionCallBack'>, 'cancelActionText'>,
      'confirmActionText'
    >
  ) => messageBoxService.show(subject, EMessageBoxType.success, options),
  /**
   * Command to display message box.
   * @param subject Subject displayed in message box.
   * @param type Type of message box displayed.
   * @param options Option to apply on message box.
   */
  show: (subject: string, type: EMessageBoxType, options?: IMessageBoxOptions) =>
    VARIABLES.subject.next({ subject, type, options }),
  /**
   * Command to display message box for confirmation.
   * @param subject Subject displayed in message box.
   * @param callBack Callback funtion when action will be confirmed.
   * @param options Option to apply on message box.
   */
  confirm: (
    subject: string,
    callBack: () => Promise<void>,
    options?: Omit<IMessageBoxOptions, 'confirmActionCallBack'>
  ) =>
    messageBoxService.show(subject, EMessageBoxType.question, {
      ...options,
      confirmActionCallBack: callBack
    }),
  /** Command to get current message box to display. */
  getMessages: () => VARIABLES.subject.asObservable()
};

export default messageBoxService;
