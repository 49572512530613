import { DefaultButton, IconButton, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import { ICreateConsultancyProfileFormData } from 'interfaces';
import { Control, UseFormSetValue } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyFunction } from 'functions';
import { MAX_FILE_SIZE } from 'constant';
import { EIconName } from 'enums';
import { TNullable } from 'types';

interface IProps {
  setValue: UseFormSetValue<ICreateConsultancyProfileFormData>;
  control: Control<any>;
}

interface IState {
  photo?: TNullable<File>;
}

const VARIABLES = {
  initState: {
  } as IState
};

const CreateUpdateConsultancyProfileFormPhoto = ({ setValue, control }: IProps) => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  useEffect(
    () => {
      const photo = control._formValues.photo;    

      photo &&
        fetch(photo)
          .then(response => response.blob())
          .then(data => setState(l => ({ ...l, photo: data as File })))
          .catch(emptyFunction);
    },
    [control._formValues.photo]
  );

  const handleOnClickButtonAddConsultingProfile = (event: any) => {
    event?.persist();
    const htmlInputElement = document.createElement('input');
    htmlInputElement.style.visibility = 'hidden';
    htmlInputElement.setAttribute('type', 'file');
    htmlInputElement.setAttribute('accept', '.jpg, .jpeg, .png');

    htmlInputElement.onchange = handleOnFileAdded;

    document.body.appendChild(htmlInputElement);

    htmlInputElement.click();

    setTimeout(
      () => htmlInputElement.remove(),
      10000
    );
  };

  const handleOnFileAdded = (event?: any) => {
    const photo: File = event.target.files[0];

    if (!photo) {
      event.target.value = null;
      return;
    }

    if (photo.size > MAX_FILE_SIZE) {
      event.target.value = null;
      return;
    }

    setState(l => ({ ...l, photo }));
    setValue('photo', photo);
  };

  const handonOnClickButtonClearPhoto = () => {
    setState(l => ({ ...l, photo: null }));
    setValue('photo', undefined);
  };

  const imageUrl = (
    state.photo
      ? URL.createObjectURL(state.photo)
      : undefined
  );

  return (
    <Stack className='px-3' horizontalAlign='center' tokens={{ childrenGap: 8 }}>
      <Text className='ms-fontWeight-semibold'>
        {t('common.photo')}
      </Text>
      <Stack horizontalAlign='center' className='my-auto' tokens={{ childrenGap: 8 }}>
        <Persona
          title={state.photo?.name}
          showUnknownPersonaCoin={!state.photo}
          size={PersonaSize.size100}
          imageUrl={imageUrl}
          hidePersonaDetails />
        <Text className='ms-fontColor-gray90' variant='small'>
          {state.photo?.name}
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 2 }}>
        <DefaultButton
          className='ms-fontWeight-semibold border-0'
          onClick={handleOnClickButtonAddConsultingProfile}
          iconProps={{ iconName: EIconName.Upload, style: { fontSize: 14 } }}>
          {t('common.upload')}
        </DefaultButton>
        <IconButton
          onClick={handonOnClickButtonClearPhoto}
          iconProps={{ iconName: EIconName.Cancel }} />
      </Stack>
    </Stack>
  );

};

export default memo(CreateUpdateConsultancyProfileFormPhoto);
