import { formPanelService, httpRequestService } from 'services';
import { ConsultantLanguagesForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { IConsultantLanguage } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (data: IConsultantLanguage) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      codeISO2Language: '',
      proficiency: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IConsultantLanguage>
};

const useConsultantLanguagesForm = () => {

  const { handleSubmit, control, reset } = useForm<IConsultantLanguage>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerFormAdd = (consultantId: string, languages: IConsultantLanguage[], callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: <ConsultantLanguagesForm control={control as any} codeISO2LanguagesExcluded={languages.map(l => l.codeISO2Language)} />,
        title: t('common.addLanguages'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .languages
                .add(consultantId, data, { showErrorMessage: false })
                .then(() => callback?.onSuccess?.(data))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });

  };

  return { triggerFormAdd };

};

export default useConsultantLanguagesForm;
