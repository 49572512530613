import { ProgressIndicator } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { appStore } from 'store';
import { memo } from 'react';

const HeaderProgressIndicator = () => {

  const _isHeaderLoadingVisible = useSelector(appStore.common.get.isHeaderLoadingVisible);

  const percentComplete = (
    _isHeaderLoadingVisible
      ? undefined
      : 0
  );

  return <ProgressIndicator className='app-stackprogressIndicator' percentComplete={percentComplete} />;

};

export default memo(HeaderProgressIndicator);
