import { concatPath } from 'functions';
import { TRouteError } from 'types';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirect to application error page. */
const ERRORS = {
  id: '/errors',
  /** Base url of route */
  base: '/errors',
  /** Path of route. */
  path: () => concatPath(
    ROUTES.path(),
    ROUTES.ERRORS.base
  ),
  relativePath: () => `errors/*`,
  /** Description of route. */
  description: (t: Function): string => t('routes.errors'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeErrors'*/'modules/errors/routes')),
  /** Route that redirects to internal server error in module errors in application. */
  INTERNAL_SERVER_ERROR: {
    id: '/errors/internalServerError',
    /** Base url of route */
    base: '/internalServerError',
    /** Path of route. */
    path: () => concatPath(
      ROUTES.ERRORS.path(),
      ROUTES.ERRORS.INTERNAL_SERVER_ERROR.base
    ),
    relativePath: () => 'internalServerError',
    /** Description of route. */
    description: (t: Function): string => t('routes.errors.internalServerError'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeErrorsInternalServerError'*/'modules/errors/pages/internalServerError'))
  },
  /** Route that redirect to network in module errors in application. */
  NETWORK: {
    id: '/errors/network',
    /** Base url of route */
    base: '/network',
    /** Path of route. */
    path: () => concatPath(
      ROUTES.ERRORS.path(),
      ROUTES.ERRORS.NETWORK.base
    ),
    relativePath: () => 'network',
    /** Description of route. */
    description: (t: Function): string => t('errors.network.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeErrorsNetwork'*/'modules/errors/pages/network'))
  },
  /** Route that redirects to forbidden in module errors in application. */
  FORBIDDEN: {
    id: '/errors/forbidden',
    /** Base url of route */
    base: '/forbidden',
    /** Path of route. */
    path: () => concatPath(
      ROUTES.ERRORS.path(),
      ROUTES.ERRORS.FORBIDDEN.base
    ),
    relativePath: () => 'forbidden',
    /** Description of route. */
    description: (t: Function): string => t('errors.forbidden.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeErrorsForbidden'*/'modules/errors/pages/forbidden'))
  }
} as TRouteError;

export default ERRORS;
