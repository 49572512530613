import NotificationsFormItemHeader from './NotificationsFormItemHeader';
import NotificationsFormItemFooter from './NotificationsFormItemFooter';
import NotificationsFormItemTitle from './NotificationsFormItemTitle';
import NotificationsFormItemUrl from './NotificationsFormItemUrl';
import { INotification } from 'interfaces';
import { Stack } from '@fluentui/react';
import { memo } from 'react';

interface IProps {
  notification: INotification;
}

const NotificationsFormItem = ({ notification }: IProps) => (
  <Stack
    tokens={{ childrenGap: 10 }}
    style={{ marginTop: -5, marginBottom: -5 }}
    className='notificationStack cursor-pointer py-2 px-3'>
    <NotificationsFormItemHeader notification={notification} />
    <NotificationsFormItemTitle notification={notification} />
    <NotificationsFormItemUrl notification={notification} />
    <NotificationsFormItemFooter notification={notification} />
  </Stack>
);

export default memo(NotificationsFormItem);
