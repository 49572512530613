import { IBaseFile, IHasDescription } from 'interfaces';
import { Dispatch } from '@reduxjs/toolkit';
import { appStore } from 'store';

const VARIABLES = {
  key: 'CreateTicketForm',
  initStore: {
    description: '',
    files: []
  } as ICreateTicketFormStore
};

export interface ICreateTicketFormStore extends IHasDescription {
  files: IBaseFile[];
}

const set = {
  set: (dispatch: Dispatch, setter: (state: ICreateTicketFormStore) => ICreateTicketFormStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  description: appStore.record.get.get(VARIABLES.key, (record: ICreateTicketFormStore) => record?.description ?? VARIABLES.initStore.description),
  files: appStore.record.get.get(VARIABLES.key, (record: ICreateTicketFormStore) => record?.files ?? VARIABLES.initStore.files)
};

const store = {
  set,
  get
};

export default store;
