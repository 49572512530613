import { EIconName, ETicketCriticality } from 'enums';
import { Icon, Stack, Text } from '@fluentui/react';
import { IHasData } from 'interfaces';
import { useEnumTitle } from 'hooks';

const CellRendererFrameworkTypeCriticality = ({ data }: IHasData<{ criticality: ETicketCriticality; }>) => {

  const { title: enumTitle } = useEnumTitle(ETicketCriticality[data.criticality] as keyof typeof ETicketCriticality);

  return (
    <Stack
      horizontal
      verticalAlign='center'
      tokens={{ childrenGap: 5 }}>
      {
        data.criticality === ETicketCriticality.major &&
        <Icon iconName={EIconName.warning} className='ms-fontSize-12 ms-fontColor-yellow ms-fontWeight-bold' />
      }
      {
        data.criticality === ETicketCriticality.critical &&
        <Icon iconName={EIconName.warning} className='ms-fontSize-12 ms-fontColor-red ms-fontWeight-bold' />
      }
      <Text>
        {enumTitle}
      </Text>
    </Stack>
  );

};

export default CellRendererFrameworkTypeCriticality;
