import { IHasDescriptionOptional, IHasId, IHasName } from 'interfaces';
import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'modules/time-tracking/pages/projects/store';

type TDataVariables = IHasId & IHasName & IHasDescriptionOptional & {
  clientId: string;
};

const VARIABLES = {
  queryKeyId: () => [QUERY_KEYS.updateTimeTrackingProject]
};

export const useHttpRequestUpdateTimeTrackingProject = () => {

  const queryClient = useQueryClient();
  const _gridApi = useSelector(store.get.gridApi);
  const { t } = useTranslation();
  const mutation = useMutation({
    //mutationKey: VARIABLES.queryKeyId(),
    mutationFn: (data: TDataVariables) => (
      httpRequestService
        .request<void>(
          `/time-entries/projects/${data.id}`,
          { showErrorMessage: false },
          {
            method: 'PUT',
            data: ({
              ...data,
              id: null
            })
          }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingProjects] });
    }
  });

  return (mutation);

};
