import { MessageBar, MessageBarType } from '@fluentui/react';
import { memo, useEffect, useState } from 'react';
import { IHttpRequestError } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { EHttpResponseStatus } from 'enums';

interface IProps {
  onClose: () => void;
  error?: IHttpRequestError | string | JSX.Element;
}

interface IState {
  isDeleted: boolean;
}

const VARIABLES = {
  timeout: 500,
  initState: {
    isDeleted: false
  } as IState
};

const FormPanelAlert = ({ error, onClose }: IProps) => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  useEffect(
    () => setState(l => ({ ...l, isDeleted: false })),
    [error]
  );

  const handleOnDismiss = () => {
    setState(l => ({ ...l, isDeleted: true }));
    setTimeout(onClose, VARIABLES.timeout);
  };

  const elementError = () => (
    <>
      {
        (error as IHttpRequestError)?.Status
          ? [
            EHttpResponseStatus.InternalServerError,
            EHttpResponseStatus.NotAcceptable,
            EHttpResponseStatus.NotAllowed,
            EHttpResponseStatus.BadRequest,
            EHttpResponseStatus.Forbidden,
            EHttpResponseStatus.NotFound
          ].includes((error as IHttpRequestError)?.Status)
            ? (error as IHttpRequestError)?.Title
            : error
          : error
      }
    </>
  );

  if (!error) {
    return <></>;
  }

  return (
    <MessageBar
      className={`my-2 ${state.isDeleted && 'ms-fadeOut500'}`}
      dismissButtonAriaLabel={t('common.close')}
      messageBarType={MessageBarType.error}
      onDismiss={handleOnDismiss}
      isMultiline={false}
      children={elementError()} />
  );

};

export default memo(FormPanelAlert);
