import HeaderItemsUserPopupHeader from './HeaderItemsUserPopupHeader';
import { EIconName, ENomenclatureType, EUserRole } from 'enums';
import { Icon, PersonaSize, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useNomenclatureData } from 'hooks';
import { UserPersona } from 'components';
import { getFullName } from 'functions';
import { appStore } from 'store';
import { memo } from 'react';
import './HeaderItemsUserPopup.scss';

const HeaderItemsUserPopup = () => {

  const { data: dataNomenclatureRoles } = useNomenclatureData<EUserRole>(ENomenclatureType.UserRole);
  const _isUserConnected = useSelector(appStore.userConnected.get.isUserConnected);
  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tertiaryText = (
    dataNomenclatureRoles
      .filter(l => _userConnected.roles.some(ll => ll === l.id))
      .map(l => l.title.toUpperCase())
      .join(', ')
  );

  
  const handleOnClickSignInDifferentAccount = () => {
    appStore.userConnected.set.set(dispatch, l => ({ ...l, isLoggingOut: true }));
    // Clear cache => logs out only client side
    httpRequestService
      .azureAD
      .clearCache()
      .then(
        () =>// Login with different account
          httpRequestService.azureAD.loginRedirect({
            ...httpRequestService.azureAD.getAuthenticationRequest(),
            prompt: 'select_account'
          })
      );
  };

  /** Element that display content of component. */
  const elementContent = () => (
    <UserPersona
      className='mx-3 mb-3 mt-1'
      hidePersonaDetails={false}
      id={_userConnected.id}
      size={PersonaSize.size72}
      onRenderPrimaryText={
        () => (
          <Text className='text-green' variant='xLarge'>
            {getFullName(_userConnected)}
          </Text>
        )
      }
      onRenderTertiaryText={() => <strong>{tertiaryText}</strong>}
      secondaryText={_userConnected.email}
      tertiaryText={tertiaryText} />
  );

  /** Element that display footer of component. */
  const elementFooter = () => (
    <div className='ms-bgColor-gray10 d-flex align-items-center cursor-pointer' onClick={handleOnClickSignInDifferentAccount}>
      <div>
        <Icon iconName={EIconName.addFriend} />
      </div>
      <small>
        {t('headerItemsUserPopup.signInDifferentAccount')}
      </small>
    </div>
  );

  if (!_isUserConnected) {
    return <></>;
  }

  return (
    <div className='app-headerItemsUserPopup'>
      <HeaderItemsUserPopupHeader />
      {elementContent()}
      {elementFooter()}
    </div>
  );

};

export default memo(HeaderItemsUserPopup);
