import { IHasData, IHasFileName } from 'interfaces';

export interface IFileFromServer extends IHasData<string>, IHasFileName {
}

export const fileFromServerToFile = (fileFromServer: IFileFromServer) => ({
  fileName: fileFromServer.fileName,
  data: new Blob([base64ToArrayBuffer(fileFromServer.data)])
}) as IHasFileName & IHasData<Blob>;

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
