import { NotificationsForm } from 'components/forms';
import { useTranslation } from 'react-i18next';
import { formPanelService } from 'services';
import { PanelType } from '@fluentui/react';

const useNotificationsForm = () => {

  const { t } = useTranslation();

  const triggerForm = () => {
    formPanelService.publish({
      content: <NotificationsForm />,
      title: t('Notifications'),
      type: PanelType.custom,
      customWidth: '500px',
      showConfirmButton: false,
      cancelActionText: t('common.close')
    });
  };

  return { triggerForm };

};

export default useNotificationsForm;
