import { Dispatch } from '@reduxjs/toolkit';
import { appStore } from 'store';

const VARIABLES = {
  key: 'CreateTranslationFolderForm',
  initStore: {
    repositories: [],
    branches: []
  } as ICreateTranslationFolderFormStore
};

export interface ICreateTranslationFolderFormStore {
  repositories: string[];
  branches: string[];
}

const set = {
  set: (
    dispatch: Dispatch,
    setter: (state: ICreateTranslationFolderFormStore) => ICreateTranslationFolderFormStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }
  ),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  repositories: appStore.record.get.get(VARIABLES.key, (record: ICreateTranslationFolderFormStore) => record?.repositories ?? VARIABLES.initStore.repositories),
  branches: appStore.record.get.get(VARIABLES.key, (record: ICreateTranslationFolderFormStore) => record?.branches ?? VARIABLES.initStore.branches),
};

const store = {
  set,
  get
};

export default store;
