import { AuthenticationResult, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { ENVIRONMENT_VARIABLES } from 'constant';
import { AccountInfo } from '@azure/msal-common';
import { ICurrentUser } from 'interfaces';
import { EUserRole } from 'enums';

declare module '@azure/msal-browser' {

  interface PublicClientApplication {
    /** Get current parameter for authentication request. */
    getAuthenticationRequest(): RedirectRequest;
    /** Extract and convert account connected to current user. */
    getCurrentUser(): ICurrentUser;
    /** Get current account active in session. */
    getCurrentAccount(): AccountInfo;
    /** Get access token of current user connected. */
    getAccessToken(): Promise<string>;
  }

}

/** Get current parameter for authentication request.'. */
// eslint-disable-next-line no-extend-native
PublicClientApplication.prototype.getAuthenticationRequest = function (this: PublicClientApplication) {

  return ({
    scopes: [
      ENVIRONMENT_VARIABLES.identityServer.scope
    ]
  }) as RedirectRequest;

};

/** Get current account active in session. */
// eslint-disable-next-line no-extend-native
PublicClientApplication.prototype.getCurrentAccount = function (this: PublicClientApplication) {

  return this.getAllAccounts()[0];

};

/** Extract and convert account connected to current user. */
// eslint-disable-next-line no-extend-native
PublicClientApplication.prototype.getCurrentUser = function (this: PublicClientApplication) {
  
  const activeAccount = this.getCurrentAccount();

  if (activeAccount === undefined) {
    return ({} as ICurrentUser);
  }

  const roles = ((activeAccount.idTokenClaims as Record<string, any>)['roles'] as string[] ?? []).map(l => l.toUpperCase());
  const [firstName, lastName] = (activeAccount.name as string).split(' ');

  const keysRole =  Object
                      .keys(EUserRole)
                      .filter(l => isNaN(l as any))
                      .filter(l => roles.some(ll => ll === l.toUpperCase()));

  return ({
    roles: keysRole.map(l => EUserRole[l as keyof typeof EUserRole]),
    id: activeAccount.localAccountId,
    email: activeAccount.username,
    firstName: firstName,
    lastName: lastName
  }) as ICurrentUser;

};

/** Get access token of current user connected. */
// eslint-disable-next-line no-extend-native
PublicClientApplication.prototype.getAccessToken = async function (this: PublicClientApplication) {

  // Declare variable
  let tokenResult: AuthenticationResult | undefined = undefined;
  // Get current user connected
  const currentAccount = this.getCurrentAccount();

  // Get access token if user found
  if (currentAccount) {
    try {
      tokenResult = await this.acquireTokenSilent({
        scopes: [ENVIRONMENT_VARIABLES.identityServer.scope],
        account: currentAccount
      });
    } catch (e: any) {
        await this.acquireTokenRedirect({
                scopes: [ENVIRONMENT_VARIABLES.identityServer.scope],
                account: currentAccount,
                redirectUri: ENVIRONMENT_VARIABLES.baseUrl})
    };
  }

      // if (e instanceof InteractionRequiredAuthError) {
      //     // fallback to interaction when silent call fails
      //     await this.acquireTokenRedirect({
      //       scopes: [ENVIRONMENT_VARIABLES.identityServer.scope],
      //       account: currentAccount,
      //       redirectUri: ENVIRONMENT_VARIABLES.baseUrl
      //     });
      // }

      // // should be an 'InteractionRequiredAuthError'
      // if ((e.Title as string | undefined)?.startsWith('monitor_window_timeout')) {
      //   // msal is weird
      //   await this.acquireTokenRedirect({
      //     scopes: [ENVIRONMENT_VARIABLES.identityServer.scope],
      //     account: currentAccount,
      //     redirectUri: ENVIRONMENT_VARIABLES.baseUrl
      //   });
      // }
      
      // throw e;
  //   };
  // }

  return tokenResult?.accessToken ?? '';

};
