import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { IHasName } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from 'modules/time-tracking/pages/clients/store';

type TDataVariables = IHasName;

const VARIABLES = {
  queryKeyId: () => [QUERY_KEYS.createTimeTrackingClient]
};

export const useHttpRequestCreateTimeTrackingClient = () => {

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const _gridApi = useSelector(store.get.gridApi);
  const mutation = useMutation({
    //mutationKey: VARIABLES.queryKeyId(),
    mutationFn: (data: TDataVariables) => (
      httpRequestService
        .request<string>(
          `/time-entries/clients`,
          { showErrorMessage: false },
          { method: 'POST', data }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingClients] });
    }
  });

  return (mutation);

};
