import CreateUpdateConsultancyProfileFormPhoto from './CreateUpdateConsultancyProfileFormPhoto';
import { ControlledDropdown, ControlledTextField } from 'components';
import { Control, UseFormSetValue } from 'react-hook-form';
import { Separator, Stack } from '@fluentui/react';
import { EGender, ENomenclatureType } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNomenclatureData } from 'hooks';
import { IConsultant } from 'interfaces';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  setValue: UseFormSetValue<any>;
  control: Control<any>;
}

const CreateUpdateConsultancyProfileForm = ({ control, setValue }: IProps) => {

  const { data: dataCountries } = useNomenclatureData<string>(ENomenclatureType.Country);
  const { data: dataGenders } = useNomenclatureData<EGender>(ENomenclatureType.Gender);
  const { t } = useTranslation();

  const dataCountriesSorted = [...dataCountries].sort((a, b) => a.title > b.title ? 1 : -1);

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 8 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Stack grow tokens={{ childrenGap: 8 }}>
          <ControlledTextField
            control={control}
            label={t('common.title')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<IConsultant>('title')} />
          <ControlledTextField
            control={control}
            label={t('common.firstName')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<IConsultant>('firstName')} />
          <ControlledTextField
            control={control}
            label={t('common.lastName')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<IConsultant>('lastName')} />
        </Stack>
        <CreateUpdateConsultancyProfileFormPhoto setValue={setValue} control={control} />
      </Stack>
      <ControlledDropdown
        control={control}
        options={dataGenders.map(l => ({ key: l.id.toString(), text: l.title, data: l.id }))}
        label={t('common.gender')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultant>('gender')} />
      <ControlledDropdown
        control={control}
        options={dataCountriesSorted.map(l => ({ key: l.id.toString(), text: l.title, data: l.id }))}
        label={t('common.nationality')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultant>('nationalityCountryCodeIso2')} />
      <Separator />
      <Stack style={{ marginTop: -4 }}>
        <ControlledTextField
          multiline
          autoAdjustHeight
          control={control}
          label={t('common.description')}
          name={nameof<IConsultant>('description')} />
      </Stack>
    </Stack>
  );

};

export default memo(CreateUpdateConsultancyProfileForm);
