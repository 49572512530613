import { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMessageBarStyles, MessageBar } from '@fluentui/react';
import { alertService } from 'services';
import { IAlert } from 'interfaces';

/** Interface of component's props. */
interface IProps extends IAlert {
  onRemove: () => void;
}

/** Interface of state */
interface IState {
  basePathname: string;
  isDeleted: boolean;
}

/** Global variables */
const VARIABLES = {
  timeout: 500,
  initState: {
    isDeleted: false
  } as IState
};

/**
 * Component used to display alert item in alert component.
 * @param props Properties of component.
 * @returns
 */
const AlertItem = ({ id, message, type, isAutoClose, isClosable, isKeptOnAllPage, ms, iconStyle, color, textColor, onRemove }: IProps) => {
  // Path of current route
  const { pathname } = useLocation();
  // State's accessers of component.
  const [state, setState] = useState<IState>({ ...VARIABLES.initState, basePathname: pathname });

  // Behavior of component when route changes
  useEffect(
    () => {
      // kill alert when page is changed
      if (!isKeptOnAllPage && state.basePathname !== pathname) {
        handleOnRemove();
      }
    },
    // eslint-disable-next-line
    [pathname]
  );

  /** Funtion used to remove alert in application. */
  const handleOnRemove = () => {
    // Update state
    setState(l => ({ ...l, isDeleted: true }));
    // Wait animation expires
    setTimeout(onRemove, VARIABLES.timeout);
  };

  // Behavior when component is mounted and unmounted
  useEffect(
    () => {
      // Subscription to alert service
      const subscription = alertService.getAlerts().subscribe(alert => {
        // Check if a new alert has been published
        if (alert.type === 'remove' && alert.data === id) {
          // Remove alert
          handleOnRemove();
        }
      });
      // When component is unmounted
      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  // Behavior when component is mounted and unmounted
  useEffect(
    // eslint-disable-next-line
    () => {
      // Check if component is not auto closable
      if (!isAutoClose) {
        // Quit function
        return () => {};
      }

      // Delete component after time elapsed
      const timeout = setTimeout(handleOnRemove, ms);
      // Kill function when component clears
      return () => clearTimeout(timeout);
    },
    // eslint-disable-next-line
    []
  );

  const customStyle = color 
    ? {content: {backgroundColor: color, color: textColor} } as IMessageBarStyles
    : undefined;

  return (
    <MessageBar
      className={`mb-2 ${state.isDeleted && 'ms-fadeOut500'}`}
      onDismiss={isClosable ? () => handleOnRemove() : undefined}
      dismissButtonAriaLabel='Close'
      messageBarType={type}
      messageBarIconProps={iconStyle}
      styles={customStyle}
      isMultiline={false}>
      {message}
    </MessageBar>
  );
  
};

export default memo(AlertItem);
