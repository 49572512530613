import { IHttpRequestServiceOptions, IConsultant, IHasIdsOptional } from 'interfaces';
import { convertService, httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/consultancy-profiles';

const currentUser = {

  get: (query: IHasIdsOptional & { includePhoto?: boolean; } = {}, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IConsultant[]>(
        `${baseUrl}/current-user?${convertService.toQueryString(query?.ids, 'ids')}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetConsultancyProfiles
        },
        { params: query }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                photo: l.photo?.toBase64()
              })
            )
        )
      ),

  getShared: (query: { includePhoto?: boolean; } = {}, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IConsultant[]>(
        `${baseUrl}/current-user/shared`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetConsultancyProfiles
        },
        { params: query }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                photo: l.photo?.toBase64()
              })
            )
        )
      )

};

export default currentUser;
