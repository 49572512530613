import { Stack, ActionButton, Separator } from '@fluentui/react';
import NotificationsFormItem from './NotificationsFormItem';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingView, NoResult } from 'components';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { useHttpRequestStatus } from 'hooks';
import { emptyFunction } from 'functions';
import { memo, useEffect } from 'react';
import { EIconName } from 'enums';
import { appStore } from 'store';

const VARIABLES = {
  requestId: 'NotificationsForm.getNotifications'
};

const NotificationsForm = () => {

  const { isLoading: isLoadingNotifications } = useHttpRequestStatus(VARIABLES.requestId, { isLoading: true });
  const _notifications = useSelector(appStore.userConnected.get.notifications);
  const _idUserConnected = useSelector(appStore.userConnected.get.id);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(
    () => {
      const cancelTokenSource = httpRequestService.getCancelTokenSource();

      httpRequestService
        .users
        .notifications
        .get(_idUserConnected, { cancelToken: cancelTokenSource.token, requestId: VARIABLES.requestId })
        .then(notifications => appStore.userConnected.set.set(dispatch, l => ({ ...l, notifications })))
        .catch(emptyFunction);

      return () => {
        cancelTokenSource.cancel();
      };
    },
    [_notifications.length]
  );

  useEffect(
    () => {
      appStore.userConnected.set.set(dispatch, l => ({ ...l, isNotificationPanelVisible: true }));

      return () => {
        httpRequestService
          .users
          .notifications
          .read(_idUserConnected)
          .then(
            () => {
              appStore
                .userConnected
                .set
                .set(
                  dispatch,
                  l => ({
                    ...l,
                    notifications: [...l.notifications.map(ll => ({ ...ll, read: true }))]
                  })
                );
            }
          )
          .catch(emptyFunction)
          .finally(() => appStore.userConnected.set.set(dispatch, l => ({ ...l, isNotificationPanelVisible: false })));
      };
    },
    []
  );

  const handleOnClickButtonCloseAllNotifications = () => {
    httpRequestService
      .users
      .notifications
      .delete(_idUserConnected, 'all')
      .then(() => appStore.userConnected.set.set(dispatch, l => ({ ...l, notifications: [] })))
      .catch(emptyFunction);
  };

  if (isLoadingNotifications) {
    return <LoadingView />;
  }

  if (_notifications.length === 0) {
    return <NoResult title={''} description={t('No new notifications from this session')} />;
  }

  return (
    <Stack horizontal={false} verticalAlign='start'>
      <ActionButton
        iconProps={{ iconName: EIconName.Cancel }}
        onClick={handleOnClickButtonCloseAllNotifications}>
        {t('notification.allClosed')}
      </ActionButton>
      <Stack horizontal={false}>
        {
          [..._notifications]
            .sort((a, b) => a.createdOn <= b.createdOn ? 1 : -1)
            .map(
              item => (
                <Stack key={item.id} horizontal={false}>
                  <Separator className='py-0 my-0' />
                  <NotificationsFormItem notification={item} />
                </Stack>
              )
            )
        }
      </Stack>
    </Stack>
  );

};

export default memo(NotificationsForm);
