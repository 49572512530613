import { DEFAULT_REQUEST_ID } from 'constant';
import { useSelector } from 'react-redux';
import { appStore } from 'store';

const useHttpRequestStatus = <TResult>(
  requestId = DEFAULT_REQUEST_ID,
  defaultValue?: { data?: TResult, isLoading?: boolean },
  middlewares: {
    data?: (data?: TResult) => TResult,
    error?: (error?: any) => any
  } = {}
) => {

  // Select value from store
  const { data, error, isLoading } = useSelector(appStore.httpRequestStatus.get.httpRequestStatus<TResult>(requestId, defaultValue));

  const dataResult = data ?? defaultValue?.data;

  const dataMiddleWare = middlewares?.data?.(dataResult) ?? dataResult;
  const errorMiddleWare = middlewares?.error?.(error) ?? error;

  return ({
    isLoading: isLoading ?? defaultValue?.isLoading ?? false,
    data: dataMiddleWare as TResult,
    error: errorMiddleWare
  });

};

export default useHttpRequestStatus;
