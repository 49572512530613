import { IPushTicketOnDevopsFormData, ITicket } from 'interfaces';
import { formPanelService, httpRequestService } from 'services';
import { PushTicketOnDevopsForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import { ETicketCriticality } from 'enums';

interface ICallback {
  onSuccess?: (workItemId: number) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IPushTicketOnDevopsFormData>
};

const usePushTicketOnDevopsForm = () => {

  const { handleSubmit, control, reset, setValue, watch, setError } = useForm<IPushTicketOnDevopsFormData>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (ticket: ITicket, callback?: ICallback) => {

    reset({
      linkToExistingWorkItem: (ticket?.workItemId ?? 0) > 0,
      description: ticket.description,
      workItemId: ticket.workItemId,
      projectId: ticket.projectId,
      title: ticket.title,
      ticketId: ticket.id,
      priority: (
        ticket.criticality === ETicketCriticality.minor
          ? 3
          : ticket.criticality === ETicketCriticality.major
            ? 2
            : 1
      )
    });

    formPanelService
      .publish({
        content: <PushTicketOnDevopsForm control={control as any} setValue={setValue} watch={watch} />,
        title: t('common.createTicket'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => {

              if (data.linkToExistingWorkItem && !data.workItem) {
                setError('workItemId', { message: t('common.workItemNotFound').toString() });
                return Promise.reject('');
              }

              return (
                (
                  data.linkToExistingWorkItem &&
                  data.workItem
                ) ? (
                  httpRequestService
                    .tickets
                    .linkToDevops(ticket.id, data.workItem.id, data.description ?? '')
                    .then(() => callback?.onSuccess?.(data.workItem?.id as number))
                    .catch(error => callback?.onError?.(error))
                )
                  : (
                    httpRequestService
                      .devops
                      .createWorkItem(data)
                      .then(workItemId => callback?.onSuccess?.(workItemId))
                      .catch(error => callback?.onError?.(error))
                  )
              );
            },
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default usePushTicketOnDevopsForm;
