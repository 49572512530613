import { Icon, ILabelProps, Label, Stack, Text } from '@fluentui/react';
import { EIconName } from 'enums';
import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './FileInput.scss';

export type FileInputProps = {
  inputClassName?: string,
  pasteAreaClassName?: string,
  labelProps?: ILabelProps,
  label?: string | JSX.Element,
} & React.InputHTMLAttributes<HTMLInputElement>;

const mediaTypeToExtensions = (mediaType: string): string[] => {
  const mediaTypeCat = mediaType.split('/')[0];
  const mediaTypeExt = mediaType.split('/').reverse()[0].toLowerCase();

  switch(mediaTypeExt)
  {
    case 'nsword':
      return ['doc'];
    case 'vnd.ms-excel':
      return ['xls'];
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return ['docx'];
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return ['xlsx'];
    default:
      return [mediaTypeExt];
  }
};

const FileInput = (props: FileInputProps) => {
  /** Translation hook */
  const { t } = useTranslation();

  const id = Math.round(Math.random() * (1000000000)).toString();

  const inputId = 'fileinput_' + id;
  const spanId = 'fileinputspan_' + id;

  const acceptedExtension: string[] = props.accept 
    ? Array.from(new Set(props.accept
      .split(',')
      .map(l => mediaTypeToExtensions(l
        .trim()))
      .flat()))
    : [];
  
  const handleOnChangeFileInput = (event: any) => {
    const newValue: string = event.target?.value 
      ? event.target.value.split('\\').pop() as string
      : t('fileInput.defaultText');

    const span = document.getElementById(spanId)
    if (span) {
      span.innerHTML = newValue;
    }
  };

  const selectFile = (fileObject: File) => {
    if (acceptedExtension.length > 0 
      && !acceptedExtension.includes(fileObject.name.split('.').reverse()[0].toLowerCase()))
    {
      //extension not accepted
      return;
    }

    const fakeOnChangeEvent = {
      target: {
        value: fileObject.name,
        files: [fileObject]
      }
    } as unknown as ChangeEvent<HTMLInputElement>

    handleOnChangeFileInput(fakeOnChangeEvent);
    props.onChange?.(fakeOnChangeEvent);
  }

  const handlePaste = (e: any) => {
    if (props.disabled) {
      return;
    }
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];

      selectFile(fileObject);
    } else {
      console.warn(
        'No image data was found in your clipboard. Copy an image first or take a screenshot.'
      );
    }
  };

  const handleDragDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.disabled) {
      return;
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileObject = e.dataTransfer.files[0];

      selectFile(fileObject);
    }
  };

  return (
    <Stack className={'app-fileInput ' + props.className} 
      onPaste={handlePaste} 
      onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      onDrop={handleDragDrop}>
      <Stack horizontal className='fileInput-line'>
        <Label { ...props.labelProps }
          className={'fileInput-label ' + props.labelProps?.className} 
          htmlFor={inputId} >
            <Icon iconName={EIconName.Upload}/>
            {props.label ?? t('fileInput.label')}
        </Label>
        <span className='fileInput-span' id={spanId}>
          {props.value ?? t('fileInput.defaultText')}
        </span>
        <input
          { ...props }
          id={inputId}
          onChange={(event: any) => {
            handleOnChangeFileInput(event);
            props.onChange?.(event);
          }}
          className={props.inputClassName}
          type='file'
        />
      </Stack>
      {!props.disabled && <div className={'fileInput-pasteArea ' + props.pasteAreaClassName}>
        {t('fileInput.pasteArea')}
      </div>}
      {acceptedExtension.length > 0 && <Text className='fileInput-commentary'>Only {acceptedExtension.join(', ')} *</Text>}
    </Stack>
  );
};

export default memo(FileInput);
