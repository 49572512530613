import { IHttpRequestServiceOptions, IHasTitle, IHasDescription, IHasId, IReleaseNote } from 'interfaces';
import { broadcastService, convertService } from 'services';
import { EEventType, EHttpRequestName } from 'enums';
import httpRequestService from '..';

const baseUrl = '/projects';

const releaseNotes = {

  get: (
    projectId: string,
    query: {
      ids?: string[];
    } = {},
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<IReleaseNote[]>(
        `${baseUrl}/${projectId}/release-notes?${convertService.toQueryString(query.ids, 'ids')}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetReleaseNotes
        }
      )
      .then(response => response.data),

  create: (
    projectId: string,
    data: IHasTitle &
      IHasDescription &
    {
      ticketIds: string[];
    },
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<string>(
        `${baseUrl}/${projectId}/release-notes`,
        params,
        {
          data,
          method: 'POST'
        }
      )
      .then(response => response.data)
      .then(
        id => {
          broadcastService.publish({ type: EEventType.releaseNoteCreated, payload: { id } as IHasId });
          return id;
        }
      )

};

export default releaseNotes;
