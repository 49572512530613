import { Controller } from 'react-hook-form';
import { IHookFormProps } from 'interfaces';
import { EMPTY } from 'constant';
import FileInput, { FileInputProps } from 'components/fileInput/FileInput';

/** Type of component's props. */
type TProps = IHookFormProps & FileInputProps;

/**
 * Component which wrap element 'TextField' for library 'react-hook-form'.
 * @param props Properties of component.
 * @returns JSX.Element
 */
const ControlledFileInput = (props: TProps) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={props.rules}
    defaultValue={props.defaultValue || EMPTY}
    render={
      ({ field: { name: fieldName, onChange, onBlur } }) => (
        <FileInput
          { ...props }
          onChange={
            (event) => {
              props.onChange?.(event);
              onChange({target: {value: event.target?.files?.[0]}});
            }
          }
          onBlur={
            (event) => {
              props.onBlur?.(event);
              onBlur();
            }
          }
          name={fieldName}
        />
      )
    }
  />
);

export default ControlledFileInput;
