import { IHttpRequestServiceOptions, ISkill } from 'interfaces';
import { httpRequestService } from 'services';
import { EHttpRequestName } from 'enums';

const baseUrl = '/skills';

const skills = {

  applyTransaction: (
    data: {
      addRange?: { parentName: string; skillNames: string[]; };
      add?: Omit<ISkill, 'id'>[];
      update?: ISkill[];
      delete?: string[]
    },
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<string[]>(
        `${baseUrl}/applyTransaction`,
        {
          ...params,
          httpRequestName: EHttpRequestName.CreateTransactionOnSkills
        },
        {
          data: {
            ...data,
            update: data?.update ?? [],
            delete: data?.delete ?? [],
            addRange: data?.addRange,
            add: data?.add ?? []
          },
          method: 'PUT'
        }
      )
      .then(response => response.data)

};

export default skills;
