import { IHasIds, IHttpRequestServiceOptions, INotification } from 'interfaces';
import httpRequestService from 'services/httpRequestService';
import { convertService } from 'services';
import { EHttpRequestName } from 'enums';
import { dayjs } from 'config';

const baseUrl = '/users';

const notifications = {

  unread: {

    count: (userId: string, params: IHttpRequestServiceOptions = {}) =>
      httpRequestService
        .request<number>(
          `${baseUrl}/${userId}/notifications/unread/count`,
          {
            ...params,
            httpRequestName: EHttpRequestName.GetUserNotificationsUnreadCount
          }
        )
        .then(response => response.data)

  },

  read: (userId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${userId}/notifications/read`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UserReadNotifications
        },
        { method: 'PUT' }
      )
      .then(response => response.data),

  delete: (userId: string, query: IHasIds | 'all', params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<string[]>(
        `${baseUrl}/${userId}/notifications/delete?${query !== 'all' && convertService.toQueryString(query.ids, 'ids')}`,
        {
          ...params,
          httpRequestName: EHttpRequestName.UserDeleteNotifications
        },
        {
          params: { all: query === 'all' },
          method: 'DELETE'
        }
      )
      .then(response => response.data),

  get: (userId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<INotification[]>(
        `${baseUrl}/${userId}/notifications`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetNotificationsOfUser
        }
      )
      .then(response => response.data)
      .then(
        data => (
          data
            .map(
              l => ({
                ...l,
                createdOn: dayjs(l.createdOn, { utc: true }).toDate()
              })
            )
        )
      )

};

export default notifications;
