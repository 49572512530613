import { useSelector } from 'react-redux';
import { appStore } from 'store';

const useUserConnected = () => {

  const _userConnected = useSelector(appStore.userConnected.get.userConnected);

  return ({
    ..._userConnected
  });

};

export default useUserConnected;
