import { EApplicationModule } from 'enums';
import { TRouteSettings } from 'types';
import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module settings in application. */
const SETTINGS = {
  module: EApplicationModule.Settings,
  id: '/settings',
  /** Parent of route. */
  parent: () => ROUTES as IRoutes,
  /** Base url of route */
  base: '/settings',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), SETTINGS.base),
  relativePath: () => `settings/*`,
  /** Description of route. */
  description: (t: Function): string => t('common.settings'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeSettings'*/ 'modules/settings/routes')),
  /** Route that redirects to overview in module settings in application. */
  OVERVIEW: {
    module: EApplicationModule.Settings,
    id: '/settings/',
    /** Parent of route. */
    parent: () => SETTINGS as IRoutes,
    /** Base url of route */
    base: '/overview',
    /** Path of route. */
    path: () => concatPath(SETTINGS.path(), SETTINGS.OVERVIEW.base),
    relativePath: () => 'overview',
    /** Description of route. */
    description: (t: Function): string => t('routes.settings.overview.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeSettingsOverview'*/ 'modules/settings/pages/overview'))
  },
  PROJECTS: {
    module: EApplicationModule.Settings,
    id: '/settings/projects',
    /** Parent of route. */
    parent: () => SETTINGS as IRoutes,
    /** Base url of route*/
    base: '/projects',
    /** Path of route */
    path: () => concatPath(SETTINGS.path(), SETTINGS.PROJECTS.base),
    relativePath: () => `projects/*`,
    /** Description of project route. */
    description: (t: Function): string => t('routes.settings.projects.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeSettingsProjects'*/ 'modules/settings/pages/projects'))
  },
  EDIT_PROJECT: {
    module: EApplicationModule.Settings,
    id: '/settings/projects/:projectId',
    /** Parent of route. */
    parent: () => SETTINGS as IRoutes,
    /** Base url of route*/
    base: '/',
    /** Parameter in route. */
    param: '/:idProject',
    /** Path of route */
    path: () => concatPath(SETTINGS.path(), SETTINGS.PROJECTS.base, SETTINGS.EDIT_PROJECT.base, SETTINGS.EDIT_PROJECT.param as string),
    relativePath: () => 'projects/:idProject',
    /** Path of route with parameters injected. */
    pathValue: (idProject: string) => SETTINGS.EDIT_PROJECT.path().replace(SETTINGS.EDIT_PROJECT.param as string, `/${idProject}`),
    /** Description of project route. */
    description: (t: Function): string => t('routes.settings.editProject.header.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeSettingsEditProject'*/ 'modules/settings/pages/editProject'))
  },
  USERS: {
    module: EApplicationModule.Settings,
    id: '/settings/users',
    /** Parent of route. */
    parent: () => SETTINGS as IRoutes,
    /** Base url of route*/
    base: '/users',
    /** Path of route */
    path: () => concatPath(SETTINGS.path(), SETTINGS.USERS.base),
    relativePath: () => 'users',
    /** Description of users route. */
    description: (t: Function): string => t('routes.settings.users.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeSettingsUsers'*/ 'modules/settings/pages/users'))
  }
} as TRouteSettings;

export default SETTINGS;
