import { CreateUpdateConsultantAcademicBackgroundForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { IConsultantAcademicBackground } from 'interfaces';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (data: IConsultantAcademicBackground) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IConsultantAcademicBackground>
};

const useCreateUpdateConsultantAcademicBackgroundForm = () => {

  const { handleSubmit, control, reset, watch, setValue } = useForm<IConsultantAcademicBackground>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerFormCreate = (consultantId: string, callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: <CreateUpdateConsultantAcademicBackgroundForm control={control as any} watch={watch} setValue={setValue} />,
        title: t('common.createAcademicBackground'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .academicBackground
                .create(consultantId, data, { showErrorMessage: false })
                .then(consultantAcademicBackgroundId => callback?.onSuccess?.({ ...data, id: consultantAcademicBackgroundId }))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  const triggerFormUpdate = (consultantId: string, academicBackground: IConsultantAcademicBackground, callback?: ICallback) => {    
    reset(academicBackground);
    formPanelService
      .publish({
        content: <CreateUpdateConsultantAcademicBackgroundForm control={control as any} watch={watch} setValue={setValue} />,
        title: t('common.updateAcademicBackground'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .academicBackground
                .update(consultantId, data.id, data, { showErrorMessage: false })
                .then(() => callback?.onSuccess?.(data))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerFormCreate, triggerFormUpdate };

};

export default useCreateUpdateConsultantAcademicBackgroundForm;
