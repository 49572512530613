import { initReactI18next } from 'react-i18next';
import { sessionService } from 'services';
import en from 'assets/i18n/en.json';
import fr from 'assets/i18n/fr.json';
import { ELang } from 'enums';
import i18n from 'i18next';

export const changeLanguage = (lang: ELang) => (
  i18n
    .changeLanguage(lang)
    .then(() => sessionService.currentLang.set(lang))
);

i18n
  .use(initReactI18next)
  .init({
    lng: sessionService.currentLang.get(),
    fallbackLng: ELang.en,
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      }
    },
    debug: false,
    ns: '',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
