import DocumentViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { IBaseFile, IHasIsLoading } from 'interfaces';
import FileViewerButton from './FileViewerButton';
import { memo, useEffect, useState } from 'react';
import LoadingView from 'components/loadingView';
import { Stack, Text } from '@fluentui/react';
import { downloadFile } from 'functions';
import { NoResult } from 'components';
import { TNullable } from 'types';
import i18n from 'config/i18n';
import { t } from 'i18next';

interface IProps {
  files: IBaseFile[];
  defaultSelected: IBaseFile;
  getFile: (file: IBaseFile) => Promise<TNullable<Blob>>;
}

interface IState extends IHasIsLoading {
  currentFileDisplayed?: TNullable<Blob>;
  currentFile?: TNullable<IBaseFile>;
}

const VARIABLES = {
  initState: {
    isLoading: true,
    currentFile: null,
    currentFileDisplayed: null
  } as IState
};

const FileViewer = ({ files, defaultSelected, getFile }: IProps) => {

  const [state, setState] = useState<IState>({ ...VARIABLES.initState, currentFile: files.find(l => l.name === defaultSelected.name) });

  const currentIndex = files.findIndex(l => l.name === state.currentFile?.name);
  const hasPreviousButtonDisabled = state.isLoading || files.length === 0 || files[0].name === state.currentFile?.name;
  const hasNextButtonDisabled = state.isLoading || files.length === 0 || files[files.length - 1].name === state.currentFile?.name;
  useEffect(
    () => {

      if (state.currentFile) {
        setState(l => ({ ...l, isLoading: true }));

        getFile(state.currentFile)
          .then(
            currentFileDisplayed => {
              currentFileDisplayed &&
                setState(l => ({ ...l, currentFileDisplayed }));
            }
          )
          .finally(() => setState(l => ({ ...l, isLoading: false })))
      }

    },
    [state.currentFile]
  );

  const handleOnClickbuttonDownloadDocument = () => {
    state.currentFileDisplayed &&
      state.currentFile &&
      downloadFile(state.currentFileDisplayed, state.currentFile.name);
  };

  if (!state.isLoading && !state.currentFileDisplayed) {
    return <NoResult />;
  }

  return (
    <Stack horizontal={false} verticalFill>
      <Stack horizontal horizontalAlign='space-between' className='p-2' style={{ backgroundColor: '#1d6257' }}>
        <FileViewerButton onClick={handleOnClickbuttonDownloadDocument} type='download' />
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FileViewerButton
            onClick={() => setState(l => ({ ...l, currentFile: files[currentIndex - 1] }))}
            disabled={hasPreviousButtonDisabled}
            type='previous' />
          <Text className='ms-fontColor-white'>
            {t<string>('common.document')} {currentIndex + 1}/{files.length}
          </Text>
          <FileViewerButton
            onClick={() => setState(l => ({ ...l, currentFile: files[currentIndex + 1] }))}
            disabled={hasNextButtonDisabled}
            type='next' />
        </Stack>
      </Stack>
      {
        state.isLoading &&
        <LoadingView classNames='mt-5' />
      }
      {
        state.isLoading === false &&
        state.currentFileDisplayed &&
        <DocumentViewer
          config={{ header: { disableHeader: true } }}
          language={i18n.language as 'fr' | 'en'}
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: window.URL.createObjectURL(state.currentFileDisplayed) }]}
          theme={{
            secondary: '#ffffff',
            tertiary: '#1d6257',
            textPrimary: '#ffffff',
            textSecondary: '#5296d8',
            textTertiary: '#00000099',
            disableThemeScrollbar: false
          }} />
      }
    </Stack>
  );

};

export default memo(FileViewer);
