export enum EHttpRequestName {

  // -> '/project'
  UpdateMandrillParameterOfProject = 'put:/projects/{projectId}/settings/mandrill',
  GetProjectTranslationFile = 'get:/projects/{projectId}/translations/{fileId}',
  UpdateProjectTranslationFile = 'put:/projects/{projectId}/translations/files',
  GetMandrillParameterOfProject = 'get:/projects/{projectId}/settings/mandrill',
  GetProjectMembersRoles = 'get:/projects/{projectId}/members:roles',
  DeleteProjectMembersRoles = 'delete:/projects/{projectId}/members',
  GetProjectTranslations = 'get:/projects/{projectId}/translations',
  GetProjectMembers = 'get:/projects/{projectId}/members',
  AddProjectMembers = 'post:/project/{projectId}/members',
  GetProjects = 'get:/projects',

  // -> '/devops'
  GetDevopsSprintsOnProject = 'get:/devops/projects/{projectsId}/sprints',
  GetDevopsWorkItem = 'get:/devops/workItem/{workItemId}',
  CreateDevopsWorkItem = 'post:/devops/workItem',

  // -> '/tickets'
  CompleteDevelopementOnTicket = 'put:/tickets/{ticketId}/complete-developement',
  RequestmoreInfoOnTicket = 'put:/tickets/{ticketId}/request-more-info',
  PustTicketOnProduction = 'put:/tickets/{ticketId}/push-on-production',
  DeleteTicketAttachments = 'delete:/tickets/{ticketId}/attachments',
  RemoveTicketWorkItem = 'put:/tickets/{ticketId}/remove-workitem',
  PustTicketOnStaging = 'put:/tickets/{ticketId}/push-on-staging',
  LinkTicketToDevops = 'put:/tickets/{ticketId}/link-to-devops',
  AddTicketAttachment = 'post:/tickets/{ticketId}/attachments',
  GetTicketAttachments = 'get:/tickets/{ticketId}/attachments',
  RejectTestOnTicket = 'put:/tickets/{ticketId}/reject-test',
  ValidateTicket = 'put:/tickets/{ticketId}/validate',
  ApproveTicket = 'put:/tickets/{ticketId}/approve',
  RejectTicket = 'put:/tickets/{ticketId}/reject',
  CloseTicket = 'put:/tickets/{ticketId}/close',
  StartTicket = 'put:/tickets/{ticketId}/start',
  OpenTicket = 'put:/tickets/{ticketId}/open',
  DeleteTicket = 'delete:/tickets/{ticketId}',
  UpdateTicket = 'put:/tickets/{ticketId}',
  GetTickets = 'get:/tickets',

  // '/tickets/{ticketId}/comments'
  DeleteCommentFromTicket = 'delete:/tickets/{ticketId}/comments/{ticketCommentId}',
  UpdateCommentFromTicket = 'put:/tickets/{ticketId}/comments/{ticketCommentId}',
  AddCommentOnTicket = 'post:/tickets/{ticketId}/comments',
  GetCommentsOnTicket = 'get:/tickets/{ticketId}/comments',

  // '/tickets/{ticketId}/events'
  GetEventsOnTicket = 'get:/tickets/{ticketId}/events',

  GetDevopsBranchesOfRepositoryOnProject = 'get:/devops/projects/{projectId}/repositories/{repositoryName}/branches',
  CreateTranslationFolderOnProject = 'post:/xchange/projects/{projectId}/translations/translationFolder',
  GetDevopsRepositoriesOnProject = 'get:/devops/projects/{projectId}/repositories',
  GetProjectTranslationsOfEmail = 'get:/projects/{projectId}/translations/email',
  GetDevopsProjects = 'get:/devops/projects?OnlyNotInXChange={onlyNotInXChange}',

  DownloadTicketsToExcelFormat = 'get:/tickets/{id}/download-excel-format',
  GetNomenclatures = 'get:/nomenclatures/{type}',

  AddMandrillKeyToProject = 'AddMandrillKeyToProject',

  // `release-notes`
  GetReleaseNotes = 'get:/release-notes',

  //#region /users,
  SetCurrentUserParameters = 'set:/users/current/parameters',
  GetCurrentUserParameters = 'get:/users/current/parameters',
  UpdateUserLang = 'put:/users/current/lang',
  SyncCurrentUser = 'put:/users/current',
  GetCurrentUser = 'get:/users/current',
  UpdateUser = 'put:/users/{userId}',
  GetUsers = 'get:/users',

  //#region /notifications
  GetUserNotificationsUnreadCount = 'get:/users/{userId}/notifications/unread/count',
  UserDeleteNotifications = 'delete:/users/{userId}/notifications/delete',
  UserReadNotifications = 'put:/users/{userId}/notifications/read',
  GetNotificationsOfUser = 'get:/users/{userId}/notifications',
  //#endregion
  //#endregion
  //#region consultancy-profiles
  GetConsultancyProfiles = 'get:/consultancy-profiles',
  DownloadConsultancyProfile = 'get:/consultancy-profiles/{consultantId}',

  DeleteConsultantAcademicBackground = 'delete:/consultancy-profiles/{consultantId}/academic-background/{consultantprofessionalExperienceId}',
  UpdateConsultantAcademicBackground = 'put:/consultancy-profiles/{consultantId}/academic-background/{consultantAcademicBackgroundId}',
  CreateConsultantAcademicBackground = 'post:/consultancy-profiles/{consultantId}/academic-background',
  GetConsultantAcademicBackground = 'get:/consultancy-profiles/{consultantId}/academic-background',

  GetConsultantExperiences = 'get:/consultancy-profiles/{consultantId}/experiences',
  GetConsultantSkills = 'get:/consultancy-profiles/{consultantId}/skills',
  CreateConsultantExperience = 'post:/consultancy-profiles/{consultantId}/experiences',

  AddConsultantLanguages = 'put:/consultancy-profiles/{consultantId}/languages',
  DeleteConsultantUserSharedWith = 'put:/consultancy-profiles/{consultantId}/languages/{codeISO2Language}',

  AddConsultantUserSharedWith = 'put:/consultancy-profiles/{consultantId}/sharing',
  DeleteConsultantLanguage = 'put:/consultancy-profiles/{consultantId}/sharing/{userId}',

  UpdateConsultantExperience = 'put:/consultancy-profiles/{consultantId}/experiences/{consultantprofessionalExperienceId}',
  DeleteConsultantExperience = 'delete:/consultancy-profiles/{consultantId}/experiences/{consultantprofessionalExperienceId}',
  //#endregion
  CreateTransactionOnSkills = 'put:/skills',
  UpdateProjectTranslationsOfEmailFIle = 'UpdateProjectTranslationsOfEmailFIle'
}
