import { LoadingScreen, Alert, Breadcrumb, MessageBox, FormPanel } from 'components';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack } from '@fluentui/react';
import { appStore } from 'store';
import Header from 'layout/header';
import Footer from 'layout/footer';
import AppRoutes from 'layout/routes';
import Menu from 'layout/menu';
import { memo } from 'react';
import './Layout.scss';

const Layout = () => {

  const _isLoggingOut = useSelector(appStore.userConnected.get.isLoggingOut);
  const _isLoggingIn = useSelector(appStore.userConnected.get.isLoggingIn);
  const { t } = useTranslation();

  if (_isLoggingOut) {
    return <LoadingScreen description={`${t('common.disconnecting')}...`} />;
  }

  if (_isLoggingIn) {
    return <LoadingScreen description={`${t('common.authentication')}...`} />;
  }

  return (
    <BrowserRouter>
      <Stack className='app-stack ms-fadeIn500' verticalFill>
        <Stack.Item>
          <Header />
        </Stack.Item>
        <Stack className='app-stackBody' grow horizontal horizontalAlign='stretch'>
          <Menu />
          <Stack className='app-stackMain' grow verticalFill>
            <Stack.Item className='app-stackMainHeader px-4 pt-1'>
              <Alert />
              <MessageBox />
              <Breadcrumb />
              <FormPanel />
            </Stack.Item>
            <Stack.Item className='px-4 pt-3' grow>
              <AppRoutes />
            </Stack.Item>
            <Stack.Item className='app-stackFooter'>
              <Footer />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </BrowserRouter>
  );

};

export default memo(Layout);
