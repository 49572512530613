import { ColorPicker, IColor, Stack } from '@fluentui/react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { memo, useState } from 'react';

interface IProps {
  setValue: UseFormSetValue<IState>;
  control: Control<any>;
}

interface IState {
  color: IColor;
}

const ColorPickerForm = ({ control, setValue }: IProps) => {

  const [state, setState] = useState<IState>({ color: control._formValues.color });

  const handleOnChangeColor = (e: any, color: IColor) => {
    setValue('color', color);
    setState(l => ({ ...l, color }));
  };


  return (
    <Stack>
      <ColorPicker
        showPreview
        color={state.color}
        alphaType={'transparency'}
        onChange={handleOnChangeColor} />
    </Stack>
  );

};

export default memo(ColorPickerForm);
