import { useNomenclatureData } from 'hooks';
import { useSelector } from 'react-redux';
import { ENomenclatureType } from 'enums';
import store from './store';

export const useCreateUpdateConsultantExperienceFormDataSkillsFiltered = () => {

  const { data: dataSkills } = useNomenclatureData<string>(ENomenclatureType.Skill, true);
  const _skillMandays = useSelector(store.get.skillMandays);
  const _formSkill = useSelector(store.get.formSkill);

  const dataSkillsFiltered = (
    dataSkills
      .filter(l => l.data)
      .filter(l => l.title.trim().toLowerCase().includes(_formSkill.value.trim().toLowerCase()))
      .filter(l => !_skillMandays.some(skillManday => skillManday.skillId === l.id))
      .slice(0, 10)
  );

  return ({
    data: dataSkillsFiltered
  });

};
