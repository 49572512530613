import { TNullable } from 'types';
import { dayjs } from 'config';

const getBusinessDatesCount = (fromDate: Date, toDate?: TNullable<Date>) => {
  const toDateJs = dayjs(toDate ?? new Date());
  let fromDateJs = dayjs(fromDate);
  
  const years = dayjs.duration(toDateJs.diff(fromDateJs)).years();
  const months = dayjs.duration(toDateJs.diff(fromDateJs)).months();
  const days = dayjs.duration(toDateJs.diff(fromDateJs)).days();
  const totalDaysMonth = toDateJs.daysInMonth();

  let result = 0;

  // Add years
  result += (years * 220);
  // Add months
  result += (months * 18);
  // Add days
  result += ((days * 18) / totalDaysMonth);

  return result;
};

export default getBusinessDatesCount;
