import { IHttpRequestServiceOptions, IUserProjectAssignated } from 'interfaces';
import { EHttpRequestName, EProjectRole } from 'enums';
import { httpRequestService } from 'services';

const baseUrl = '/projects';

const members = {

  remove: (projectId: string, userIds: string[], params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${projectId}/members`,
        {
          ...params,
          httpRequestName: EHttpRequestName.DeleteProjectMembersRoles
        },
        {
          method: 'DELETE',
          data: { userIds }
        }
      )
      .then(response => response.data),

  getMembersRoles: (projectId: string, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<{ userId: string; role: EProjectRole; }[]>(
        `${baseUrl}/${projectId}/members/roles`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjectMembersRoles
        }
      )
      .then(response => response.data),

  get: (projectId: string, query: { includePhoto?: boolean; } = {}, params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IUserProjectAssignated[]>(
        `${baseUrl}/${projectId}/members`,
        {
          ...params,
          httpRequestName: EHttpRequestName.GetProjectMembers
        },
        { params: query }
      )
      .then(response => response.data)
      .then(data => data.map(l => ({ ...l, photo: l.photo?.toBase64() }))),

  changeMemberRole: (
    projectId: string,
    userId: string,
    role: EProjectRole,
    params: IHttpRequestServiceOptions = {}
  ) =>
    httpRequestService
      .request<void>(
        `${baseUrl}/${projectId}/members/${userId}`,
        params, {
        method: 'PUT',
        data: { role }
      })
      .then(response => response.data),

  add: (projectId: string, userIds: string[], params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<{ userId: string; role: EProjectRole; }[]>(
        `${baseUrl}/${projectId}/members`,
        {
          ...params,
          httpRequestName: EHttpRequestName.AddProjectMembers
        },
        {
          method: 'POST',
          data: { idUsers: userIds }
        }
      )
      .then(response => response.data)

};

export default members;
