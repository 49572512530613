import { DefaultButton, Panel, PanelType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { IFormPanelOptions, IHasIsLoading } from 'interfaces';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormPanelAlert from './FormPanelAlert';
import { formPanelService } from 'services';

const VARIABLES = {
  initState: {
    options: {
      confirmActionCallBack: () => Promise.resolve(),
      confirmActionText: '',
      cancelActionText: '',
      content: <></>,
      title: ''
    },
    isLoading: false,
    isOpen: false
  } as IState
};

interface IState extends IHasIsLoading {
  options: IFormPanelOptions;
  isOpen: boolean;
  error?: string | JSX.Element;
}

const FormPanel = () => {

  const [state, setState] = useState<IState>(VARIABLES.initState);
  const { t } = useTranslation();

  // Behavior when component is mounted and unmounted
  useEffect(
    () => {
      // Subscription to alert service
      const subscription = formPanelService.getOptions().subscribe(options => {
        // Update state
        setState(l => ({
          ...l,
          isLoading: false,
          isOpen: true,
          options: {
            ...l.options,
            confirmActionCallBack: options.confirmActionCallBack ?? (() => Promise.resolve()),
            cancelActionText: options.cancelActionText ?? t('common.cancel'),
            confirmActionText: options.confirmActionText ?? t('common.save'),
            showConfirmButton: options.showConfirmButton ?? true,
            showCancelButton: options.showCancelButton ?? true,
            type: options.type ?? PanelType.medium,
            customWidth: options.customWidth,
            content: options.content,
            title: options.title
          }
        }));
      });
      // When component is unmounted
      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  /** Handler of event 'onDismiss'. */
  const handleOnDismiss = (_?: any) => setState(VARIABLES.initState);

  /** Handler of event 'onClick' on button 'Save change'. */
  const handleOnClickSave = (_: any) => {
    // Update state
    setState(l => ({ ...l, isLoading: true }));
    // Perform callback function
    state.options
      ?.confirmActionCallBack?.()
      .then(handleOnDismiss)
      .catch(error => setState(l => ({ ...l, error })))
      .finally(() => setState(l => ({ ...l, isLoading: false })));
  };

  /** Handler of event 'onClick' on button 'Cancel'. */
  const handleOnClickCancel = (_: any) => {
    // Perform callback function
    state.options?.cancelActionCallBack?.();
    // Perform dismiss function
    handleOnDismiss();
  };

  /** Component used to display footer of panel. */
  const handleOnRenderFooterContent = () => (
    <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
      {
        state.options.showConfirmButton &&
        <DefaultButton primary disabled={state.isLoading} onClick={handleOnClickSave}>
          {state.options.confirmActionText}
          {state.isLoading && <Spinner className='ms-2' size={SpinnerSize.small} />}
        </DefaultButton>
      }
      {
        state.options.showCancelButton &&
        <DefaultButton onClick={handleOnClickCancel} disabled={state.isLoading}>
          {state.options.cancelActionText}
        </DefaultButton>
      }
    </Stack>
  );

  const handleOnCloseAlert = () => setState(l => ({ ...l, error: undefined }));

  return (
    <Panel
      onRenderFooterContent={handleOnRenderFooterContent}
      closeButtonAriaLabel={t('common.close')}
      customWidth={
        [PanelType.custom, PanelType.customNear].includes(state.options.type as any)
          ? state.options.customWidth
          : undefined
      }
      hasCloseButton={!state.isLoading}
      isLightDismiss={!state.isLoading}
      headerText={state.options.title}
      onDismiss={handleOnDismiss}
      type={state.options.type}
      isFooterAtBottom={true}
      isOpen={state.isOpen}>
      <FormPanelAlert error={state.error} onClose={handleOnCloseAlert} />
      {state.options.content}
    </Panel>
  );
};

export default memo(FormPanel);
