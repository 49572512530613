import { CreateUpdateConsultantExperienceForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import { IConsultantExperience } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (data: IConsultantExperience) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IConsultantExperience>
};

const useCreateUpdateConsultantExperienceForm = () => {

  const { handleSubmit, control, reset, watch, setValue } = useForm<IConsultantExperience>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerFormCreate = (consultantId: string, callback?: ICallback) => {
    reset();
    formPanelService
      .publish({
        content: <CreateUpdateConsultantExperienceForm control={control as any} watch={watch} setValue={setValue} />,
        title: t('common.createProfessionalExperience'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.large,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .experiences
                .create(consultantId, data, { showErrorMessage: false })
                .then(consultantExperienceId => callback?.onSuccess?.({ ...data, id: consultantExperienceId }))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  const triggerFormUpdate = (consultantId: string, experience: IConsultantExperience, callback?: ICallback) => {    
    reset(experience);
    formPanelService
      .publish({
        content: <CreateUpdateConsultantExperienceForm control={control as any} watch={watch} setValue={setValue} />,
        title: t('common.updateProfessionalExperience'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.large,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .experiences
                .update(consultantId, data.id, data, { showErrorMessage: false })
                .then(() => callback?.onSuccess?.(data))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerFormCreate, triggerFormUpdate };

};

export default useCreateUpdateConsultantExperienceForm;
