import { httpRequestService } from 'services';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { appStore } from 'store';
import { filter } from 'rxjs';

const useListenBroadcastHttpStatuses = () => {

  const dispatch = useDispatch();

  useEffect(
    () => {
      const subscription = (
        httpRequestService
          .getStatus()
          .pipe(filter(command => ![undefined, null].includes(command.requestId as any)))
          .subscribe(command => appStore.httpRequestStatus.set.set(dispatch, command))
      );

      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line
    []
  );
  
};
 
export default useListenBroadcastHttpStatuses;
