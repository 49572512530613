export enum ETicketState {
  Draft = 0,
  PendingApproval = 1,
  Approved = 2,
  OnDevops = 3,
  InProgress = 4,
  Closed = 5,
  Rejected = 6,
  PendingDeployment = 7,
  OnStaging = 8,
  StagingValidated = 9,
  OnProduction = 10,
  Archived = 11
}
