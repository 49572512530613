import TIME_TRACKING from './timeTracking';
import CONSULTING from './consulting';
import DASHBOARD from './dashboard';
import SETTINGS from './settings';
import PROJECTS from './projects';
import { TRoute } from 'types';
import ERRORS from './errors';
import { lazy } from 'react';
import ADMIN from './admin';

/** Object that contains routes used in application. */
const ROUTES = Object.freeze({
  module: null,
  id: '/',
  /** Default base url of application. */
  base: '/',
  /** Path of route. */
  path: () => ROUTES.base,
  relativePath: () => "/",
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeLandingPage'*/ 'modules/home')),
  /** Description of route. */
  description: (t: Function): string => t('routes.home'),
  /** Route that redirect to not found component. */
  NOT_FOUND: {
    id: '/not-found',
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeNotFound'*/ 'modules/notFound'))
  },
  TIME_TRACKING,
  CONSULTING,
  /** Route that redirect to dashboard in application. */
  DASHBOARD,
  /** Route that redirect to projects in application. */
  PROJECTS,
  /** Route that redirect to module settings in application. */
  SETTINGS,
  /** Route that redirect to application error page. */
  ERRORS,
  /** Route that redirect to module settings in application. */
  ADMIN
}) as unknown as TRoute;

export default ROUTES;
