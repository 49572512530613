import { INotification } from 'interfaces';
import { Text } from '@fluentui/react';
import { memo } from 'react';

interface IProps {
  notification: INotification;
}

const NotificationsFormItemTitle = ({ notification }: IProps) => {

  const className = notification.read
    ? ''
    : 'ms-fontWeight-semibold text-green';

  return (
    <Text className={className} variant='medium'>
      {notification.title}
    </Text>
  );

};

export default memo(NotificationsFormItemTitle);
