import { Dispatch } from '@reduxjs/toolkit';
import { INomenclature } from 'interfaces';
import { ENomenclatureType } from 'enums';
import { appStore } from 'store';

export interface IUseNomenclatureDataStore extends Record<ENomenclatureType, INomenclature<any, any>[]> {
}

const VARIABLES = {
  key: 'useNomenclatureData',
  initStore: {
  } as IUseNomenclatureDataStore
};

const set = {
  set: (dispatch: Dispatch, setter: (state: IUseNomenclatureDataStore) => IUseNomenclatureDataStore) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  store: appStore.record.get.get(VARIABLES.key, (record: IUseNomenclatureDataStore) => record)
};

const store = {
  set,
  get
};

export default store;
