import imgWorkInProgress from 'assets/images/png/work-in-progress-512x512.png';
import { Stack, Text } from '@fluentui/react';
import './WorkInProgress.scss';
import { memo } from 'react';

interface IProps {
  title?: string;
  className?: string;
  hideImage?: boolean;
  description?: string;
  children?: JSX.Element;
}

const WorkInProgress = ({ description, className, title = 'Work in progress', hideImage = false, children = <></> }: IProps) => (
  <Stack className={`app-workInProgress ms-fadeIn500 ${className}`} verticalAlign='center' verticalFill>
    <Stack>
      {
        hideImage === false &&
        <Stack.Item align='center'>
          <img src={imgWorkInProgress} alt='Work in progress' />
        </Stack.Item>
      }
      <Stack.Item align='center'>
        <Stack verticalAlign='center' horizontalAlign='center'>
          <Text variant='xLarge'>
            {title}
          </Text>
          <Text variant='large'>{description}</Text>
          {children}
        </Stack>
      </Stack.Item>
    </Stack>
  </Stack>
);

export default memo(WorkInProgress);
