import { Route, RouteProps, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Guard } from 'components';
import { EUserRole } from 'enums';
import { appStore } from 'store';
import { memo } from 'react';

type Props = {
  userRoles: EUserRole[];
  component: any;
}

const RouteGuard = ({ component, userRoles: userRole }: Props) => {

  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const _idUserConnected = useSelector(appStore.userConnected.get.id);

  if (!_idUserConnected) {
    return <></>;
  }

  if (!_userConnected.roles.some(l => userRole.includes(l))) {
      return <Guard rule={'none'} />;
  }

  return component;

};

export default memo(RouteGuard);
