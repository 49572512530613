import { ENVIRONMENT_VARIABLES } from 'constant';
import HelloSign from 'hellosign-embedded';

const openHelloSignEmbedded = (signUrl: string, thenCallback?: (data: any) => void, catchCallback?: (data?: any) => void) => {

  const clientId = ENVIRONMENT_VARIABLES.helloSignClientId;
  const testMode = !ENVIRONMENT_VARIABLES.isProd;

  const client = new HelloSign({
    clientId: clientId
  });

  const option = {
    skipDomainVerification: testMode,
    testMode: testMode
  } as HelloSign.HelloSign.Options;

  client.open(signUrl, option);

  if (thenCallback !== undefined) {
    client.once('sign', thenCallback);
  }
  if (catchCallback !== undefined) {
    client.once('cancel', catchCallback);
    client.once('error', catchCallback);
  }

};

export default openHelloSignEmbedded;
