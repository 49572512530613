import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.duration({ months: 12 });

export default dayjs;
