import { ELang, ETicketCriticality, ETicketState, ETicketType } from 'enums';
import { useTranslation } from 'react-i18next';

type TParams = keyof typeof ETicketState | keyof typeof ETicketCriticality | keyof typeof ETicketType | keyof typeof ELang;


const useEnumTitle = (value?: TParams) => {

  const { t } = useTranslation();

  return ({
    getTitle: (value: TParams) => t(getTranslationKey(value)),
    getTranslationKey,
    title: value
      ? t(getTranslationKey(value))
      : null
  });

};

const getTranslationKey = (value: TParams) => {

  let key = 'enum.';

  switch (value) {

    case 'PendingDeployment':
    case 'StagingValidated':
    case 'PendingApproval':
    case 'OnProduction':
    case 'InProgress':
    case 'OnStaging':
    case 'Rejected':
    case 'Approved':
    case 'Archived':
    case 'OnDevops':
    case 'Closed':
    case 'Draft':

      key += `ticketState.${ETicketState[value]}`;

      break;

    case 'task':
    case 'bug':

      key += `ticketType.${ETicketType[value]}`;

      break;

    case 'critical':
    case 'minor':
    case 'major':

      key += `ticketCriticality.${ETicketCriticality[value]}`;

      break;

    case 'en':
    case 'fr':
    case 'de':

      key += `language.${ELang[value]}`;

      break;

    default:
      break;

  }

  return key;

};

export default useEnumTitle;
