import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpRequestService } from 'services';
import { QUERY_KEYS } from 'constant';

export const useHttpRequestGetTimeTrackingProjects = () => {

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: [QUERY_KEYS.getTimeTrackingProjects],
    queryFn: () => httpRequestService.timeEntries.projects.get()
  });

  const refreshData = () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingProjects] });

  return ({
    data: data ?? [],
    refreshData,
    isLoading
  });

};
