import { CommandBarButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { changeLanguage } from 'config/i18n';
import { EIconName, ELang } from 'enums';
import { memo } from 'react';

const VARIABLES = {
  iconProps: {
    iconName: EIconName.LocaleLanguage
  }
};

const HeaderItemsLanguage = () => {

  const { t } = useTranslation();

  const onChangeLanguageHandler = (lang: ELang) => () => {
    changeLanguage(lang);

    if (httpRequestService.azureAD.getCurrentAccount() !== undefined) {
      httpRequestService.users.current.changeLang(lang);
    }
  };

  const languages = [
    { value: ELang.en, label: t('common.english') },
    { value: ELang.fr, label: t('common.french') }
  ] as { value: ELang, label: string }[];

  return (
    <CommandBarButton
      title={t('header.changeLanguage')}
      iconProps={VARIABLES.iconProps}
      className='px-1'
      menuProps={{
        items: languages.map(l => ({
          key: l.value,
          text: l.label,
          onClick: onChangeLanguageHandler(l.value)
        }))
      }} />
  );

};

export default memo(HeaderItemsLanguage);
