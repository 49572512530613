import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import PushTicketOnDevopsForm from './PushTicketOnDevopsForm';
import { IPushTicketOnDevopsFormData } from 'interfaces';

export default PushTicketOnDevopsForm;

export interface IProps {
  setValue: UseFormSetValue<IPushTicketOnDevopsFormData>;
  watch: UseFormWatch<IPushTicketOnDevopsFormData>;
  control: Control<any>;
}
