import { HTMLAttributes, memo } from 'react';
import './Container.scss';

interface IProps extends HTMLAttributes<any>, Record<string, any> {
  className?: string;
  minHeight?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
}

const Container = ({ children, minHeight, maxWidth, minWidth, className = '', ...props }: IProps) => (
  <div
    {...props}
    className={`app-Container box-shadow rounded ${!className.includes('my-') ? 'my-3' : ''} ${!className.includes('py-') ? 'py-3' : ''} ${className}`}
    style={{
      ...props.style,
      minHeight,
      maxWidth,
      minWidth
    }}>
    {children}
  </div>
);

export default memo(Container);
