import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpRequestService } from 'services';
import { INomenclature } from 'interfaces';
import { ENomenclatureType } from 'enums';
import { QUERY_KEYS } from 'constant';

interface IOptions<TId, TData = any, TSelect = INomenclature<TId, TData>[]> {
  select?: (data: INomenclature<TId, TData>[]) => TSelect;
}

const VARIABLES = {
  queryKeyId: QUERY_KEYS.getNomenclatures
};

const useHttpRequestGetNomenclatures = <TId, TData = any, TSelect = INomenclature<TId, TData>[]>(type: ENomenclatureType, options?: IOptions<TId, TData, TSelect>) => {

  const queryClient = useQueryClient();

  const { isLoading, data, dataUpdatedAt } = useQuery({
    queryKey: [VARIABLES.queryKeyId, type],
    queryFn: ({ queryKey: [_, _type] }) => (
      _type
        ? httpRequestService.nomenclatures.get<TId, TData>(_type as ENomenclatureType)
        : Promise.resolve([])
    ),
    select: options?.select
  });

  const refreshData = () =>
    queryClient.invalidateQueries({ queryKey: [VARIABLES.queryKeyId, type] });

  return ({
    isLoading,
    refreshData,
    dataUpdatedAt,
    data: data ?? []
  });

};

export default useHttpRequestGetNomenclatures;
