import { IHasDescriptionOptional, IHasName } from 'interfaces';
import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'modules/time-tracking/pages/projects/store';

type TDataVariables = IHasName & IHasDescriptionOptional;

export const useHttpRequestCreateTimeTrackingProject = () => {
  const queryClient = useQueryClient();
  const _gridApi = useSelector(store.get.gridApi);
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (data: TDataVariables) => (
      httpRequestService
        .request<string>(
          `/time-entries/projects`,
          { showErrorMessage: false },
          { method: 'POST', data }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries( { queryKey: [QUERY_KEYS.getTimeTrackingProjects]} );
    }
  });

  return (mutation);

};
