import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EIconName, EUserRole } from 'enums';
import { INavLink } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constant';
import { appStore } from 'store';

const VARIABLES = {
  route: ROUTES.SETTINGS,
  defaultNavLink: ({
    url: '',
    iconProps: ({
      className: 'mx-2',
      style: {
        fontSize: 'large'
      }
    })
  }) as INavLink
};

const useModuleSettings = () => {

  const _hasRolesAdminUserConnected = useSelector(appStore.userConnected.get.asRoles(EUserRole.Admin, EUserRole.Tester));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuLinks = _hasRolesAdminUserConnected
    ? [
      {
        ...VARIABLES.defaultNavLink,
        name: VARIABLES.route.PROJECTS.description(t),
        key: VARIABLES.route.PROJECTS.id,
        onClick: () => navigate(VARIABLES.route.PROJECTS.path()),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.ProjectLogo16
        }),
        routeIds: [VARIABLES.route.PROJECTS.id]
      },
      {
        ...VARIABLES.defaultNavLink,
        name: VARIABLES.route.USERS.description(t),
        key: VARIABLES.route.USERS.id,
        onClick: () => navigate(VARIABLES.route.USERS.path()),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.Group
        }),
        routeIds: [VARIABLES.route.USERS.id]
      }
    ]
    : [];

  return ({
    menuLinks
  });

};

export default useModuleSettings;
