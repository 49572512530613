import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { memo, MutableRefObject } from 'react';
import './AgGrid.scss';

/** Interface of state */
interface IState extends AgGridReactProps {
  gridRef?: MutableRefObject<any>;
}

/** Global variables */
const VARIABLES = {
  rowHeight: 40,
  defaultColDef: {
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    sortable: true
  } as ColDef,
  /**
   * Get height of data table according to the number of elements.
   * @param length Number of elements.
   */
  getHeigthDataTable: (length: number) => {
    // Check to apply default value
    if (!length || length < 10) {
      length = 10;
    }
    // Return height of data table
    return length > 15 ? VARIABLES.rowHeight * 16 : (length + 1) * VARIABLES.rowHeight;
  }
};

/**
 * Component which wraps 'AgGridReact' component while adding auto heigth and theme 'ag-theme-alpine'.
 * @param props Properties of component.
 * @returns Component
 */
const AgGrid = (props: IState) => {

  /** Handler of event 'onGridReady' of ag-grid. */
  const handleOnGridReady = (event: GridReadyEvent) => {
    // Apply parent event
    props.onGridReady?.(event);
  };

  return (
    <div className='app-agGrid' style={{ height: `${VARIABLES.getHeigthDataTable(props.rowData?.length ?? 0)}px` }}>
      <AgGridReact
        {...{
          ...props,
          defaultColDef: Object.assign({}, VARIABLES.defaultColDef, props.defaultColDef),
          suppressMenuHide: props.suppressMenuHide ?? true,
          className: `ag-theme-alpine ${props.className}`,
          rowSelection: props.rowSelection ?? 'single',
          headerHeight: VARIABLES.rowHeight - 3,
          rowHeight: VARIABLES.rowHeight,
          onGridReady: handleOnGridReady,
          reactiveCustomComponents: true,
          paginationPageSizeSelector: false
        }}
      />
    </div>
  );
  
};

export default memo(AgGrid);
