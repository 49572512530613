import { ControlledDatePicker, ControlledTextField } from 'components';
import { Stack, StackItem, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { IConsultant } from 'interfaces';
import { nameof } from 'functions';
import { memo } from 'react';

interface IProps {
  control: Control<any>;
};

const UpdateConsultancyContactInfoForm = ({ control }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 4 }}>
      <Stack horizontal={false} tokens={{ childrenGap: 4 }}>
        <Text className='ms-fontWeight-semibold'>
          {t('common.phone')}
        </Text>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <ControlledTextField
            style={{ width: 100 }}
            placeholder='Indicatif'
            control={control}
            name={nameof<IConsultant>('phoneNumberIndicatif')} />
          <StackItem grow>
            <ControlledTextField
              placeholder={t('common.phone')}
              type='number'
              control={control}
              name={nameof<IConsultant>('phoneNumber')} />
          </StackItem>
        </Stack>
      </Stack>
      <ControlledTextField
        control={control}
        label={t('common.email')}
        name={nameof<IConsultant>('email')} />
      <ControlledDatePicker
        control={control}
        maxDate={new Date()}
        label={t('common.birthDate')}
        name={nameof<IConsultant>('birthDate')} />
      <ControlledTextField
        multiline
        autoAdjustHeight
        rows={3}
        control={control}
        label={t('common.address')}
        name={nameof<IConsultant>('address')} />
    </Stack>
  );

};

export default memo(UpdateConsultancyContactInfoForm);
