import { useNomenclatureData, useUserConnected } from 'hooks';
import { Separator, Stack, Text } from '@fluentui/react';
import { ISkillManDays } from 'interfaces';
import { ENomenclatureType } from 'enums';
import { Skill } from 'components';
import { memo } from 'react';

interface IProps {
  skillParentId: string;
  allowDeletion?: boolean;
  skillMandays: ISkillManDays[];
  onDelete?: (value: string | JSX.Element) => Promise<void>;
}

const ProfileSectionExperiencesItemParent = ({ skillMandays, skillParentId, onDelete, allowDeletion = false }: IProps) => {

  const { getTitle: getTitleSkill } = useNomenclatureData<string>(ENomenclatureType.Skill);
  const _userConnected = useUserConnected();

  return (
    <Stack>
      <Separator alignContent='start'>
        <Text variant='small' className='ms-fontWeight-semibold italic'>
          {`${getTitleSkill(skillParentId)} (${skillMandays.reduce((sum, current) => sum + current.manDays, 0)})`}
        </Text>
      </Separator>
      <Stack horizontal wrap tokens={{ childrenGap: 8 }}>
        {
          skillMandays
            .map(
              l => (
                <Skill
                  variant='tiny'
                  key={l.skillId}
                  value={l.skillId}
                  onDelete={onDelete}
                  allowDeletion={allowDeletion}
                  bgColor={_userConnected.bbcvColor ?? 'white'}
                  onRender={skillId => `${getTitleSkill(skillId)} (${l.manDays})`} />
              )
            )
        }
      </Stack>
    </Stack>
  );

};

export default memo(ProfileSectionExperiencesItemParent);
