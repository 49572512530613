import { createSelector, createSlice, Dispatch } from '@reduxjs/toolkit';
import { IStore } from 'store';

interface IPayload {
  key: string;
  setter: (state: any) => any
}

const recordSlice = createSlice({
  name: 'record',
  reducers: {
    set: (
      state: any,
      action: {
        type: string,
        payload: IPayload
      }
    ) => {
      // Get current value from field
      const currentValue = state[action.payload.key];
      // Update value from field
      state[action.payload.key] = action.payload.setter(currentValue);
      return state;
    },
    clear: (
      state: any,
      action: {
        type: string,
        payload: string
      }
    ) => {
      // Delete field
      delete state[action.payload];
      return state;
    }
  },
  initialState: {} as Record<string, any>
});

export default recordSlice;

const set = {
  set: (dispatch: Dispatch, payload: IPayload) => (
    dispatch({
      type: `${recordSlice.name}/${set.set.name}`,
      payload
    })
  ),
  clear: (dispatch: Dispatch, key: string) => (
    dispatch({
      type: `${recordSlice.name}/${set.clear.name}`,
      payload: key
    })
  )
};

const get = {
  get: <TData = any>(
    key: string,
    valueSelector: (record: any) => TData = record => record
  ) => createSelector(
    (state: IStore) => state.record[key],
    valueSelector
  )
};

export const recordStore = {
  set,
  get
};

