import { IConsultant, IUpdateConsultancyProfileFormData } from 'interfaces';
import { CreateUpdateConsultancyProfileForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import useUserConnected from './useUserConnected';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import { useEffect } from 'react';
import { nameof } from 'functions';

interface ICallback {
  onError?: (error: any) => void;
  onSuccess?: (data: IUpdateConsultancyProfileFormData) => void;
}

const VARIABLES = {
  formConfig: {
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IUpdateConsultancyProfileFormData>
};

const useCreateConsultancyProfileForm = () => {

  const { handleSubmit, control, reset, setValue, watch } = useForm<IUpdateConsultancyProfileFormData>(VARIABLES.formConfig);
  const _userConnected = useUserConnected();
  const { t } = useTranslation();

  useEffect(
    () => {
      const subscriptionTicketType = watch(
        (data, { name }) => {
          name === nameof<IUpdateConsultancyProfileFormData>('photo') &&
            setValue('photoUpdated', true);
        }
      );
      return () => subscriptionTicketType.unsubscribe();
    },
    // eslint-disable-next-line
    []
  );

  const triggerForm = async (consultant: IConsultant, callback?: ICallback) => {
    reset({
      nationalityCountryCodeIso2: consultant.nationalityCountryCodeIso2,
      description: consultant.description,
      firstName: consultant.firstName,
      photo: consultant.photo as any,
      lastName: consultant.lastName,
      gender: consultant.gender,
      title: consultant.title,
      photoUpdated: false
    });

    formPanelService
      .publish({
        content: <CreateUpdateConsultancyProfileForm control={control as any} setValue={setValue} />,
        title: t('common.updateProfile'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .updateProfile(_userConnected.id, consultant.id, data)
                .then(() => callback?.onSuccess?.(data))
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useCreateConsultancyProfileForm;
