import dayjs from 'dayjs';

declare global {

  interface Date {
    /**
     * Convert date to string.
     * @param format Format to apply on date.
     */
    toFormat(format?: string): string;
  }
  
}

/**
 * Convert date to string.
 * @param format Format to apply on date.
 */
// eslint-disable-next-line
Date.prototype.toFormat = (format = 'DD/MM/YYYY') => dayjs(this).format(format);
