import { IHttpRequestError } from 'interfaces';
import { EMPTY } from 'constant';

const getErrorDescription = (error: IHttpRequestError): string[] => {
  // Update error
  error.Errors = error.Errors ?? error.errors ?? undefined;
  error.Detail = error.Detail ?? error.detail ?? EMPTY;
  error.Title = error.Title ?? error.title ?? EMPTY;
  error.Status = error.Status ?? error.status;
  error.Type = error.Type ?? error.type;

  // Get list of errors
  const errors = Object.keys(error.Errors ?? {}).map(key => `${key}: ${error.Errors?.[key].join(', ')}`);

  if (errors.some(_ => true)) {
    return errors;
  }

  return [error.Title];
};

export default getErrorDescription;
