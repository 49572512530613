import { EHttpResponseStatus, EAppInitStatus } from 'enums';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IHttpRequestError } from 'interfaces';
import { httpRequestService } from 'services';
import { changeLanguage } from 'config/i18n';
import { LoadingScreen } from 'components';
import { memo, useEffect } from 'react';
import { appStore } from 'store';
import Layout from 'layout';

const VARIABLES = {
  throwRequestError: (error: any) =>
    Promise.reject({
      Status: EHttpResponseStatus.Request,
      Detail: error.message,
      Title: error.message,
      Extensions: {},
      Type: ''
    })
};

const AuthSuccess = () => {

  const _isLoading = useSelector(appStore.startup.get.isLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // When component is mounted.
  useEffect(
    () => {
      (
        async () => {
          try {

            // Check availability of back end service
            const version = (
              await httpRequestService
                .info
                .getVersion({ showErrorMessage: false })
                .catch(
                  error => (
                    error?.response?.status === EHttpResponseStatus.NotFound
                      ? VARIABLES.throwRequestError(error)
                      : Promise.reject(error)
                  )
                )
            );
            // Update store
            appStore.common.set.setVersion(dispatch, version as any);

            // Update store
            appStore.startup.set.setLoading(dispatch, true);

            // Sync current user
            await httpRequestService.users.current.sync({ showErrorMessage: false });
            // Get current user
            const userConnected = await httpRequestService.users.current.get({ showErrorMessage: false });

            // Update lang
            if (userConnected.lang) {
              changeLanguage(userConnected.lang);
            }

            // Update store
            appStore.startup.set.setStatus(dispatch, EAppInitStatus.authenticated);
            appStore.userConnected.set.set(dispatch, l => ({ ...userConnected, notifications: [] }));
            appStore.startup.set.setLoading(dispatch, false);
          } catch (error) {
            // Cast error
            const httpRequestError = error as IHttpRequestError;
            // Check if network error
            if (httpRequestError.Status === EHttpResponseStatus.Network) {
              // Update store
              appStore.startup.set.setStatus(dispatch, EAppInitStatus.errorNetwork);
            } else if (httpRequestError.Status === EHttpResponseStatus.Forbidden) {
              // Get current from MSAL object
              const user = httpRequestService.azureAD.getCurrentUser();
              // Update store
              appStore.startup.set.setStatus(dispatch, EAppInitStatus.error403);
              appStore.userConnected.set.set(dispatch, l => ({ ...user, notifications: [] }));
            } else {
              // Get current from MSAL object
              const user = httpRequestService.azureAD.getCurrentUser();
              // Update store
              appStore.startup.set.setStatus(dispatch, EAppInitStatus.error500);
              appStore.startup.set.setData(dispatch, httpRequestError);
              appStore.userConnected.set.set(dispatch, l => ({ ...user, notifications: [] }));
            }
          } finally {
            // Update store
            appStore.startup.set.setLoading(dispatch, false);
          }
        }
      )();
    },
    // eslint-disable-next-line
    []
  );

  // When statup is working
  if (_isLoading) {
    return <LoadingScreen description={`${t('common.loading')}...`} />;
  }

  return <Layout />;

};

export default memo(AuthSuccess);
