import { EApplicationModule } from 'enums';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { TNullable } from 'types';
import { appStore } from 'store';

const useSetCurrentModule = (module?: TNullable<EApplicationModule>) => {

  const dispatch = useDispatch();

  useEffect(
    () => { appStore.common.set.setCurrentModule(dispatch, module); },
    [module]
  );

};

export default useSetCurrentModule;
