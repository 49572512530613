import { Spinner } from '@fluentui/react/lib/Spinner';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { memo } from 'react';
import './LoadingView.scss';

interface IProps {
  classNames?: string;
}

const LoadingView = ({ classNames }: IProps) => {

  const { t } = useTranslation();

  return (
    <Stack
      className={`app-loadingView ms-fadeIn500 ${classNames}`}
      verticalAlign='center'
      horizontalAlign='center'>
      <Spinner label={`${t('common.loading')}...`} />
    </Stack>
  );

};

export default memo(LoadingView);
