import { useLocation } from 'react-router-dom';

const useSearchParams = <TData = string>(name: string, defaultValue?: TData) => {

  const { search } = useLocation();

  const value = new URLSearchParams(search).get(name);

  return ({
    value: (value ?? defaultValue) as TData,
    search
  });

};

export default useSearchParams;
