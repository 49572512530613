import { IColor, PanelType, getColorFromString } from '@fluentui/react';
import { UseFormProps, useForm } from 'react-hook-form';
import { ColorPickerForm } from 'components/forms';
import { useTranslation } from 'react-i18next';
import { formPanelService } from 'services';
import { IHasColor } from 'interfaces';

interface ICallback {
  onSuccess?: (color: IColor) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      color: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IHasColor>
};

const useColorPickerForm = () => {

  const { handleSubmit, control, reset, setValue } = useForm<IHasColor>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (initColor: string, callback?: ICallback) => {

    reset({ color: getColorFromString(initColor) });

    formPanelService
      .publish({
        content: <ColorPickerForm control={control as any} setValue={setValue} />,
        title: t('common.colorPicker'),
        showCancelButton: true,
        showConfirmButton: true,
        confirmActionText: t('common.select'),
        type: PanelType.smallFixedFar,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              new Promise<void>(
                resolve => {
                  callback?.onSuccess?.(data.color);
                  resolve();
                }
              )
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useColorPickerForm;
