import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { IHasName } from 'interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'modules/time-tracking/pages/activities/store';

type TDataVariables = IHasName & {
  projectId: string;
};

export const useHttpRequestCreateTimeTrackingActivity = () => {
  const queryClient = useQueryClient();
  const _gridApi = useSelector(store.get.gridApi);
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (data: TDataVariables) => (
      httpRequestService
        .request<string>(
          `/time-entries/activities`,
          { showErrorMessage: false },
          { method: 'POST', data }
        )
        .then(response => response.data)
    ),
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
      _gridApi?.deselectAll();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeTrackingActivities] });
    },
  });

  return (mutation);

};
