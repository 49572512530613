import { Icon, Stack, Text } from '@fluentui/react';
import { EIconName, ETicketState } from 'enums';
import { useTranslation } from 'react-i18next';
import { IHasData } from 'interfaces';
import { useEnumTitle } from 'hooks';
import { TColor } from 'types';

export const CellRendererFrameworkTicketState = ({ data }: IHasData<{ state: ETicketState }>) => {

  const { title: enumTitle } = useEnumTitle(ETicketState[data.state] as keyof typeof ETicketState);
  const { t } = useTranslation();

  if (data.state as any === 'ALL') {
    return <>{t(`common.all`)}</>;
  }

  let color: TColor = 'gray80';

  switch (data.state) {
    case ETicketState.Draft:
      color = 'blueLight';
      break;

    case ETicketState.Approved:
    case ETicketState.PendingApproval:
      color = 'blue';
      break;

    case ETicketState.OnProduction:
    case ETicketState.Archived:
    case ETicketState.Rejected:
    case ETicketState.Closed:
      color = 'red';
      break;

    case ETicketState.InProgress:
    case ETicketState.OnDevops:
      color = 'greenLight';
      break;

    case ETicketState.PendingDeployment:
      color = 'green';
      break;

    case ETicketState.OnStaging:
      color = 'orange';
      break;

    default:
      break;
  }

  return (
    <Stack horizontal verticalAlign='center'>
      <Icon
        iconName={EIconName.LocationFill}
        className={`ms-fontColor-${color} me-1`} />
      <Text>
        {enumTitle}
      </Text>
    </Stack>
  );

};
