import { IApiVersion, IHttpRequestServiceOptions } from 'interfaces';
import { httpRequestService } from 'services';

const info = {

  getVersion: (params: IHttpRequestServiceOptions = {}) =>
    httpRequestService
      .request<IApiVersion>('/info/version', params)
      .then(response => response.data)

};

export default info;
