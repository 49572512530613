import ENVIRONMENT_VARIABLES from 'constant/environmentVariables';
import { IDropdownOption } from '@fluentui/react';
import { QUERY_KEYS } from './queryKeys';
import ROUTES from 'constant/routes';

/** String value empty. */
const EMPTY = '';
/** Default time out applied on animations. */
const DEFAULT_TIME_OUT_ANIMATION = 300;
/** Defaut duration of alerts. */
const DEFAULT_ALERT_DURATION = 3000;

const DEFAULT_REQUEST_ID = 'default';

const OPTIONS_PAGE_SIZE = [
  { key: 10, data: 10, text: '10' },
  { key: 15, data: 15, text: '15' },
  { key: 20, data: 20, text: '20' },
  { key: 50, data: 50, text: '50' },
  { key: 100, data: 100, text: '100' }
] as IDropdownOption<number>[];


/** 10 mb. */
const MAX_FILE_SIZE = 10240000;

export {
  DEFAULT_TIME_OUT_ANIMATION,
  DEFAULT_ALERT_DURATION,
  ENVIRONMENT_VARIABLES,
  DEFAULT_REQUEST_ID,
  OPTIONS_PAGE_SIZE,
  MAX_FILE_SIZE,
  QUERY_KEYS,
  ROUTES,
  EMPTY
};
