export const QUERY_KEYS = {
  createTimeTrackingActivity: 'createTimeTrackingActivity',
  deleteTimeTrackingActivities: 'deleteTimeTrackingActivities',
  updateTimeTrackingActivity: 'updateTimeTrackingActivity',
  getTimeTrackingActivities: 'getTimeTrackingActivities',

  createTimeTrackingProject: 'createTimeTrackingProject',
  deleteTimeTrackingProject: 'deleteTimeTrackingProject',
  updateTimeTrackingProject: 'updateTimeTrackingProject',
  getTimeTrackingProjects: 'getTimeTrackingProjects',

  createTimeTrackingClient: 'createTimeTrackingClient',
  deleteTimeTrackingClient: 'deleteTimeTrackingClient',
  updateTimeTrackingClient: 'updateTimeTrackingClient',
  getTimeTrackingClients: 'getTimeTrackingClients',
  
  updateUserParameter: 'updateUserParameter',
  getUserParameters: 'getUserParameters',
  getNomenclatures: 'getNomenclatures',
  createTimeEntry: 'createTimeEntry',
  deleteTimeEntry: 'deleteTimeEntry',
  getTimeEntries: 'getTimeEntries',
  getProjects: 'getProjects'
};
