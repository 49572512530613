import { EApplicationModule } from 'enums';
import { concatPath } from 'functions';
import { IRoutes } from 'interfaces';
import { TRouteAdmin } from 'types';
import { ROUTES } from 'constant';
import { lazy } from 'react';

/** Route that redirects to module admin in application. */
const ADMIN = {
  module: EApplicationModule.Settings,
  id: '/admin',
  /** Parent of route. */
  parent: () => ROUTES as IRoutes,
  /** Base url of route */
  base: '/admin',
  /** Path of route. */
  path: () => concatPath(ROUTES.path(), ROUTES.ADMIN.base),
  relativePath: () => `admin/*`,
  /** Description of route. */
  description: (t: Function): string => t('common.admin'),
  /** Component to display on that route. */
  lazyComponent: lazy(() => import(/*webpackChunkName: 'routeAdmin'*/ 'modules/admin/routes')),
  /** Route that redirects to overview in module admin in application. */
  OVERVIEW: {
    module: EApplicationModule.Settings,
    id: '/admin/',
    /** Parent of route. */
    parent: () => ROUTES.ADMIN as IRoutes,
    /** Base url of route */
    base: '/overview',
    /** Path of route. */
    path: () => concatPath(ROUTES.ADMIN.path(), ROUTES.ADMIN.OVERVIEW.base),
    relativePath: () => 'overview',
    /** Description of route. */
    description: (t: Function): string => t('routes.admin.overview.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeAdminOverview'*/ 'modules/admin/pages/overview'))
  },
  /** Route that redirects to logs in module admin in application. */
  LOGS: {
    module: EApplicationModule.Settings,
    id: '/admin/logs',
    /** Parent of route. */
    parent: () => ROUTES.ADMIN as IRoutes,
    /** Base url of route*/
    base: '/logs',
    /** Path of route */
    path: () => concatPath(ROUTES.ADMIN.path(), ROUTES.ADMIN.LOGS.base),
    relativePath: () => 'logs',
    /** Description of route. */
    description: (t: Function): string => t('routes.admin.logs.title'),
    /** Component to display on that route. */
    lazyComponent: lazy(() => import(/*webpackChunkName: 'routeAdminLogs'*/ 'modules/admin/pages/logs'))
  }
} as TRouteAdmin;

export default ADMIN;
