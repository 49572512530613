import { IHasDescriptionOptional, ISkillManDays } from 'interfaces';
import { getBusinessDatesCount } from 'functions';
import { Dispatch } from '@reduxjs/toolkit';
import { appStore } from 'store';

const VARIABLES = {
  key: 'CreateConsultantExperienceForm',
  initStore: {
    minEndDate: new Date(),
    formSkill: {
      hasFocus: false,
      value: ''
    },
    description: '',
    skillMandays: [],
    skillMandaysEditing: false
  } as ICreateUpdateConsultantExperienceFormStore
};

export interface ICreateUpdateConsultantExperienceFormStore extends IHasDescriptionOptional {
  endDate?: Date;
  skillMandaysEditing: boolean;
  skillMandays: ISkillManDays[];
  minEndDate: Date;
  formSkill: {
    value: string;
    hasFocus: boolean;
  };
}

const set = {
  set: (dispatch: Dispatch, setter: (state: ICreateUpdateConsultantExperienceFormStore) => ICreateUpdateConsultantExperienceFormStore) =>
    appStore.record.set.set(dispatch, { key: VARIABLES.key, setter }),
  init: (dispatch: Dispatch) => appStore.record.set.set(dispatch, { key: VARIABLES.key, setter: () => VARIABLES.initStore }),
  clear: (dispatch: Dispatch) => appStore.record.set.clear(dispatch, VARIABLES.key)
};

const get = {
  skillMandaysEditing: appStore.record.get.get(
    VARIABLES.key,
    (record: ICreateUpdateConsultantExperienceFormStore) => record?.skillMandaysEditing ?? VARIABLES.initStore.skillMandaysEditing
  ),
  skillMandays: appStore.record.get.get(VARIABLES.key, (record: ICreateUpdateConsultantExperienceFormStore) => record?.skillMandays ?? VARIABLES.initStore.skillMandays),
  description: appStore.record.get.get(VARIABLES.key, (record: ICreateUpdateConsultantExperienceFormStore) => record?.description ?? VARIABLES.initStore.description),
  minEndDate: appStore.record.get.get(VARIABLES.key, (record: ICreateUpdateConsultantExperienceFormStore) => record?.minEndDate ?? VARIABLES.initStore.minEndDate),
  formSkill: appStore.record.get.get(VARIABLES.key, (record: ICreateUpdateConsultantExperienceFormStore) => record?.formSkill ?? VARIABLES.initStore.formSkill),
  endDate: appStore.record.get.get(VARIABLES.key, (record: ICreateUpdateConsultantExperienceFormStore) => record?.endDate ?? VARIABLES.initStore.endDate),
  totalDays: (
    appStore.record.get.get(
      VARIABLES.key,
      (record: ICreateUpdateConsultantExperienceFormStore) => {
        const startDate = record?.minEndDate ?? VARIABLES.initStore.minEndDate;
        const endDate = record?.endDate ?? VARIABLES.initStore.endDate;
        const totalDays = getBusinessDatesCount(startDate, endDate);        

        return totalDays;
      }
    )
  )
};

const store = {
  set,
  get
};

export default store;
