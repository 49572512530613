import { CreateUpdateConsultancyProfileForm } from 'components/forms';
import { formPanelService, httpRequestService } from 'services';
import { ICreateConsultancyProfileFormData } from 'interfaces';
import { UseFormProps, useForm } from 'react-hook-form';
import useUserConnected from './useUserConnected';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (consultantId: string) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      description: '',
      firstName: '',
      lastName: '',
      title: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<ICreateConsultancyProfileFormData>
};

const useCreateConsultancyProfileForm = () => {

  const { handleSubmit, control, reset, setValue } = useForm<ICreateConsultancyProfileFormData>(VARIABLES.formConfig);
  const _userConnected = useUserConnected();
  const { t } = useTranslation();

  const triggerForm = (callback?: ICallback) => {
    reset();

    formPanelService
      .publish({
        content: <CreateUpdateConsultancyProfileForm control={control as any} setValue={setValue} />,
        title: t('common.createConsultancyProfile'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .create(_userConnected.id, data)
                .then(releaseNoteId => callback?.onSuccess?.(releaseNoteId))
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useCreateConsultancyProfileForm;
