import { formPanelService, httpRequestService } from 'services';
import { UseFormProps, useForm } from 'react-hook-form';
import { DescriptionForm } from 'components/forms';
import { useTranslation } from 'react-i18next';
import {IHasDescription, IHasDescriptionOptional} from 'interfaces';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      description: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IHasDescriptionOptional>
};

const useTicketRejectionCommentForm = () => {

  const { handleSubmit, control, reset } = useForm<IHasDescriptionOptional>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (ticketId: string, callback?: ICallback) => {

    reset();

    formPanelService
      .publish({
        content: <DescriptionForm control={control as any} />,
        title: t('common.reason'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .tickets
                .reject(
                  ticketId,
                  data.description
                )
                .then(() => callback?.onSuccess?.())
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useTicketRejectionCommentForm;
