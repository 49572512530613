import { IFontStyles, Icon, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { IHasIsLoading, IHasValue } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { EIconName } from 'enums';

interface IProps extends IHasValue<string> {
  onDelete?: (value: string | JSX.Element) => Promise<void>;
  onRender?: (value: string) => string | JSX.Element;
  variant?: keyof IFontStyles;
  allowDeletion?: boolean;
  className?: string;
  bgColor?: string;
}

interface IState extends IHasIsLoading {
}

const Skill = ({ value, onDelete, className, onRender, bgColor, variant = 'medium', allowDeletion = false }: IProps) => {

  const [state, setState] = useState<IState>({ isLoading: false });
  const { t } = useTranslation();

  const size = (
    variant === 'medium' || variant === 'mediumPlus'
      ? SpinnerSize.medium
      : variant === 'small' || variant === 'smallPlus' || variant === 'tiny'
        ? SpinnerSize.small
        : SpinnerSize.large
  );

  const handleOnClickButtonDelete = () => {

    if (onDelete) {
      setState(l => ({ ...l, isLoading: true }));
      onDelete(value)
        .finally(() => setState(l => ({ ...l, isLoading: false })));
    }

  };

  return (
    <Stack
      horizontal
      verticalAlign='center'
      tokens={{ childrenGap: 8 }}
      style={{ backgroundColor: bgColor }}
      className={`${className} p-1.5 rounded ${!bgColor && 'ms-bgColor-gray30'}`}>
      <Text
        variant={variant}
        className='ms-fontWeight-semibold p-0 m-0' >
        {onRender?.(value) ?? value}
      </Text>
      {
        state.isLoading &&
        <Spinner size={size} />
      }
      {
        !state.isLoading &&
        allowDeletion &&
        <Icon
          className=''
          title={t('common.delete')}
          role='button'
          onClick={handleOnClickButtonDelete}
          iconName={EIconName.Cancel} />
      }
    </Stack>
  );

};

export default memo(Skill);
