import { ControlledDatePicker, ControlledDropdown, ControlledRichTextEditor, ControlledTextField } from 'components';
import CreateUpdateConsultantExperienceFormSkills from './CreateUpdateConsultantExperienceFormSkills';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IConsultantExperience } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useNomenclatureData } from 'hooks';
import { ENomenclatureType } from 'enums';
import { memo, useEffect } from 'react';
import { nameof } from 'functions';
import store from './store';

interface IProps {
  watch: UseFormWatch<IConsultantExperience>;
  setValue: UseFormSetValue<IConsultantExperience>;
  control: Control<any>;
}

const CreateUpdateConsultantExperienceForm = ({ control, watch, setValue }: IProps) => {

  const { isLoading: isLoadingWorkingLocationTypes, data: dataWorkingLocationTypes } = useNomenclatureData<number>(ENomenclatureType.WorkingLocationType);
  const { isLoading: isLoadingEmploymentTypes, data: dataEmploymentTypes } = useNomenclatureData<number>(ENomenclatureType.EmploymentType);
  const _skillMandays = useSelector(store.get.skillMandays);
  const _description = useSelector(store.get.description);
  const _minEndDate = useSelector(store.get.minEndDate);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => {
      store
        .set
        .set(
          dispatch,
          l => ({
            ...l,
            skillMandays: control._defaultValues.skillMandays ?? [],
            description: control._defaultValues.description,
            minEndDate: control._defaultValues.startDate,
            endDate: control._defaultValues.endDate,
            formSkill: {
              hasFocus: false,
              value: ''
            }
          })
        );

      const subscription = watch(
        (data, { name }) => {
          name === nameof<IConsultantExperience>('startDate') &&
            store.set.set(dispatch, l => ({ ...l, minEndDate: data.startDate as Date }));
          name === nameof<IConsultantExperience>('endDate') &&
            store.set.set(dispatch, l => ({ ...l, endDate: data.endDate as Date }));
          name === nameof<IConsultantExperience>('description') &&
            store.set.set(dispatch, l => ({ ...l, description: data.description }));
        }
      );

      return () => {
        subscription.unsubscribe();
        store.set.clear(dispatch);
      };
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => { setValue('skillMandays', _skillMandays); },
    [_skillMandays]
  );

  const handleOnChangeDescription = (description: string) => {
    setValue('description', description);
    store.set.set(dispatch, l => ({ ...l, description }));
  };

  return (
    <Stack className='mt-2' tokens={{ childrenGap: 10 }}>
      <ControlledTextField
        required
        control={control}
        label={t('common.title')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantExperience>('title')} />
      <ControlledDropdown
        required
        control={control}
        disabled={isLoadingEmploymentTypes}
        options={dataEmploymentTypes.map(l => ({ key: l.id, text: l.title }))}
        label={t('common.employmentType')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantExperience>('employmentType')} />
      <ControlledTextField
        required
        control={control}
        label={t('common.companyName')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantExperience>('companyName')} />
      <ControlledDropdown
        required
        control={control}
        disabled={isLoadingWorkingLocationTypes}
        options={dataWorkingLocationTypes.map(l => ({ key: l.id, text: l.title }))}
        label={t('common.workingLocationType')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<IConsultantExperience>('workingLocationType')} />
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Stack.Item grow>
          <ControlledDatePicker
            isRequired
            maxDate={new Date()}
            control={control}
            label={t('common.startDate')}
            rules={{ required: t('common.formError.required').toString() }}
            name={nameof<IConsultantExperience>('startDate')} />
        </Stack.Item>
        <Stack.Item grow>
          <ControlledDatePicker
            showCloseButton
            minDate={_minEndDate}
            control={control}
            label={t('common.endDate')}
            name={nameof<IConsultantExperience>('endDate')} />
        </Stack.Item>
      </Stack>
      <Pivot>
        <PivotItem headerText={t('common.description')} className='mt-3'>
          <ControlledRichTextEditor
            control={control}
            wrapperStyle={{
              borderTop: 0,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#e1dfdd'
            }}
            editorStyle={{
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              paddingBottom: '1rem'
            }}
            defaultHtml={_description}
            onChange={value => handleOnChangeDescription(value.blocks[0].text)}
            name={nameof<IConsultantExperience>('description')}
            label='' />
        </PivotItem>
        <PivotItem headerText={`${t('common.skills')} (${_skillMandays.length})`} className='mt-2'>
          <CreateUpdateConsultantExperienceFormSkills />
        </PivotItem>
      </Pivot>
    </Stack>
  );

};

export default memo(CreateUpdateConsultantExperienceForm);
