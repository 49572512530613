import { initializeIcons, loadTheme } from '@fluentui/react';

initializeIcons();

loadTheme({
  palette: {
    themePrimary: '#1d6257',
    themeDarkAlt: '#133f38',
    themeDark: '#123a34'
  }
});
