import { formPanelService, httpRequestService } from 'services';
import { CreateTranslationFolderForm } from 'components/forms';
import { ITranslationFolder, IProject } from 'interfaces';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';

interface ICallback {
  onSuccess?: (translationFolderId: string) => void;
  onError?: (error: any) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      folderPath: '',
      repository: '',
      projectId: '',
      branch: '',
      name: '',
      id: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<ITranslationFolder>
};

const useCreateTranslationFolderForm = () => {

  const { handleSubmit, control, reset, setValue, watch } = useForm<ITranslationFolder>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (projectSelected: IProject, callback?: ICallback) => {

    reset();

    formPanelService
      .publish({
        content: <CreateTranslationFolderForm control={control as any} setValue={setValue} projectSelected={projectSelected} watch={watch} />,
        title: t('common.createTranslationSource'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                ._projects
                .translations
                .folders
                .create(projectSelected.id, data, { showErrorMessage: false })
                .then(translationFolderId => callback?.onSuccess?.(translationFolderId))
                .catch(error => callback?.onError?.(error))
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useCreateTranslationFolderForm;
