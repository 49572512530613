import { convertService, formPanelService, httpRequestService } from 'services';
import { UpdateConsultancyContactInfoForm } from 'components/forms';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import { IConsultant } from 'interfaces';

interface ICallback {
  onSuccess?: (data: IConsultant) => void;
}

const VARIABLES = {
  formConfig: {
    defaultValues: {
      phoneNumberIndicatif: '',
      birthDate: null,
      phoneNumber: '',
      address: '',
      email: ''
    },
    reValidateMode: 'onBlur',
    mode: 'all'
  } as UseFormProps<IConsultant>
};

const useUpdateConsultancyContactInfoForm = () => {

  const { handleSubmit, control, reset } = useForm<IConsultant>(VARIABLES.formConfig);
  const { t } = useTranslation();

  const triggerForm = (consultant: IConsultant, callback?: ICallback) => {

    reset({
      birthDate: convertService.convertToDate(consultant.birthDate),
      phoneNumberIndicatif: consultant.phoneNumberIndicatif,
      phoneNumber: consultant.phoneNumber,
      address: consultant.address,
      email: consultant.email
    });

    formPanelService
      .publish({
        content: <UpdateConsultancyContactInfoForm control={control as any} />,
        title: t('common.updateContactInfo'),
        showCancelButton: true,
        showConfirmButton: true,
        type: PanelType.medium,
        confirmActionCallBack: () => (
          handleSubmit(
            data => (
              httpRequestService
                .consultancyProfiles
                .updateContactInfo(consultant.id, data, { showErrorMessage: false })
                .then(
                  () => callback?.onSuccess?.({
                    ...consultant,
                    phoneNumberIndicatif: data.phoneNumberIndicatif,
                    phoneNumber: data.phoneNumber,
                    birthDate: data.birthDate,
                    address: data.address,
                    email: data.email
                  })
                )
            ),
            () => Promise.reject(t('common.formError.invalidForm'))
          )()
        )
      });
  };

  return { triggerForm };

};

export default useUpdateConsultancyContactInfoForm;
