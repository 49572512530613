import { ICreateUpdateTimeEntryFormData, ITimeEntry } from 'interfaces';
import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

type TDataVariables = ICreateUpdateTimeEntryFormData;

interface IParams {
  userId: string,
  from?: Date;
  to?: Date;
}

export const useHttpRequestCreateTimeEntry = (params: IParams) => {

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (data: TDataVariables) =>
      httpRequestService
        .timeEntries
        .create(data, { showErrorMessage: true }),
    onMutate: (newTimeEntry) => {
      queryClient.cancelQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });
      const oldTimeEntries = queryClient.getQueryData([QUERY_KEYS.getTimeEntries, params]);
      queryClient.setQueryData([QUERY_KEYS.getTimeEntries, params], (old: ITimeEntry[]) => [...old, newTimeEntry]);
      return { oldTimeEntries }
    },
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
    },
    onError: (_,__,context) => {
      if (context)
      queryClient.setQueryData([QUERY_KEYS.getTimeEntries, params], context.oldTimeEntries);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });
    }
  });

  return (mutation);

};
